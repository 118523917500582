(function() {
	'use strict';

	angular.module('unleeshApp').controller('SignUpController', SignUpController);
	SignUpController.$inject = ['$scope', 'userService', '$rootScope', '$location'];

	function SignUpController($scope, userService, $rootScope, $location) {
		$scope.singUp = singUp;
        $scope.accessCodeVisible   = false;

        $scope.accessCode    = accessCode;

		if ($rootScope.user && $rootScope.user.authorized) {
			$location.path('/');
		}

		function singUp() {
			var formData = new FormData();
			for(var key in $scope.register) {
				if(key == 'files' && ($scope.register[key] && $scope.register[key][0] && $scope.register[key].length != null)) {
						formData.append('file',$scope.register[key][$scope.register[key].length - 1] ,$scope.register[key][$scope.register[key].length - 1].name);
				}
				else if($scope.register[key]) {
					formData.append(key,$scope.register[key]);
				}
			}
			if(!$scope.form.$valid) {
				return;
			}
			$rootScope.isLoaded = true;
			userService.createUser(formData).then(function(response) {
                if(response.status == 'Fail') {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: response.message
                    });}
                $rootScope.isLoaded = false;
				$rootScope.handleResponse($scope, response, createUser);
			}, function(error) {
				$rootScope.isLoaded = false;
				$scope.$emit('errorMessage',error);
			});
		}

        function accessCode() {
            $scope.accessCodeVisible = !$scope.accessCodeVisible;
            $scope.register.accessCode  = '';
        }

		function createUser(response){
			$scope.$emit('userAuthorized', response.user);
		}
	}

})();
