(function() {
    'use strict';

    angular.module('unleeshApp').service('eventService', eventService);
    eventService.$inject = ['doRequest', 'config', 'US'];

    function eventService(doRequest, config, US) {
        var $this = this;

        /*
         * Events
         * */
        //settings
        $this.eventsUrlSuffix = function () {
            return 'events';
        };

        $this.statusUrlSuffix = function () {
            return 'status';
        };
        $this.inviteesUrlSuffix = function () {
            return 'invitees';
        };
        $this.commentUrlSuffix = function () {
            return 'comments';
        };
        $this.newUrlSuffix = function () {
            return 'users';
        };

        //url
        $this.getListEventsUrl = function () {
            return config.appHost + US.urlSeparator() + $this.eventsUrlSuffix() + US.urlSeparator();
        };

        $this.getEventUrl = function (id) {
            return config.appHost + US.urlSeparator() + $this.eventsUrlSuffix() + US.urlSeparator() + id + US.urlSeparator();
        };

        $this.getEventStatusUrl = function (id) {
            return $this.getEventUrl(id) + $this.statusUrlSuffix() + US.urlSeparator();
        };

        $this.getCommentsEventUrl = function (id) {
            return $this.getEventUrl(id) + $this.commentUrlSuffix() + US.urlSeparator();
        };

        $this.getUsersEventUrl = function () {
            return $this.getListEventsUrl() + $this.newUrlSuffix() + US.urlSeparator();
        };
        $this.getUsersEventInviteesUrl = function (id) {
            return $this.getListEventsUrl() + id + US.urlSeparator() + $this.inviteesUrlSuffix() + US.urlSeparator();
        };

        $this.createEventUrl = function () {
            return $this.getListEventsUrl();
        };

        $this.deleteEventUrl = function (id) {
            return $this.getListEventsUrl() + id + US.urlSeparator();
        };

        $this.editEventUrl = function (id) {
            return $this.getListEventsUrl() + id + US.urlSeparator();
        };

        /**
         * REQUEST EVENTS
         *
         * GET
         * Get list events
         * @param token
         * @param callback
         * @returns {*}
         */
        $this.getListEvents = function (token, callback) {
            var $this = this;
            return doRequest.doRequest(
                 US.REQUEST_TYPE.GET,
                $this.getListEventsUrl(),
                {token: US.getToken()},
                callback
            )
        };

        /**
         * GET
         * Get count new events
         * @param token
         * @param callback
         * @returns {*}
         */
        $this.getEventNewCount = function (callback) {
            var $this = this;
            return doRequest.doRequest(
                 US.REQUEST_TYPE.GET,
                $this.getListEventsUrl(),
                {token: US.getToken(), onlyNewItemsCount: 1},
                callback,
                true
            )
        };

        /**
         * GET
         * Get current event
         * @param id event
         * @param token user
         * @param callback
         * @returns {*}
         */
        $this.getEvent = function (id, token, callback) {
            var $this = this;
            return doRequest.doRequest(
                 US.REQUEST_TYPE.GET,
                $this.getEventUrl(id),
                {token: US.getToken()},
                callback
            )
        };

        /**
         * POST
         * Edit status users to current event
         * @param entity
         * @param callback
         * @returns {*}
         */
        $this.editEventStatus = function (entity, callback) {
            var $this = this;
            return doRequest.doRequest(
                 US.REQUEST_TYPE.POST,
                $this.getEventStatusUrl(entity.id),
                {token: entity.token, status: entity.status},
                callback
            )
        };

        /**
         * GET
         * Get List comments to current event
         * @param entity
         * @param callback
         * @returns {*}
         */
            // /events/{eventId}/comments/
        $this.getListCommentsEvent = function (id, page, limit, callback) {
            var $this = this;
            return doRequest.doRequest(
                 US.REQUEST_TYPE.GET,
                $this.getCommentsEventUrl(id),
                {token: US.getToken(), page: page, limit: limit},
                callback
            )
        };

        /**
         * GET
         * Get list users available to selected path
         * @param entity
         * @param callback
         * @returns {*}
         */
        $this.getUsersEvent = function (entity, callback) {
            var $this = this;
            return doRequest.doRequest(
                 US.REQUEST_TYPE.GET,
                $this.getUsersEventUrl(),
                entity,
                callback
            )
        };

        /**
         * GET
         * Get list users invited to event
         * @param entity
         * @param callback
         * @returns {*}
         */
        $this.getUsersEventInvitees = function (entity, callback) {
            var $this = this;
            return doRequest.doRequest(
                 US.REQUEST_TYPE.GET,
                $this.getUsersEventInviteesUrl(entity.id),
                entity,
                callback
            )
        };

        /**
         * POST
         * Create new event
         * @param entity
         * @param callback
         * @returns {*}
         */
        $this.createEvent = function (entity, token, callback) {
            var $this = this;
            return doRequest.doRequest(
                 US.REQUEST_TYPE.POST,
                $this.createEventUrl() + "?token=" + US.getToken(),
                entity,
                callback
            )
        };

        /**
         * POST
         * Create new event with file
         * @param entity
         * @param callback
         * @returns {*}
         */
        $this.createEventFile = function (entity, token, callback) {
            var $this = this;
            return doRequest.doRequestFile(
                 US.REQUEST_TYPE.POST,
                $this.createEventUrl() + "?token=" + US.getToken(),
                entity,
                callback
            )
        };

        /**
         * POST
         * Edit event
         * @param entity
         * @param callback
         * @returns {*}
         */
        $this.editEvent = function (entity, token, id, callback) {
            var $this = this;
            return doRequest.doRequest(
                 US.REQUEST_TYPE.POST,
                $this.editEventUrl(id) + "?token=" + US.getToken(),
                entity,
                callback
            )
        };

        /**
         * POST
         * Edit event with file
         * @param entity
         * @param callback
         * @returns {*}
         */
        $this.editEventFile = function (entity, token, id, callback) {
            var $this = this;
            return doRequest.doRequestFile(
                US.REQUEST_TYPE.POST,
                $this.editEventUrl(id) + "?token=" + US.getToken(),
                entity,
                callback
            )
        };

        /**
         * DELETE
         * Delete event
         * @param token
         * @param id
         * @param callback
         * @returns {*}
         */
        $this.deleteEvent = function (id, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.DELETE,
                $this.deleteEventUrl(id) + "?token=" + US.getToken(),
                {},
                callback
            )
        };

        /**
         * POST
         * Add comment to event
         * @param entity
         * @param id
         * @param callback
         * @returns {*}
         */
        $this.addCommentToEvent = function (entity, id, callback) {
            var $this = this;

            return doRequest.doRequestFile(
                US.REQUEST_TYPE.POST,
                $this.getCommentsEventUrl(id) + '?token=' + US.getToken(),
                entity,
                callback
            )
        };

    }
})();