(function () {
  "use strict";

  angular
    .module("unleeshApp")
    .directive("activity", () => {
      return {
        templateUrl: "sources/template/post.html",
        scope: {
          post: "=",
          // passed through to <message-attach-form>, if true, formats that vertically
          // don't forget that HTML attributes are case insensitive, so we do angular normalization with the name
          formatReplyBoxVertically: "@",
          // location is where the activity box is being rendered
          location: "@", // "sidebar"
        },
        controller: controller,
      };
    })
    .directive("reply", () => {
      return {
        templateUrl: "sources/template/post-reply.html",
        scope: {
          post: "=",
        },
        controller: controller,
      };
    });

  controller.$inject = ["$scope", "$rootScope", "$location", "submitService"];

  function controller($scope, $rootScope, $location, submitService) {
    // whether to hide this post
    $scope.hide = false;

    // whether to hide if rendering top level (eg., not as a reply)
    $scope.hideIfTopLevel = false;

    let post = $scope.post;
    if (!post) {
      $scope.hide = true;
      return;
    }

    // uncomment to hide your own posts
    // if ($scope.location && $scope.location === "sidebar") {
    //   if (post.user.id === $rootScope.user.id) {
    //     $scope.hideIfTopLevel = true;
    //   }
    // }

    // whether to format <message-attach-form> vertically
    $scope.formatReplyBoxVertically = !!$scope.formatReplyBoxVertically;

    // stores the data for replying, given to <message-attach-form>
    // <message-attach-form> actually mutates this value, and so we can read it from this scope directly
    $scope.replyMessage = {
      message: "",
      attacheType: "",
      files: [],
    };

    // do cleanup of post
    if (!post.activityType) {
      post.activityType = null;
    }

    // some docs on wtf all these activity types are
    /*
      ActionSubmit - submitting an activity for a path step
      ActionSubmitComment - a reply to that activity

    */

    // ---- determine content and subtext text, and lining ----
    $scope.content = null;
    $scope.subtext = null;
    $scope.content_link_url = null;
    $scope.can_reply = false; // whether you can reply to this comment or not
    $scope.reply_type = null; // reply type, used to reply to activity and sent in setListCommentsFile
    $scope.replies = [];
    // if it's an event
    if (post.activityType === "Event") {
      $scope.content = post.message;
      $scope.subtext = `Breakouts`;

      if (post.activityLink) {
        $scope.content_link_url = `/event/view/${post.activityLink}`;
      } else {
        $scope.content_link_url = `/events`;
      }

      // hide your own event activity
      // if ($scope.location && $scope.location === "sidebar") {
      //   if (post.user.id === $rootScope.user.id) {
      //     $scope.hideIfTopLevel = true;
      //   }
      // }
    }
    // If it's a submission for a path's step
    else if (post.activityType === "ActionSubmit") {
      $scope.content = post.activity.comment;
      $scope.subtext = post.message;

      // setup linking
      if ($scope.location !== "steps") {
        const stepInfo = getStepInfo(post);
        if (stepInfo) {
          $scope.content_link_url = `/path/step/${stepInfo.phaseID}&${stepInfo.levelID}&${stepInfo.stepID}`;
        }
      }

      // setup replies
      $scope.can_reply = true;
      $scope.reply_type = "ActionSubmit";
      $scope.replies = post.activity.posts;
      $scope.replies.forEach((reply) => {
        if (!reply.activityType) {
          reply.activityType = "ActionSubmitComment-fixed";
        }
      });
      $scope.submitCommentToId = $scope.post.activity.id; // id for replies (used in submitReply())
    }
    // A reply to an ActionSubmit
    else if (post.activityType === "ActionSubmitComment") {
      $scope.content = post.activity.message;
      $scope.subtext = post.message;

      // setup linking
      const stepInfo = getStepInfo(post);
      if (stepInfo) {
        $scope.content_link_url = `/path/step/${stepInfo.phaseID}&${stepInfo.levelID}&${stepInfo.stepID}`;
      }

      $scope.hideIfTopLevel = true;
    }
    // Like ActionSubmitComment, but a different structure because it was pulled out in an ActionSubmit
    else if (post.activityType === "ActionSubmitComment-fixed") {
      $scope.content = post.message;
      $scope.hideIfTopLevel = true;
    }

    // "EventComment" is a type made up by Ben and is set inside of event-landing.controller.js for comments on events
    else if (post.activityType === "EventComment") {
      $scope.content = post.message;
      $scope.subtext = `Commented in event`;
    }
    // A "Post" is also sometimes called a "Chat". They used to be in the "chat" tab of a path
    // they have now been renamed as "path announcements"
    // this is basically an Announcement in the activity sidebar
    else if (post.activityType === "Post") {
      $scope.content = post.activity.message;
      $scope.subtext = post.message;

      // visually rename chat to announcement
      $scope.subtext = $scope.subtext
        .replace("Posted in Chat", "Posted Announcement")
        // Remove trailing period, if present
        .replace(".", "");

      // setup linking to announcements page
      $scope.content_link_url = `/announcements`;

      // setup replies
      $scope.can_reply = true;
      $scope.reply_type = "Post";
      $scope.submitCommentToId = post.id;
      $scope.replies = post.activity.replies;
      $scope.replies.forEach((reply) => {
          console.log(reply);
          if (!reply.activityType) {
              reply.activityType = "AnnouncementReply";
          }
      });
      $scope.submitCommentToId = $scope.post.activity.id; // id for replies (used in submitReply())
    }
    // Announcement is a made-up type by Ben. It's set on the announcements page, because they're rendered as activitys there
    else if (post.activityType === "Announcement") {
      $scope.content = post.message;
      $scope.subtext = `Posted Announcement in ${post.pathName}`;

      // setup replies
      $scope.can_reply = true;
      $scope.reply_type = "Post";
      $scope.submitCommentToId = post.id;
      $scope.replies = post.replies;
      $scope.replies.forEach((reply) => {
        if (!reply.activityType) {
          reply.activityType = "AnnouncementReply";
        }
      });
    }
    // "AnnouncementReply" is created in-app for replies to announcements
    else if (post.activityType === "AnnouncementReply") {
      $scope.content = post.message;
      $scope.subtext = "Replied to an Announcement";
    }
    // A "Reply" is a comment/reply on a "Post", which a "Post" is also sometimes called a "Chat",
    // and Chats have been renamed to "Announcements"
    else if (post.activityType === "Reply") {
      $scope.content = post.activity.message;
      $scope.subtext = "Replied to an Announcement";

      $scope.hideIfTopLevel = true;
    } else {
      console.warn("No activity type");
      console.log(post);
    }

    // ---- setup attachment ----
    $scope.attachmentType = null;
    $scope.attachmentUrl = null;
    if (post.activity && post.activity.attachment) {
      $scope.attachmentUrl = post.activity.attachment;
    } else if (
      [
        "ActionSubmitComment-fixed",
        "Announcement",
        "AnnouncementReply",
      ].includes(post.activityType) &&
      post.attachment
    ) {
      $scope.attachmentUrl = post.attachment;
    }

    // set attachment type
    const imageEndings = [
      ".png",
      ".jpg",
      ".jpeg",
      ".gif",
      ".png",
      ".eps",
      ".raw",
      ".tiff",
    ];
    const videoEndings = [".mp4"];
    const audioEndings = [".amr", ".aac", ".m4a"];
    if ($scope.attachmentUrl) {
      // check if attachment is an image
      imageEndings.forEach((ending) => {
        if ($scope.attachmentUrl.includes(ending)) {
          $scope.attachmentType = "image";
        }
      });

      // check if attachment is a video
      videoEndings.forEach((ending) => {
        if ($scope.attachmentUrl.includes(ending)) {
          $scope.attachmentType = "video";
        }
      });

      // check if attachment is audio
      audioEndings.forEach((ending) => {
        if ($scope.attachmentUrl.includes(ending)) {
          $scope.attachmentType = "audio";
        }
      });

      if(post.activity){
          if (post.activity.attachmentType === 'document'){
              $scope.attachmentType = 'document';
              $scope.originalName   = post.activity.originalName;
          }
      }
    }


/* Handle when links are embedded in an activity
    If a user submits an activity that has a link in it, the server will automatically add <a></a> tags inside the text
    body itself. These won't be automatically clickable, we have to actually choose to bind to html. The problem is
    we don't always want to do that.

    if content_is_html is set,
*/
    const aTagRegex = /<a.+href=.+?>.+<\/a>/i;
    $scope.content_is_html = false;
    if ($scope.content && aTagRegex.test($scope.content)) {
      $scope.content_is_html = true;
    }

    // internally it's inconsistently called "action" or "step"
    function getStepInfo(post) {
      // nothing to do here if we don't have the lookup table
      if (!$rootScope.stepLookup) {
        return null;
      }

      // get the stepID (it's stored in different places depending on the activity)
      let stepID;
      if (post.activity && post.activity.action) {
        stepID = post.activity.action.id;
      } else if (
        post.activity &&
        post.activity.actionSubmit &&
        post.activity.actionSubmit.action
      ) {
        stepID = post.activity.actionSubmit.action.id;
      } else {
        // can't get info if we can't find the ID
        return null;
      }

      const stepInfo = $rootScope.stepLookup[stepID];

      if (stepInfo) {
        let returnObj = JSON.parse(JSON.stringify(stepInfo));
        returnObj.stepID = stepID;

        return returnObj;
      } else {
        // info wasn't in lookup table
        return null;
      }
    }

    // callback for submitting a reply. Attached to <message-attach-form></message-attach-form>
    $scope.submitReply = () => {
      let formData = new FormData();

      // add typed message
      if ($scope.replyMessage.message) {
        formData.append("message", $scope.replyMessage.message);
      }

      // add attached files
      if ($scope.replyMessage.files.length) {
        formData.append(
          "file",
          $scope.replyMessage.files[$scope.replyMessage.files.length - 1],
          $scope.replyMessage.files[$scope.replyMessage.files.length - 1].name
        );
        formData.append("attachmentType", $scope.replyMessage.attacheType);
      }

      if (!$scope.submitCommentToId || !$scope.reply_type) {
        throw new Error(
          `Need to set reply variables correctly. submitCommentToId: '${$scope.submitCommentToId}', reply_type: '${$scope.reply_type}', `
        );
      }

      // submit!
      submitService
        .setListCommentsFile(
          formData,
          $scope.submitCommentToId,
          $scope.reply_type
        )
        .then(
          function (response) {
            $rootScope.handleResponse(
              $scope,
              response,
              handleAddCommentResponse
            );
          },
          function (error) {
            $scope.$emit("errorMessage", error);
          }
        );
    };

    // callback after submitting a reply comment
    function handleAddCommentResponse(response) {
      let newReply;

      // replying to a step activity
      if ($scope.reply_type === "ActionSubmit") {
        newReply = response.post;
        newReply.activityType = "ActionSubmitComment-fixed";
      }
      // replying to an announcement on /announcements
      else if ($scope.reply_type === "Post") {
        newReply = response.reply;
        newReply.activityType = "AnnouncementReply";
      }

      $scope.replies.push(newReply);

      $scope.replyMessage = {
        message: "",
        attacheType: "",
        files: [],
      };
    }

    // ng-click functions for inside of template

    // on-click for profile name
    $scope.goToProfile = () => {
      $location.path(`/profile/${$scope.post.user.id}/view`);
    };

    // on press for "View Activity" button
    $scope.goToContent = () => {
      if ($scope.content_link_url) {
        $location.path($scope.content_link_url);

        // collapse activity bar if on mobile
        if ($rootScope.isMobile) {
          $rootScope.toggleActivityExpanded();
        }
      }
    };

    // toggles showing replies underneath (attached to the "x replies" button)
    $scope.showReplies = false;
    $scope.toggleReplies = () => {
      $scope.showReplies = !$scope.showReplies;

      //Disable refreshing activity while comment section is open
      if($scope.showReplies){
            $rootScope.activityRefresh = false;
        } else {
            $rootScope.activityRefresh = true;
        }
      };
  }
})();
