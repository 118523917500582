angular.module('unleeshApp').filter('cut', function () {
	return function (value, wordwise, max, tail) {
        if (!value) return '';
		value = value.replace(/(<\/?(?:a)[^>]*>)|<[^>]+>/ig, '$1');
		max = parseInt(max, 10);
		if (!max) return value;
		if (value.length <= max) return value;

		value = value.substr(0, max);
		if (wordwise) {
			var lastspace = value.lastIndexOf(' ');
			if (lastspace != -1) {
				value = value.substr(0, lastspace);
			}
		}

		return value + (tail || ' …');
	};
});
