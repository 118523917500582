'use strict';

angular.module('unleeshApp').service('mediaCam', [ '$window', '$q',
	function($window, $q) {
		var $this = this;

		$this.hasGetUserMedia = function() {
			return !!($window.navigator.getUserMedia || $window.navigator.webkitGetUserMedia ||
				$window.navigator.mozGetUserMedia || $window.navigator.msGetUserMedia);
		};

		$this.getUserMedia = function(oprions, callback, error) {
			if(!!$window.navigator.getUserMedia) {
				return $window.navigator.getUserMedia(oprions, callback, error);
			}
			if(!!$window.navigator.webkitGetUserMedia) {
				return $window.navigator.webkitGetUserMedia(oprions, callback, error);
			}
			if(!!$window.navigator.mozGetUserMedia) {
				return $window.navigator.mozGetUserMedia(oprions, callback, error);
			}
			if(!!$window.navigator.msGetUserMedia) {
				return $window.navigator.msGetUserMedia(oprions, callback, error);
			}
		};

		$this.get$q = function () {
			return $q;
		};


		/**
		 * Get random name files
		 * @returns string
		 */

		$this.getRandomName = function(l) {
			var name = "";
			var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

			for( var i=0; i < l; i++ )
				name += possible.charAt(Math.floor(Math.random() * possible.length));

			return name;
		};


		$this.audioRecord = function() {
			var deferred = $this.get$q().defer();
			if($this.hasGetUserMedia()) {
				$window.navigator.webkitGetUserMedia(
					{audio: true, video: false},
					function(stream) {
						var record = RecordRTC(stream);
						deferred.resolve(record, stream);
					}, function(error) {
						//console.log(error);
						deferred.reject(error);
					}
				)
			} else {
				//console.log('not support');
				return false;
			}
			return deferred.promise;
		};

		$this.screenRecord = function() {
			if($this.hasGetUserMedia()) {
				var deferred = $this.get$q().defer();
				$this.getUserMedia(
					{audio: true, video: true},
					function(stream) {
						var record = RecordRTC(stream);
						deferred.resolve(record, stream);
					}, function(error) {
						//console.log(error);
						deferred.reject(error);
					}
				)
			} else {
				//console.log('not support');
				deferred.reject({message: 'not support'});
			}

			return deferred.promise;
		};


	}
]);