(function() {
    'use strict';

    angular.module('unleeshApp').directive('attachmentModal', attachmentModal);
    attachmentModal.$inject = ['$document', 'video', 'audio', '$timeout'];

    function attachmentModal($document, video, audio, $timeout) {
        var image, sourceWrapper;
        return {
            restrict: 'E',
            templateUrl: 'sources/template/directive/attachment-modal.html',
            scope: {
                isOpened: '=',
                type: '=',
                src: '='
            },
            controller: controller
        };

        function controller($scope) {
            $scope.onOkClick = $scope.onCancelClick = onModalClose;
            $scope.$watch('isOpened', onOpenedChange);
            $timeout(initElements);

            function onOpenedChange(isOpened) {
                if (!isOpened) {
                    video.stop();
                    video.elem.remove();
                    audio.stop();
                    angular.element(audio.elem).remove();
                    image ? image.style.display = 'none' : 1;
                    return;
                }
                switch($scope.type) {
                    case 'video':
                        video.play($scope.src);
                        sourceWrapper.append(video.elem);
                        break;
                    case 'audio':
                        audio.play($scope.src);
                        sourceWrapper.append(audio.elem);
                        break;
                    case 'image':
                        image.src = $scope.src;
                        image.style.display = 'block';
                        break;
                }
            }

            function onModalClose() {
                $scope.isOpened = false;
                $scope.type = '';
                $scope.src = '';
            }
        }

        function initElements() {
            sourceWrapper = $('#source-modal-content');
            sourceWrapper.append(video.elem);
            video.stop();
            video.elem.remove();
            sourceWrapper.append(audio.elem);
            angular.element(audio.elem).remove();
            audio.stop();
            image = $document[0].createElement('img');
            image.style.display = 'none';
            sourceWrapper.append(image);
        }
    }

})();
