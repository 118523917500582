(function() {
    'use strict';

    angular.module('unleeshApp').service('searchService', searchService);
    searchService.$inject = ['doRequest', 'config', 'US'];

    function searchService(doRequest, config, US) {
        var $this = this;

        //settings
        $this.newUrlSuffix = function () {
            return 'search';
        };

        //url
        $this.getSearchUrl = function () {
            return config.appHost + US.urlSeparator() + $this.newUrlSuffix() + US.urlSeparator();
        };

        /**
         * GET
         * Get List Users
         * @param paths
         * @param callback
         * @returns {*}
         */
        $this.search = function(query, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getSearchUrl(),
                {token: US.getToken(), query: query},
                callback
            )

        };
    }
})();