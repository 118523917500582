(function() {
    'use strict';

    angular.module('unleeshApp').service('pathService', pathService);

    pathService.$inject = ['doRequest', 'config', 'US'];

    function pathService(doRequest, config, US) {
        var $this = this;

        $this.pathsUrlSuffix = function () {
            return 'paths';
        };

        $this.startUrlSuffix = function () {
            return 'start';
        };
        $this.startedUrlSuffix = function () {
            return 'started';
        };
        $this.statusUrlSuffix = function () {
            return 'status';
        };
        $this.pathPointsUrlSuffix = function () {
            return 'points-list';
        };

        $this.accessCodeUrlSuffix = function () {
            return 'paths/access_code';
        }

        $this.securePathsUrlSuffix = function () {
            return 'secure/path';
        }

        $this.secureLevelUrlSuffix = function () {
            return 'secure/level';
        }

        $this.secureStepUrlSuffix = function () {
            return 'secure/action';
        }

        //url
        $this.getPathsUrl = function () {
            return config.appHost + US.urlSeparator() + $this.pathsUrlSuffix() + US.urlSeparator();
        };
        $this.getPathsStatusUrl = function () {
            return $this.getPathsUrl() + $this.statusUrlSuffix() + US.urlSeparator();
        };

        $this.getAccessCodeUrl = function () {
            return config.appHost + US.urlSeparator() + $this.accessCodeUrlSuffix() + US.urlSeparator();
        }

        $this.getSecureEditPathUrl = function (id) {
            return  document.location.origin + '/api/v3' +  US.urlSeparator() + $this.securePathsUrlSuffix() + US.urlSeparator() + id  + US.urlSeparator();
        };

        $this.getSecureEditLevelUrl = function (id) {
            return  document.location.origin + '/api/v3' +  US.urlSeparator() + $this.secureLevelUrlSuffix() + US.urlSeparator() + id  + US.urlSeparator();
        };

        $this.getSecureEditStepUrl = function (id) {
            return  document.location.origin + '/api/v3' +  US.urlSeparator() + $this.secureStepUrlSuffix() + US.urlSeparator() + id  + US.urlSeparator();
        };

        $this.getPathsStartedUrl = function () {
            return $this.getPathsUrl() + $this.startedUrlSuffix() + US.urlSeparator();
        };
        $this.getPathsPointsListUrl = function () {
            return $this.getPathsUrl() + $this.pathPointsUrlSuffix() + US.urlSeparator();
        };

        $this.getPathSetCurrentUrl = function (id) {
            return config.appHost + US.urlSeparator() + $this.pathsUrlSuffix() + US.urlSeparator() + id + US.urlSeparator() + $this.startUrlSuffix() + US.urlSeparator();
        };
        /**
         * Get info path to display
         * @param id - path id
         * @returns {string}
         */
        $this.getPathUrl = function (id) {
            return config.appHost + US.urlSeparator() + $this.pathsUrlSuffix() + US.urlSeparator() + id + US.urlSeparator();
        };

        /**
         * Get info path to display
         * @param id - path id
         * @returns {string}
         */
        $this.getPathv3Url = function (id) {
            return  document.location.origin + '/api/v3' +  US.urlSeparator()
                + 'paths' + US.urlSeparator() +
                + id + US.urlSeparator();
        };

        $this.getPathPhasesUrl = function (id) {
            return  document.location.origin + '/api/v3' +  US.urlSeparator()
                + 'paths' + US.urlSeparator() +
                + id + US.urlSeparator()
                + 'phases' + US.urlSeparator()
                ;
        };


        ///========================================== NEW LINKS =========================================///
        $this.getPathsStartedUrl_NEW = function () {
            return document.location.origin + '/api/v3' +  US.urlSeparator()
                + 'users' + US.urlSeparator()
                + 'paths' + US.urlSeparator();
            //return $this.getPathsUrl() + $this.startedUrlSuffix() + US.urlSeparator();
        };

        $this.loadCurrentPathUrl = function () {
            return document.location.origin + '/api/v3' +  US.urlSeparator()
                + 'users' + US.urlSeparator()
                + 'currentPath' + US.urlSeparator();
        }

        /*$this.getUserPathsUrl_NEW = function () {
            return document.location.origin + '/api/v3/' + US.urlSeparator()
        };*/

        ///============================================= END NEW LINKS ==================================///

        /**
         * GET
         * Get ALL Path
         * @param token
         * @param callback
         * @returns {*}
         */
        $this.getPaths = function (callback) {
            var $this = this;

            US.get$rootScope().isLoaded = true;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getPathsUrl(),
                {token: US.getToken()},
                callback
            )
        };

        /**
         * get path started
         * @param callback
         * @returns {*}
         */
        $this.getPathsStarted = function (entity, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getPathsStartedUrl() + '?token=' + US.getToken(),
                entity,
                callback
            )
        };

        /**
         * get path started new
         * @param callback
         * @returns {*}
         */
        $this.getPathsStarted_NEW = function (entity, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getPathsStartedUrl_NEW() + '?token=' + US.getToken(),
                entity,
                callback
            )
        };

        /**
         * Load user current path directly
         * @param entity
         * @param callback
         * @returns {*}
         */
        $this.loadCurrentPath = function (entity, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.loadCurrentPathUrl() + '?token=' + US.getToken(),
                entity,
                callback
            )

        }


        /**
         * GET
         * Get path statuses.
         */
        $this.getPathsStatuses = function (entity, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getPathsStatusUrl() + '?token=' + US.getToken(),
                entity,
                callback
            )
        };

        /**
         * POST
         * Save path statuses.
         */
        $this.savePathStatuses = function (statuses, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.POST,
                $this.getPathsStatusUrl() + '?token=' + US.getToken(),
                statuses,
                callback
            )
        };

        /**
         * POST
         * Use access code.
         */
        $this.useAccessCode = function (accessCode, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.POST,
                $this.getAccessCodeUrl() + '?token=' + US.getToken(),
                {accessCode: accessCode},
                callback
            )
        };

        /**
         * POST
         * Secure edit path
         */
        $this.secureEditPath = function (path_id, editPathData, callback){
            var $this = this;

            return doRequest.doRequestFile(
                US.REQUEST_TYPE.POST,
                $this.getSecureEditPathUrl(path_id) + '?token=' + US.getToken(),
                editPathData,
                callback
            )
        }

        /**
         * POST
         * Secure edit level
         */
        $this.secureEditSection = function (phase_id, editLevelData, callback){
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.POST,
                $this.getSecureEditLevelUrl(phase_id) + '?token=' + US.getToken(),
                {editLevelData: editLevelData},
                callback
            )
        }

        /**
         * POST
         * Secure edit step
         */
        $this.secureEditStep = function (step_id, editStepData, callback){
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.POST,
                $this.getSecureEditStepUrl(step_id) + '?token=' + US.getToken(),
                {editStepData: editStepData},
                callback
            )
        }

        /**
         * GET
         * Get path and its user total points count.
         * @param callback
         * @returns {*}
         */
        $this.getPathsPointsList = function (callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getPathsPointsListUrl(),
                {token: US.getToken()},
                callback
            )
        };

        /**
         * GET
         * Set Current path
         * @param id
         * @param token
         * @param callback
         * @returns {*}
         */
        $this.setCurrentPath = function (id, token, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getPathSetCurrentUrl(id),
                {token: US.getToken()},
                callback
            )
        };

        $this.getPath = function (id, token, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getPathUrl(id),
                {token: US.getToken()},
                callback
            )
        };

        $this.getPathv3 = function(id, token, callback){
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getPathv3Url(id),
                {token: US.getToken()},
                callback
            )
        }

        $this.getPathPhases = function(id, token, callback){
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getPathPhasesUrl(id),
                {token: US.getToken()},
                callback
            )
        }

    }
})();
