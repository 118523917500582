'use strict';

angular.module('unleeshApp')
	.directive('eventNew', function (eventService, $rootScope, $timeout) {
		return{
			restrict: 'A',
			scope: {
				interval:'@'
			},
			link: function (scope, element, attr) {
				function getNewEvent() {
					eventService.getEventNewCount($rootScope.user.token)
						.then(function(response) {
							if(response.statusCode == 200) {
								$rootScope.countNewEvent = response.count;
								trigger = $timeout(function() {
									getNewEvent();
								}, interval)
							}else if(response.statusCode == 401) {
								$rootScope.$emit('userLogout', response);
							}
						}, function() {
							trigger = $timeout(function() {
								getNewEvent();
							}, interval)
						});
				}
				var interval = attr.interval || 50000;
				$rootScope.countNewEvent = 0;

				var trigger = null;
				getNewEvent();
			}
		};
	});
