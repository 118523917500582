(function() {
    'use strict';

    require('../../bower_components/angular-local-storage/dist/angular-local-storage.js');
    require('angular-animate');
    require('../../node_modules/ng-file-upload/dist/ng-file-upload.js');
    require('../../bower_components/ngmap/build/scripts/ng-map.js');
    require('../../bower_components/angular-ui-date/src/date.js');
    require('../../bower_components/angular-mask/dist/ngMask.js');
    require('../../node_modules/ng-clip/src/ngClip.js');

    var app = angular.module('unleeshApp', [
        require('angular-route'),
        'LocalStorageModule',
        require('angular-sanitize'),
        'ngFileUpload',
        'ngMap',
        'ui.date',
        'ngMask',
        'ngClipboard',
        'angular-redactor'
    ]);

    // Relative time directive definition.
    var moment = require('../../node_modules/ng-time-relative/node_modules/moment/moment.js');
    app.constant('moment', moment);
    var ngTimeRelative = require('ng-time-relative');
    ngTimeRelative(app);

    // Init configuration.
    var config = require('../config/config.js');
    var localConfig = require('../config/config-local.js');
    angular.extend(config, localConfig);
    app.constant('config', config);

    app.run(run);
    run.$inject = ['$rootScope', '$location', 'localStorageService', '$timeout', 'US', '$window', 'config', 'doRequest'];

    function run($rootScope, $location, localStorage, $timeout, US, $window, config, doRequest) {
        $('.show-body').removeClass('show-body');

        $rootScope.isLoaded = false;

        initFacebookApp();

        firebase.initializeApp({
            messagingSenderId: '6661255250'
        });

        if ('Notification' in window) {
            if (Notification.permission !== 'denied') {
                var messaging = firebase.messaging();

                /*if (Notification.permission === 'granted') {
                    subscribe();
                }*/

                subscribe();
            } else {
                console.log('Pushes denied');
            }
        }

        function subscribe() {
            messaging.requestPermission()
                .then(function () {
                    messaging.getToken()
                        .then(function (currentToken) {
                            if (currentToken) {
                                //If user authorized sync user token on the server
                                if($rootScope.user && ($rootScope.user.authorized == true)){
                                    console.log('User authorized and syncing');
                                    sendTokenToServer(currentToken);
                                } else {

                                }

                            } else {
                                console.warn('No current token returned');
                                //clear local storage token since it's not valid
                                setTokenSentToServer(false);
                            }
                        })
                        .catch(function (err) {
                            console.warn('Error while getting token', err);
                            //clear local storage token since it's not valid
                            setTokenSentToServer(false);
                        });
                })
                .catch(function (err) {
                    console.warn('Error getting permission', err);
                });
        }

        function sendTokenToServer(currentToken) {
            console.log('Sending token to server');
            if (!isTokenSentToServer(currentToken)) {
                var subscribeToken = {'stoken': currentToken};

                doRequest.doRequest(
                    US.REQUEST_TYPE.POST,
                    '/api/push/subscribe/' + '?token=' + US.getToken(),
                    subscribeToken
                );

                setTokenSentToServer(currentToken);
            } else {
                console.log('Token already sent');
            }
        }

        if(messaging){
            messaging.onMessage(function(payload) {
                 // register fake ServiceWorker for show notification on mobile devices
                 navigator.serviceWorker.register('/firebase-messaging-sw.js');
                 Notification.requestPermission(function(permission) {
                     if (permission === 'granted') {
                         navigator.serviceWorker.ready.then(function(registration) {
                             // Copy data object to get parameters in the click handler
                             payload.data.data = JSON.parse(JSON.stringify(payload.data));

                             registration.showNotification(payload.data.title, payload.data);
                         }).catch(function(error) {
                             // registration failed :(
                             showError('ServiceWorker registration failed', error);
                         });
                     }
                 });
            });

            // Callback fired if Instance ID token is updated.
            messaging.onTokenRefresh(function() {
                messaging.getToken()
                    .then(function(refreshedToken) {
                        // Send Instance ID token to app server.
                        sendTokenToServer(refreshedToken);
                        //updateUIForPushEnabled(refreshedToken);
                    })
                    .catch(function(error) {
                        //showError('Unable to retrieve refreshed token', error);
                    });
            });
        }

        function isTokenSentToServer(currentToken) {
            if(currentToken){
                return localStorage.get('sentFirebaseMessagingToken') == currentToken;
            } else {
                return false;
            }
        }

        function setTokenSentToServer(currentToken) {
            localStorage.set(
                'sentFirebaseMessagingToken',
                currentToken ? currentToken : ''
            );
        }

        if (localStorage.get('user')) {
            $rootScope.user = localStorage.get('user');
            US.setToken($rootScope.user.token);
        } else {
            $rootScope.user = {authorized: false};
        }

        if (localStorage.get('currentPath')) {
            $rootScope.currentPath = localStorage.get('currentPath');
        }

        /**
         * Get Current Step to display
         * @param scope
         * @param currentPath
         * @returns {*}
         */
        $rootScope.getCurrentPath = getCurrentPath;

        /**
         * Handler response request
         * @param scope
         * @param response
         * @param callback
         */
        $rootScope.handleResponse = handleResponse;

        $rootScope.$on('$routeChangeStart', routeChangeStartHandler);
        $rootScope.$on('userAuthorized', userAuthorizedHandler);
        $rootScope.$on('userEdit', userEditHandler);
        $rootScope.$on('userLogout', userLogoutHandler);
        $rootScope.$on('endLoaded', endLoadedHandler);
        $rootScope.$on('errorMessage', errorMessageHandler);
        $rootScope.$on('currentPath', currentPathHandler);

        function initFacebookApp() {
            $window.fbAsyncInit = function() {
                FB.init({
                    appId: config.facebookAppId,
                    channelUrl: 'sources/template/profile/channel.html',
                    status: true,
                    cookie: true
                });
            };
            (function(d){
                var js,
                    id = 'facebook-jssdk',
                    ref = d.getElementsByTagName('script')[0];

                if (d.getElementById(id)) {
                    return;
                }

                js = d.createElement('script');
                js.id = id;
                js.async = true;
                js.src = "//connect.facebook.net/en_US/all.js";

                ref.parentNode.insertBefore(js, ref);
            }(document));
        }

        function routeChangeStartHandler() {
            // If user is not authorized then redirect to login page.
            if($location.path() === '/sign-up') {
                return;
            }
            if (!$rootScope.user.authorized) {
                $location.path('/login');
            } else if ($location.url() == '/login' || $location.url() == '/sign-up') {
                $location.path('/');
            }
        }

        function userAuthorizedHandler(event, data) {
            $rootScope.user = data;
            $rootScope.user.authorized = true;
            localStorage.set('user', $rootScope.user);
            US.setToken($rootScope.user.token);

            //Sync user and this browser
            if(messaging){
                //Enable sync between user and this browser
                subscribe();
            }

            $location.path('/');
        }

        function userEditHandler(event, data) {
            $rootScope.user = data;
            $rootScope.user.authorized = true;
            localStorage.set('user', $rootScope.user);
            US.setToken($rootScope.user.token);
            $location.path('/profile');
        }

        function userLogoutHandler(event, data) {
            $rootScope.user = {};
            $rootScope.user.authorized = false;
            $rootScope.currentPath = {};
            localStorage.set('user');
            localStorage.set('filterRAIds');
            localStorage.set('filter');
            localStorage.set('currentPath');

            if(messaging){
                //Disable sync between user and this browser
                sendTokenToServer(false);

                /*var deleteToken = localStorage.get('sentFirebaseMessagingToken');

                 messaging.deleteToken(deleteToken)
                 .then(function(dresponse){
                 localStorage.set('sentFirebaseMessagingToken');
                 })
                 .catch(function(error) {
                 //showError('Unable to retrieve refreshed token', error);
                 });*/
            }
            US.clearToken();

            $location.path('/');
        }

        function endLoadedHandler(event, data) {
            $rootScope.isLoaded = false;
        }

        function errorMessageHandler(event, data) {
            if(data.status == 413) {
                $rootScope.errorMessage = {status: true, text: 'The size of the file is too big.'}
            } else
            if (data.responseText) {
                data.responseText = JSON.parse(data.responseText);
                $rootScope.errorMessage = {status: true, text: data.responseText.status};
            }
            else {
                $rootScope.errorMessage = {status: true, text: data.statusText};
            }
            if (!data.isLogin && data.status && $.inArray(data.status, [404, 403]) !== -1) {
                if ($window.history.length > 1) {
                    $window.history.back();
                }
                else {
                    $location.path('/');
                }
            }
            $rootScope.closeError = function() {
                $rootScope.errorMessage = {status: false, text: ''};
            };
        }

        //Injects current path into local storage
        function currentPathHandler(event, date) {
            $rootScope.currentPath = date;
            localStorage.set('currentPath', $rootScope.currentPath);
        }

        //forms current path structure
        function getCurrentPath(scope, currentPath) {
            if(typeof(currentPath) != 'object') {
                return false;
            }
            var arrayChange = currentPath.phases,
                isHide = false,
                isBreak = false;
            scope.availableStep = [];

            angular.forEach(arrayChange, function(val) {

                angular.forEach(val.levels, function(lVal) {
                    var isAdd = isHide;
                    if(!isBreak && lVal.levelPoints < lVal.targetPoints) {
                        isHide = true;
                        isBreak = true;
                    }
                    angular.forEach(lVal.actions, function(sVal) {
                        if(sVal.completed || (scope.availableStep.length >= 3) || isAdd) {
                            return true;
                        }
                        sVal.lessenId = val.id;
                        sVal.levelId = lVal.id;
                        scope.availableStep.push(sVal);
                    });
                });
            });
            scope.$emit('currentPath', currentPath);
            scope.currentPath = currentPath;
            return scope;
        }

        function handleResponse(scope, response, callback) {
            if($.inArray(response.statusCode, [200, 201]) !== -1) {
                callback(response);
            } else if (response.statusCode == 401) {
                scope.$emit('userLogout', response);
            } else if (response.statusCode == 403) {
                scope.$emit('errorMessage', {statusText: response.message});
            } else if(response.statusCode == 500) {
                scope.$emit('errorMessage', {statusText: response.message});
            }
        }
    }

})();
