(function () {
  "use strict";

  angular.module("unleeshApp").config(config);
  config.$inject = ["$routeProvider"];

  function config($routeProvider) {
    $routeProvider
      .when("/", {
        redirectTo: "/current-path",
      })
      //path
      .when("/current-path", {
        controller: "CurrentPathController",
        templateUrl: "sources/template/home-page.html",
        // old: templateUrl: "sources/old-template/path/current-path.html",
      })
      .when("/path/view/:id", {
        controller: "PathLandingController",
        templateUrl: "sources/template/path-page.html",
        // old: templateUrl: "sources/old-template/path/path-landing.html",
      })
      .when("/path/step/:lessen&:lvl&:id", {
        controller: "StepLandingController",
        templateUrl: "sources/template/steps-page.html",
        // old: templateUrl: "sources/old-template/path/step-landing.html",
      })
      //profile
      .when("/profile", {
        //view profile
        controller: "ProfileController",
        templateUrl: "sources/template/profile.html",
      })
      //unsubscribe settings
      .when("/unsubscribe", {
          //unsubscribe
          controller: "UnsubscribeController",
          templateUrl: "sources/template/unsubscribe.html",
      })
      //push settings
      .when("/push", {
          //unsubscribe
          controller: "PushController",
          templateUrl: "sources/template/push.html",
      })
      // I don't think this is necessary, but left for historical reasons/backward compatibility for links
      .when("/profile/:id/edit", {
        //edit profile
        controller: "ProfileController",
        templateUrl: "sources/template/profile.html",
      })
      .when("/profile/:id/view", {
        //view user profile
        controller: "ProfileController",
        templateUrl: "sources/template/profile.html",
      })
      .when("/login", {
        //login profile
        controller: "LoginController",
        templateUrl: "sources/template/sign-in.html",
      })
      .when("/sign-up", {
        //register profile
        controller: "SignUpController",
        templateUrl: "sources/template/sign-up.html",
        // old: templateUrl: "sources/old-template/profile/sign-up.html",
      })
      .when("/sign-out", {
        //logout profile
        controller: "LogoutController",
        templateUrl: "sources/template/sign-out.html",
      })
      //events
      .when("/events", {
        controller: "EventsController",
        templateUrl: "sources/template/breakouts-page.html",
        // old: templateUrl: "sources/old-template/events/events.html",
      })
      .when("/event/add", {
        controller: "AddEventController",
        templateUrl: "sources/template/breakouts-edit-page.html",
      })
      .when("/event/edit/:id", {
        controller: "AddEventController",
        templateUrl: "sources/template/breakouts-edit-page.html",
        // old: controller: "EventEditController",
        // old: templateUrl: "sources/old-template/events/add-event.html",
      })
      .when("/event/view/:id", {
        controller: "EventLandingController",
        templateUrl: "sources/template/breakouts/breakouts-event.html",
        // old: templateUrl: "sources/old-template/events/event-landing.html",
      })
      // Pathmail
      .when("/pathmail", {
        controller: "PathMail",
        templateUrl: "sources/template/pathmail/pathmail-home.html",
        // old: templateUrl: "sources/old-template/pathmail/pathmail.html",
      })
      .when("/pathmail/thread/:id", {
        controller: "PathmailThread",
        templateUrl: "sources/template/pathmail/pathmail-thread.html",
        // old: templateUrl: "sources/old-template/pathmail/thread.html",
      })
      .when("/pathmail/threads/new-thread/:id", {
        controller: "NewThread",
        templateUrl: "sources/template/pathmail/pathmail-new-thread.html",
        // old: templateUrl: "sources/old-template/pathmail/new-thread.html",
      })
      .when("/pathmail/threads/new-thread", {
        controller: "NewThread",
        templateUrl: "sources/template/pathmail/pathmail-new-thread.html",
        // old: templateUrl: "sources/old-template/pathmail/new-thread.html",
      })
      //pathmates
      .when("/pathmates", {
        // view pathmates
        // old: templateUrl: "sources/old-template/pathmates/pathmates.html",
        controller: "PathMatesController",
        templateUrl: "sources/template/pathmates.html",
      })
      .when("/announcements", {
        controller: "AnnouncementsPageController",
        templateUrl: "sources/template/announcements-page.html",
      })
      //search
      .when("/search",{
        controller: "GlobalSearchController",
        templateUrl: "sources/template/global-search-page.html",
      })
      .when("/search/:string", {
        controller: "GlobalSearchResultsController",
        templateUrl: "sources/template/global-search-results-page.html",
      })
      .otherwise("/current-path");
  }
})();
