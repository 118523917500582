(function() {
    'use strict';

    angular.module('unleeshApp').config(config);
    config.$inject = ['localStorageServiceProvider'];

    function config(localStorageServiceProvider) {
        localStorageServiceProvider.setPrefix('unleeshApp');
    }
})();
