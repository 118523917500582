(function() {
    'use strict'
    angular.module('unleeshApp').service('RAService', recentActivityService)

    recentActivityService.$inject = ['doRequest', 'config', 'US'];

    function recentActivityService(doRequest, config, US) {
        var $this = this;

        $this.feedUrlSuffix = function () {
            return 'feed';
        };

        //settings
        $this.recentActivityUrlSuffix = function () {
            return 'recent-activity';
        };
        //url
        $this.getRecentActivity = function (id) {
            return config.appHost + US.urlSeparator() + US.newUrlSuffix() + US.urlSeparator() + id + US.urlSeparator() + $this.recentActivityUrlSuffix() + US.urlSeparator();
        };

        $this.getFeedRecentActivity = function () {
            return config.appHost + US.urlSeparator() + $this.feedUrlSuffix() + US.urlSeparator();
        };

        /**
         * GET
         * @param id
         * @param paths
         * @param page
         * @param limit
         * @param callback
         * @returns {*}
         */
        $this.getListRecentActivity = function (id, paths, page, limit, callback) {
            var $this = this;

            function getRandomInt(max) {
                return 2; //Math.floor(Math.random() * max);
            }

            if(getRandomInt(4) == 2 ){
                return doRequest.doRequest(
                    US.REQUEST_TYPE.GET,
                    $this.getRecentActivity(id),
                    {token: US.getToken(), paths: paths, page: page, limit: limit},
                    callback
                )
            } else {
                return false;
            }
        };

        /**
         * GET
         * @param page
         * @param limit
         * @param callback
         * @returns {*}
         */
        $this.getFeedListRecentActivity = function (page, limit, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getFeedRecentActivity(),
                {token: US.getToken(), page: page, limit: limit},
                callback
            )
        };
    }
})();
