/*
  This file contains the controller for announcements-page.html, 
  as well as the directive <announcement></announcement> used on that page.
  For route /announcements. 

  An "announcement" doesn't really exist in the system. It's repurposed "chats" (sometimes called "posts")
  from a path. In the old system chats were only accessible from the path page.
*/

(function () {
  "use strict";

  angular
    .module("unleeshApp")
    // plural is for the page
    .controller("AnnouncementsPageController", AnnouncementsPageController);

  // ---- page controller ----

  AnnouncementsPageController.$inject = [
    "$scope",
    "$rootScope",
    "postService",
    "US",
  ];

  function AnnouncementsPageController($scope, $rootScope, postService, US) {
    // "announcements" are just "chats" (sometimes called a "post") pulled out of paths

    new window.PostsScopeTrait(
      $scope,
      US,
      postService,
      null,
      null,
      $rootScope,
      null,
      null,
      null,
      null
    );

    $scope.announcements = [];
    $scope.loading = false;

    $scope.currentPage = 1;
    $scope.announcementLoadLimit = 10;

    // function to load announcements
    $scope.getAnnouncements = () => {
      $scope.loading = true;
      postService
        .getPosts(1, 10)
        .then((response) => {
          $rootScope.handleResponse($scope, response, (response) => {
            $scope.announcements = $scope.announcements.concat(response.posts);
            $scope.loading = false;
            formatAnnouncements();
            resetSearch();
          });
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Had an error while loading announcements`,
          });
        })
        .finally(() => {
          $scope.loading = false;
        });
    };

    // sets up announcements properly
    function formatAnnouncements() {
      $scope.announcements.forEach((ann) => {
        // add an activityType so that the <activity></activity> directive renders it correctly
        // "Announcement" is a madeup type that doesn't exist on the server
        ann.activityType = "Announcement";

        // save path name
        ann.pathName = `${$rootScope.currentPath.title}`;
      });
    }

    // set text for page header
    $scope.headerText = $rootScope.currentPath
      ? `Path Announcements for ${$rootScope.currentPath.title}`
      : `Path Announcements`;

    $scope.pathTitle = $rootScope.currentPath.title;

    // toggle whether to show the "write new announcement" box
    $scope.showWriteAnnouncement = false;
    $scope.toggleWriteAnnouncement = () => {
      $scope.showWriteAnnouncement = !$scope.showWriteAnnouncement;
    };

    // handle searching
    function resetSearch() {
      $scope.search = "";
      $scope.filteredAnnouncements = [...$scope.announcements];
    }
    resetSearch();

    $scope.$watch("search", () => {
      // If search is empty, show all
      if (!$scope.search || $scope.search.length === 0) {
        resetSearch();
        return;
      }

      const searchTerm = $scope.search.toLowerCase();

      $scope.filteredAnnouncements = $scope.announcements.filter((ann) => {
        const name = `${ann.user.firstName} ${ann.user.lastName}`;

        // match on any part of their name
        if (name.toLowerCase().includes(searchTerm)) {
          return true;
        }

        // match on the message
        if (ann.message.toLowerCase().includes(searchTerm)) {
          return true;
        }

        // no match, don't include
        return false;
      });
    });

    // ---- submit a new announcement ----

    // variables for write new announcement
    function resetNewAnnouncement() {
      $scope.newAnnouncementSending = false;
      $scope.newAnnouncement = {
        message: "",
        attacheType: "",
        files: [],
      };
    }
    resetNewAnnouncement();

    $scope.submitNewAnnouncement = () => {
      let formData = new FormData();

      // add text message
      formData.append("message", $scope.newAnnouncement.message);

      // add attachment if present
      if ($scope.newAnnouncement.files.length > 0) {
        formData.append(
          "file",
          $scope.newAnnouncement.files[$scope.newAnnouncement.files.length - 1],
          $scope.newAnnouncement.files[$scope.newAnnouncement.files.length - 1]
            .name
        );
        formData.append("attachmentType", $scope.newAnnouncement.attacheType);
      }

      // submit!
      $scope.newAnnouncementSending = true;
      postService
        .addPostFile(formData)
        .then((response) => {
          $rootScope.handleResponse($scope, response, () => {
            // add announcement to list
            $scope.announcements.unshift(response.post);

            formatAnnouncements();
            resetSearch();
            resetNewAnnouncement();
            $scope.toggleWriteAnnouncement();
          });
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Had an error while submitting new announcement`,
          });
        });
    };

    // load at begginning
    $scope.getAnnouncements();
  }
})();
