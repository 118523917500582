(function () {
  "use strict";

  require("../filters/cut.filter.js");
  require("../filters/line-brake.filter.js");
  require("../filters/user-name.filter.js");
  require("../filters/parse-link-to-event.filter.js");
  require("../filters/prepComment.filter.js");
  require("../filters/highlight.filter.js");
})();
