(function () {
    'use strict';

    angular.module('unleeshApp').factory('doRequest', doRequest)

    doRequest.$inject = ['$q', '$rootScope'];

    function doRequest($q, $rootScope) {
        var $this = this;

        $this.REQUEST_TYPE = {
            GET: 'GET',
            DELETE: 'DELETE',
            POST: 'POST',
            PUT: 'PUT'
        };
        $.ajaxSetup({
            cache: false
        });
        $this.get$http = function () {
            return $.ajax;
        };

        $this.get$q = function () {
            return $q;
        };

        $this.get$rootScope = function () {
            return $rootScope;
        };
        return {
            doRequest: function (type, url, query, callback, dontSkipLoading, timeoutRequest) {
                //var $this = this;
                //console.log($this);

                var data = '';

                switch (type) {
                    case $this.REQUEST_TYPE.GET:
                    case $this.REQUEST_TYPE.DELETE:
                        data = query;
                        break;
                    case $this.REQUEST_TYPE.POST:
                    case $this.REQUEST_TYPE.PUT:
                        data = query;
                        break;
                }

                var deferred = $this.get$q().defer();
                $this.get$http()({
                    method: type,
                    url: url,
                    data: data,
                    crossDomain: true,
                    timeout: timeoutRequest | $this.timeout,
                    success: function (data) {
                        deferred.resolve(data);
                    },
                    error: function (error) {
                        deferred.reject(error);
                    },
                    complete: function () {
                        if (dontSkipLoading) {
                            return;
                        }
                        //$this.get$rootScope().isLoaded = false;
                    }
                });

                return deferred.promise;
            },
            doRequestFile: function (type, url, query, callback, timeoutRequest) {

                var data = '';

                switch (type) {
                    case $this.REQUEST_TYPE.GET:
                    case $this.REQUEST_TYPE.DELETE:
                        data = query;
                        break;
                    case $this.REQUEST_TYPE.POST:
                    case $this.REQUEST_TYPE.PUT:
                        data = query;
                        break;
                }
                //console.log("file",url);
                var deferred = $this.get$q().defer();
                $this.get$http()({
                    method: type,
                    url: url,
                    data: data,
                    crossDomain: true,
                    cache: false,
                    contentType: false,
                    processData: false,
                    //timeout: timeoutRequest | $this.timeout,
                    success: function (data) {
                        //$this.get$rootScope().isLoaded = false;
                        deferred.resolve(data);
                    },
                    error: function (error) {
                        //$this.get$rootScope().isLoaded = false;
                        deferred.reject(error);
                    }
                });

                return deferred.promise;
            }
        }
    }
})();