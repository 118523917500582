(function () {
  "use strict";

  require("../directive/tabs.directive.js");
  require("../directive/active-tracking.js");
  require("../directive/modal.directive.js");
  require("../directive/notification-event.directive.js");
  require("../directive/notification-pathmails.directive.js");
  require("../directive/add-more.directive.js");
  require("../directive/progress-line.directive.js");
  require("../directive/time-watch.directive.js");
  require("../directive/message-attach-form.directive.js");
  require("../directive/path-filter-modal.directive.js");
  require("../directive/attachment.directive.js");
  require("../directive/attachment-modal.directive.js");
  require("../directive/fixed-panel.directive.js");
  require("../directive/activity.js");
  require("../directive/loading.js");
  require("../directive/modal.js");
})();
