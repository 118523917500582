(function() {
    'use strict';
    angular.module('unleeshApp').service('submitService', submitService);

    submitService.$inject = ['doRequest', 'config', 'US'];

    function submitService(doRequest, config, US) {
        var $this = this;

        $this.getActionUrlSuffix = function () {
            return 'actions'
        };

        $this.getPrivateUrlSuffix = function () {
            return 'private'
        };

        $this.getSubmitUrlSuffix = function () {
            return 'submit';
        };
        $this.submitUrlSuffix = function () {
            return 'submits';
        };
        $this.postUrlSuffix = function () {
            return 'posts';
        };

        $this.discussionsUrlSuffix = function () {
            return 'discussions';
        };
        $this.replyUrlSuffix = function () {
            return 'replies';
        };
        $this.commentUrlSuffix = function () {
            return 'comments';
        };
        //url

        $this.setPostUrl = function () {
            return config.appHost + US.urlSeparator() + $this.discussionsUrlSuffix() + US.urlSeparator() + $this.postUrlSuffix() + US.urlSeparator();
        };

        $this.getPostCommentUrl = function (id) {
            return $this.setPostUrl() + id + US.urlSeparator() + $this.replyUrlSuffix() + US.urlSeparator();
        };
        $this.getComments = function (id) {
            return config.appHost + US.urlSeparator() + $this.submitUrlSuffix() + US.urlSeparator() + id + US.urlSeparator() + $this.commentUrlSuffix() + US.urlSeparator();
        };

        //url
        // /actions/{actionId}/submits/
        $this.getActionSubmitsUrl = function (id) {
            return config.appHost + US.urlSeparator() + $this.getActionUrlSuffix() + US.urlSeparator() + id + US.urlSeparator() + $this.submitUrlSuffix() + US.urlSeparator()
        };

        $this.getSubmitUrl = function () {
            return config.appHost + US.urlSeparator() + $this.submitUrlSuffix() + US.urlSeparator();
        };

        $this.getSetSubmitUrl = function (id) {
            return $this.getSubmitUrl() + id + US.urlSeparator();
        };

        $this.getDeleteSubmitUrl = function (id) {
            return $this.getSubmitUrl() + id + US.urlSeparator();
        };

        $this.getPublicSubmit = function (id) {
            return config.appHost + US.urlSeparator() + $this.getActionUrlSuffix() + US.urlSeparator() + id + US.urlSeparator() + $this.getSubmitUrlSuffix() + US.urlSeparator();
        };
        $this.getPrivateSubmit = function (id) {
            return $this.getPublicSubmit(id) + $this.getPrivateUrlSuffix() + US.urlSeparator();
        };
        /**
         * REQUEST SUBMIT
         *
         * GET
         * Get list comments submit
         * @param entity
         * @param callback
         * @returns {*}
         */
        $this.getListComments = function (entity, callback) {
            var $this = this;

            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getComments(entity.id),
                {token: US.getToken()},
                callback
            )
        };


        /**
         * POST
         * Set comments not file
         * @param entity
         * @param submitId
         * @param callback
         * @returns {*}
         */
        $this.setListComments = function (entity, submitId, type, callback) {
            var $this = this;
            var URL = '';
            switch (type) {
                case 'Post':
                case 'Reply':
                    URL = $this.getPostCommentUrl(submitId);
                    break;
                case 'ActionSubmitComment':
                case 'ActionSubmit':
                    URL = $this.getComments(submitId);
                    break;
            }
            return doRequest.doRequest(
                US.REQUEST_TYPE.POST,
                URL + "?token=" + US.getToken(),
                entity,
                callback
            )
        };

        /**
         * POST
         * Set comments to files
         * @param entity
         * @param submitId
         * @param type
         * @param callback
         * @returns {*}
         */
        $this.setListCommentsFile = function (entity, submitId, type, callback) {
            var $this = this;
            var URL = '';
            switch (type) {
                case 'Post':
                case 'Reply':
                    URL = $this.getPostCommentUrl(submitId);
                    break;
                case 'ActionSubmitComment':
                case 'ActionSubmit':
                    URL = $this.getComments(submitId);
                    break;
            }
            return doRequest.doRequestFile(
                US.REQUEST_TYPE.POST,
                URL + "?token=" + US.getToken(),
                entity,
                callback
            )
        };

        /**
         * POST
         * Edit submit to files
         * @param entity
         * @param submitId
         * @param callback
         * @returns {*}
         */
        $this.setSubmit = function (entity, submitId, callback) {
            var $this = this;
            return doRequest.doRequestFile(
                US.REQUEST_TYPE.POST,
                $this.getSetSubmitUrl(submitId) + "?token=" + US.getToken(),
                entity,
                callback
            )
        };

        // /submits/{submitId}/

        /**
         * DELETE
         * Delete submit
         * @param id
         * @param callback
         * @returns {*}
         */
        $this.deleteSubmit = function (id, callback) {
            var $this = this;
            return doRequest.doRequestFile(
                US.REQUEST_TYPE.DELETE,
                $this.getDeleteSubmitUrl(id) + "?token=" + US.getToken(),
                {},
                callback
            )
        };


        /**
         * POST
         * set submit
         * @param entity
         * @param actionId
         * @param type - boolean (public/private - true/false)
         * @param callback
         * @returns {*}
         */
        $this.actionsSubmitFiles = function (entity, actionId, type, callback) {
            var $this = this;
            var URL = null;
            if (type) {
                URL = $this.getPublicSubmit(actionId);
            } else {
                URL = $this.getPrivateSubmit(actionId);
            }
            return doRequest.doRequestFile(
                US.REQUEST_TYPE.POST,
                URL + "?token=" + US.getToken(),
                entity,
                callback
            )
        };

        /**
         * GET
         * Get submits selected actions
         * @param entity
         * @param id
         * @param callback
         * @returns {*}
         */
        $this.getActionSubmits = function (id, page, limit, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getActionSubmitsUrl(id),
                {
                    page: page,
                    limit: limit,
                    token: US.getToken()
                },
                callback
            )
        };
    }
})();