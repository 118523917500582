(function() {
    'use strict';

    angular.module('unleeshApp').service('userService', userService);
    userService.$inject = ['doRequest', 'config', 'US'];

    function userService(doRequest, config, US) {
        var $this = this;
        /*
         * Users
         * */
        //settings
        $this.newUrlSuffix = function () {
            return 'users';
        };

        $this.editProfileUrlSuffix = function () {
            return 'edit-profile';
        };

        $this.passwordResetUrlSuffix = function () {
            return 'reset-password';
        };

        $this.listUsersUrlSuffix = function () {
            return 'pathmates';
        };

        $this.portfolioUserUrlSuffix = function () {
            return 'pathfolio';
        };

        $this.userUnsubscribeSettingsSuffix = function () {
            return 'email';
        };

        $this.favoritesUrlSuffix = function () {
            return 'favorites';
        };
        //url
        $this.getLogout = function () {
            return config.appHost + '/logout/';
        };

        console.log(config.appHost);
        $this.getSignUrl = function () {
            return config.appHost + '/login/';
        };

        $this.getSignInFacebookUrl = function($id) {
            return config.appHost + '/login/facebook/' + $id;
        };

        $this.getPasswordResetUrl = function () {
            return config.appHost + US.urlSeparator() + $this.newUrlSuffix() + US.urlSeparator() + $this.passwordResetUrlSuffix() + US.urlSeparator();
        };

        $this.getSignUpUrl = function () {
            return config.appHost + US.urlSeparator() + $this.newUrlSuffix() + US.urlSeparator();
        };

        $this.getListUserUrl = function () {
            return config.appHost + US.urlSeparator() + $this.newUrlSuffix() + US.urlSeparator() + $this.listUsersUrlSuffix() + US.urlSeparator();
        };

        $this.getUserProfileUrl = function (id) {
            return config.appHost + US.urlSeparator() + $this.newUrlSuffix() + US.urlSeparator() + id + US.urlSeparator() + $this.portfolioUserUrlSuffix() + US.urlSeparator();
        };

        $this.getUserUnsubscribeSettingsUrl = function () {
            return config.appHost + US.urlSeparator() + $this.userUnsubscribeSettingsSuffix() + US.urlSeparator();
        };

        $this.editProfileUrl = function () {
            return config.appHost + US.urlSeparator() + $this.newUrlSuffix() + US.urlSeparator() + $this.editProfileUrlSuffix() + US.urlSeparator();
        };

        $this.getEditSubscribeSettingsUrl = function () {
            return config.appHost + US.urlSeparator() + $this.userUnsubscribeSettingsSuffix() + US.urlSeparator();
        }


        $this.getFavoriteUrl = function (id) {
            return config.appHost + US.urlSeparator() + $this.newUrlSuffix() + US.urlSeparator() + id + US.urlSeparator() + $this.favoritesUrlSuffix() + US.urlSeparator();
        };


        /**
         * REQUEST USERS
         *
         * POST
         * Register users
         * @param entity
         * @param callback
         * @returns promise
         */
        $this.register = function (entity, callback) {
            var $this = this;
            return doRequest.doRequest(
                $this.REQUEST_TYPE.POST,
                config.appHost + US.urlSeparator() + $this.newUrlSuffix(),
                entity,
                callback
            );
        };
        /**
         * POST
         * login user
         * @param entity
         * @param callback
         * @returns {*}
         */
        $this.login = function (entity, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.POST,
                $this.getSignUrl(),
                entity,
                callback
            )
        };

        /**
         * GET
         * Facebook user login
         */
        $this.facebookLogin = function (id, accessToken, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getSignInFacebookUrl(id),
                {access_token: accessToken},
                callback
            )
        };

        /**
         * POST
         * Sign Up user
         * @param entity
         * @param callback
         * @returns {*}
         */
        $this.createUser = function (entity, callback) {
            var $this = this;
            return doRequest.doRequestFile(
                US.REQUEST_TYPE.POST,
                $this.getSignUpUrl(),
                entity,
                callback
            )
        };

        /**
         * GET
         * logout user
         * @param entity
         * @param callback
         * @returns {*}
         */
        $this.logout = function (callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getLogout(),
                {token: US.getToken()},
                callback
            )
        };

        /**
         * POST
         * Edit Profile
         * @param entity
         * @param callback
         * @returns {*}
         */
        $this.editProfile = function (entity, isLoaded, callback) {
            var $this = this;
            return doRequest.doRequestFile(
                US.REQUEST_TYPE.POST,
                $this.editProfileUrl() + '?token=' + US.getToken(),
                entity,
                callback
            );
        };

        /**
         *
         * @param id - id User
         * @param callback
         * @returns {*}
         */
        $this.getUserProfile = function (id, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getUserProfileUrl(id),
                {token: US.getToken()},
                callback,
                true
            );
        };

        /**
         *
         * @param id - id User
         * @param callback
         * @returns {*}
         */
        $this.getUserUnsubscribeSettings = function (id, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getUserUnsubscribeSettingsUrl(),
                {token: US.getToken()},
                callback,
                true
            );
        };

        /**
         * POST
         * Edit email subscribe settings
         * @param entity
         * @param callback
         * @returns {*}
         */
        $this.editSubscribeSettings = function (entity, isLoaded, callback) {
            var $this = this;
            return doRequest.doRequestFile(
                US.REQUEST_TYPE.POST,
                $this.getEditSubscribeSettingsUrl() + '?token=' + US.getToken(),
                entity,
                callback
            );
        };

        /**
         * GET
         * Get List Users
         * @param paths
         * @param callback
         * @returns {*}
         */
        $this.getListUsers = function(paths, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getListUserUrl(),
                {token: US.getToken(), paths: paths},
                callback
            )

        };

        /**
         * POST
         * set favorite user
         * @param id - user id
         * @param callback
         * @returns {*}
         */
        $this.setFavorite = function (id, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.POST,
                $this.getFavoriteUrl(id),
                {token: US.getToken()},
                callback
            );
        };

        /**
         * DELETE
         * Delete favorite user
         * @param id - user id
         * @param callback
         * @returns {*}
         */
        $this.deleteFavorite = function (id, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.DELETE,
                $this.getFavoriteUrl(id),
                {token: US.getToken()},
                callback
            );
        };
        /**
         * POST
         * @param email
         * @param callback
         * @returns {*}
         */
        $this.passwordReset = function (email, callback) {
            return doRequest.doRequest(
                US.REQUEST_TYPE.POST,
                $this.getPasswordResetUrl(),
                {email: email},
                callback
            )
        };

    }
})();