(function() {
	'use strict';

	// state options for the collapsed box
	const collapseOptions = {
		default: "default",
		collapsed: "collapsed", // show minimized
		expanded: "expanded" // show as lightbox
	}

	angular.module('unleeshApp').controller('StepLandingController', StepLandingController);
	StepLandingController.$inject = ['$scope', '$routeParams', '$rootScope', '$window', 'mediaCam', '$location', 'calculation', 'pathService', '$document', 'getText', 'submitService', '$sce', 'US', 'zoomService'];

	function StepLandingController($scope, $routeParams, $rootScope, $window, mediaCam, $location, calculation, pathService, $document, getText, submitService, $sce, US, zoomService) {
		$scope.$parent.titleClass= '';
		$rootScope.$emit('showBackLink');

		$scope.isDisable = true;
		$scope.stepSubmit = {
			photoFiles: [],
			photoFilesCam: null,
			videoFiles: [],
			audioFiles: [],
            documents: []
		};

        $scope.zoomInit = true;
		$scope.isPublic = true; // whether a response is public
		$scope.actionEdit = true;
		$scope.isEdit = false; // whether we're in edit mode or not
		$scope.modalAttachmentIsOpened = false;

		$scope.videoStream = null;
		$scope.isPhotoModal = false;
		$scope.isVideoModal = false;
		$scope.isAudioModal = false;
		$scope.isCamera = false;
        $scope.isDocumentModal = false;
        $scope.modalTogglerOn    = true;

		$scope.positionToCurrent = {};
		$scope.hasMedia =  mediaCam.hasGetUserMedia();
        $scope.pathPhases = false;

		$scope.openAttachment = openAttachment;
		$scope.closeAttache = closeAttache; // function to delete my entry
		$scope.clearForm = clearForm;
		$scope.stepEdit = stepEdit;
		$scope.hideShow = hideShow;
		$scope.openStep = openStep;
		$scope.changeSubmitBoxStatus = changeSubmitBoxStatus;
        $scope.getIframeSrc = getIframeSrc;

        $scope.toggleModal      = toggleModal;
        $scope.secureEditStep   = secureEditStep;

		// Whether the submit box is expanded, collapsed, or default
		$scope.submitCollapseState = collapseOptions.default

		$scope.$watch('stepSubmit.messageText', messageTextWatcher);

		$scope.$watch('isEdit', isEditWatcher);

        $scope.meetingUrl = null;
        $scope.pathId     = $rootScope.currentPath.id;

        $scope.editStepData = {
            'title': "",
            'description': "",
            'actionSubmitDescription': ""
        }

        if(!$rootScope.currentPath.phases){
            console.log('loading path');
            pathService.getPathv3($scope.pathId).then(function(response) {
                $rootScope.isLoaded = false;
                var path =  response.path;
                pathService.getPathPhases($scope.pathId).then(function(response) {
                    path.phases = response.phases;
                    path.phases.sort(function(obj1,obj2) {
                        return obj1.phaseNumber - obj2.phaseNumber;
                    });

                    $scope.path = path;
                    $rootScope.currentPath = path;
                    $scope.pathPhases = path.phases;

                    //$scope.$emit('currentPath', $scope.path);
                    //$this.stepPoints($scope);
                    console.log('path loaded');
                }).then(function(response){
                    init()
                });
            }, function(error) {
                $rootScope.isLoaded = false;
                $scope.$emit('errorMessage', error);
            });
        } else {
            console.log('init on current path phases');
            $scope.pathPhases = $rootScope.currentPath.phases;
            init();
        }

		function init() {
			$scope.submitAction = {}

            console.log('init');

			if(initStep()){
                $scope.$parent.headerTitle = $scope.step.title;
                $scope.editAction = $scope.step.actionSubmit;

                if($scope.step.completed) {
                    $scope = $rootScope.getCurrentPath($scope, $rootScope.currentPath);
                }

                $scope.to_trusted = function(html_code) {
                    return $sce.trustAsHtml(html_code);
                }
                switch($scope.step.submitOptions.type) {
                    case 'files':
                        filesInit();
                        break;
                    case 'calculations':
                        calculationsInit();
                        break;
                    case 'buttons':
                        buttonsInit();
                        break;
                    case 'textFields':
                        textFieldsInit();
                        break;
                }
            }
		}

		function initStep() {
            console.log('init step');
            console.log($scope.pathPhases);
			angular.forEach($scope.pathPhases, function(val, key) {
				if(val.id != $routeParams.lessen) {
					return true;
				}
				$scope.positionToCurrent.lessen = key;
				angular.forEach(val.levels, function(lVal, lKey) {
					if(lVal.id != $routeParams.lvl) {
						return true;
					}
					$scope.positionToCurrent.level = lKey;
					angular.forEach(lVal.actions, function(sVal, sKey){
						if(sVal.id != $routeParams.id) {
							return true;
						}
						$scope.positionToCurrent.step = sKey;
						$scope.step = sVal;
						$scope.step.sectionNumber = key + 1;
					})
				});
			});

            /*console.log($scope.step);
            console.log($rootScope.user);
            console.log($scope.step.zoomMeetingId);*/

            //console.log('DropboxFired');

            //var dropbox_container = document.getElementById("dropbox_container");
            //console.log($scope.step.dropBoxData);
            //console.log(dropbox_container);

           /* try {
                if($scope.step.dropBoxData){
                    console.log(dropbox_container);

                    var options = {
                        // Shared link to Dropbox file
                        link: $scope.step.dropBoxData,
                        file: {
                            // Sets the zoom mode for embedded files. Defaults to 'best'.
                            zoom: "best" // or "fit"
                        },
                        folder: {
                            // Sets the view mode for embedded folders. Defaults to 'list'.
                            view: "list", // or "grid"
                            headerSize: "normal" // or "small"
                        }
                    }
                    Dropbox.embed(options, dropbox_container);
                }
            } catch(error){

            }*/


           /* if($scope.step && $scope.step.zoomMeetingId && $scope.step.zoomSignature){
                var meetingConfig = zoomService.getMeetingConfig();
                meetingConfig.mn  = $scope.step.zoomMeetingId;
                meetingConfig.pwd = $scope.step.zoomMeetingPwd;
                meetingConfig.signature = $scope.step.zoomSignature;
                meetingConfig.apiKey = "mlpMxS4WS0KfRomZ25W_1Q";
                meetingConfig.name = zoomService.b64EncodeUnicode(
                    $rootScope.user.firstName + ' ' + $rootScope.user.lastName
                );
                meetingConfig.email = zoomService.b64EncodeUnicode(
                    $rootScope.user.email
                );

                $scope.meetingUrl = '/web-app/meeting.html?' + zoomService.serialize(meetingConfig);
            }*/

			if (!$scope.step) {
				$location.path('/');
			}

            // setup info needed for "Next Step" button
            $scope.nextStepUrl = null

            //console.log($scope.step);return false;
            if ($rootScope.nextStepLookup && $rootScope.nextStepLookup[$scope.step.id]) {
                const nextStepInfo = $rootScope.nextStepLookup[$scope.step.id]
                $scope.nextStepUrl = `#/path/step/${nextStepInfo.phaseID}&${nextStepInfo.levelID}&${nextStepInfo.actionID}`
            }


            return true;
		}

		function filesInit() {
			if($scope.step.actionSubmit) {
				switchType($scope.step.actionSubmit.attachmentType);
				$scope.actionEdit = false;
			}

			/**
			 * Open attache form
			 */
			$scope.openAttache = function(type) {
				if($scope.editAction) {
					return;
				}
				switch (type) {
					case 'video':
						$scope.isVideo = true;
						break;
					case 'audio':
						$scope.isAudio = true;
						break;
                    case 'document':
                        $scope.isDocument = true;
					case 'photo':
						$scope.isPhoto= true;
						break;
					case 'text':
						$scope.isText = true;
						break;
				}
			};

			/**
			 * open modal
			 */

			$scope.openModal = function(type) {
				switch (type) {
					case 'video':
						$scope.isVideoModal = true;
						break;
					case 'audio':
						$scope.isAudioModal = true;
						break;
					case 'photo':
						$scope.isPhotoModal = true;
						break;
					case 'text':
						$scope.isPhotoModal = true;
						break;
                    case 'document':
                        $scope.isDocumentModal = true;
                        break;
				}
			};

			//change file to browser
			$scope.changeFile = function(file, event) {
				$scope.isPhotoModal = false;
				$scope.isVideoModal = false;
				$scope.isAudioModal = false;
                $scope.isDocumentModal = false;

				$scope.isDisable = false;
				$scope.stepSubmit.photoFilesCam = null;
			};

			//detect browser
			$scope.getMobile = function() {
				var userAgent = $window.navigator.userAgent;
				var browsers = {Mobile: /Mobile/i};
				for(var key in browsers) {
					if (browsers[key].test(userAgent)) {
						return true;
					}
				}
				return false;
			};
			$scope.isMobile = $scope.getMobile();

			/**
			 * Show/Hide camera
			 */
			$scope.recordRTC = null;
			//record video
			$scope.videoTag = null;
			$scope.startRecord = function() {
				mediaCam.getUserMedia({video: true, audio: true},
					function(stream) {
						$scope.videoTag.src = $window.URL.createObjectURL(stream);
						$scope.videoTag.play();
						$scope.videoTag.muted = false;
						$scope.recordRTC = RecordRTC(stream, {type: 'video'});

						$scope.recordRTC.startRecording();
						$scope.$apply();
					}, function(error) {
					}
				);
			};
			$scope.stopRecord = function() {
				$scope.videoStream = null;
				$scope.recordRTC.stopRecording(function (audioVideoWebMURL) {
					$($scope.videoTag).attr('muted', 'true');
					$scope.videoTag.src = audioVideoWebMURL;
					$scope.stepSubmit.videoFiles[0] = $scope.recordRTC.getBlob();
					$scope.videoTag.controls = true;
					$scope.videoTag.muted = true;
				});
			};
			$scope.isCameraVideo = false;
			//record audio
			$scope.startRecordAudio = function() {
				$scope.recordRTC.startRecording();
			};
			$scope.stopRecordAudio = function() {
				var audio = document.querySelector('#audio');
				if(!!$scope.videoStream.stop){
					$scope.videoStream.stop();
				}
				$scope.videoStream = null;
				$scope.recordRTC.stopRecording(function (audioVideoWebMURL) {
					audio.src = audioVideoWebMURL;
					$scope.stepSubmit.audioFiles[0] = $scope.recordRTC.getBlob();

				});
			};
			/**
			 * Show modale cam
			 * @param type
			 */
			$scope.cameraShow = function(type) {
				switch (type){
					case 'photo':
						$scope.isPhotoModal = false;
						mediaCam.getUserMedia({video: true},
							function(stream) {
								$scope.isCamera = true;
								$scope.videoStream = $window.URL.createObjectURL(stream);
								$scope.$apply();
							}, function(error) {
							}
						);
						break;
					case 'video':
						$scope.isVideoModal = false;
						$scope.videoTag = $document[0].createElement('video');
						$('#video-record').append($scope.videoTag);
						$($scope.videoTag).attr('muted', 'false');
						$scope.isCameraVideo = true;
						break;
					case 'audio':
						$scope.isPhotoModal = false;
						mediaCam.getUserMedia({audio: true},
							function(stream) {
								$scope.isCameraVideo = true;
								$scope.videoStream = $window.URL.createObjectURL(stream);
								$scope.recordRTC = RecordRTC(stream);
								$scope.$apply();
							}, function(error) {
							}
						);
						break;
				}
			};

			/**
			 * get ScreenShot
			 */
			$scope.getScreenShot = function() {
				if($scope.videoStream != null) {
					var video = document.querySelector('#video-screen');
					var canvas = document.querySelector('#canvas-screen');
					var ctx = canvas.getContext('2d');
					ctx.drawImage(video, 0, 0);
					$scope.srcScreen = canvas.toDataURL('image/png');
				} else {
				}
			};

			$scope.cameraOk = function() {
				$scope.isDisable = false;
				$scope.isCamera = false;
				$scope.stepSubmit.photoFilesCam = $scope.srcScreen;
				$scope.stepSubmit.photoFiles = [];
				$scope.videoStream = null;
				$scope.srcScreen = '';
				$('#screen').attr('src', '');
			};

			$scope.cameraHide = function() {
				$scope.isCamera = false;
				$scope.videoStream = null;
				$scope.srcScreen = null;
				$('#screen').attr('src', '');
			};
			/**
			 * method to video
			 */
			$scope.cameraVideoOk = function() {
				if($scope.isVideo){
					if($scope.stepSubmit.videoFiles.length != 0) {
						$scope.isDisable = false;
						$scope.isCameraVideo = false;
						$scope.isVideoModal = false;
						$scope.videoStream = null;
						$('#video-record video').remove();
					}
				}
				$scope.isAudioModal = false;
			};

			$scope.cameraVideoHide = function() {
				if($scope.isVideo){
					$scope.stepSubmit.videoFiles = [];
					$('#video-record video').remove();
					$scope.isCameraVideo = false;
				} else if($scope.isAudio) {

				}
			};

		}

        function getIframeSrc(){
            return  $scope.step.videoRoomData.roomUrl + '?embed&floatSelf&recording=on&screenshare=on&chat=on&leaveButton=on';
        }

        function calculationsInit() {
			if ($scope.step.actionSubmit) {
				getText.get($scope.step.attachment, function(text) {
					$scope.step.actionSubmit.comment = text;
				});
			}
			$scope.$watch('submitAction.$valid', function(val) {
				$scope.isDisable = !val;
			});
			$scope.isCalculation = true;
		}

		function buttonsInit() {
			if ($scope.step.actionSubmit) {
				getText.get($scope.step.actionSubmit.attachment, function(text) {
					$scope.step.actionSubmit.comment = text;
				});
			}
		}

        function toggleModal(e) {
            $scope.modalTogglerOn = !$scope.modalTogglerOn;
            console.log('path_edit');
            return false;
        }

        function secureEditStep(step_id){
            $scope.editStepData = {
                'title': $scope.step.title,
                'description': $scope.step.description,
                'actionSubmitDescription': $scope.step.actionSubmitDescription,
            }

            pathService.secureEditStep(step_id, $scope.editStepData, $rootScope.user.token)
                .then(function (response){
                    location.reload();
                }, function (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.responseJSON.status,
                    })
                }
            );
        }

		function textFieldsInit() {

			if ($scope.step.actionSubmit) {

			}
			$scope.$watch('submitAction.$valid', function(val) {
				$scope.isDisable = !val;
			});
			$scope.isFields = true;
		}

		function openAttachment(type, src) {
			$scope.modalAttachmentType = type;
			$scope.modalAttachmentSrc = src;
			$scope.modalAttachmentIsOpened = true;
		}

		function closeAttache(type) {
			if ($scope.editAction != null) {
				$rootScope.isLoaded = true;
				submitService.deleteSubmit($scope.editAction.id).then(function(response) {
					$rootScope.isLoaded = false;
					if(response.statusCode == 200) {
						handlerResponse();
					}
				}, function(error) {
					$rootScope.isLoaded = false;
					$scope.$emit('errorMessage', error);
				});
			}
			if (type) {
				switch (type) {
					case 'video':
						$scope.isVideo = false;
						break;
					case 'audio':
						$scope.isAudio = false;
						break;
					case 'photo':
						$scope.isPhoto = false;
						break;
					case 'text':
						$scope.isText = false;
						break;
                    case 'document':
                        $scope.isDocument = false;
                        break;
				}
				$scope.clearForm(type);
			}
		}

		function clearForm(type) {
			$scope.isDisable = true;

			switch (type) {
				case 'video':
					$scope.stepSubmit.editVideo = null;
					$scope.stepSubmit.videoFiles = [];
					break;
				case 'audio':
					$scope.stepSubmit.editAudio = null;
					$scope.stepSubmit.audioFiles = [];
					break;
				case 'photo':
					$scope.stepSubmit.photoFiles = [];
					$scope.stepSubmit.photoFilesCam = null;
					$scope.stepSubmit.editPhoto = null;
					break;
				case 'text':
					$scope.stepSubmit.textFiles = [];
					break;
                case 'document':
                    $scope.stepSubmit.documents = [];
                    break;
			}
		}

		function switchType(type) {
			switch (type) {
				case 'video':
					$scope.isVideo = true;
					$scope.stepSubmit.editVideo = $scope.step.actionSubmit.attachment ;
					$scope.stepSubmit.messageVideo = $scope.step.actionSubmit.comment;
					break;
				case 'audio':
					$scope.isAudio = true;
					$scope.stepSubmit.editAudio = $scope.step.actionSubmit.attachment ;
					$scope.stepSubmit.messageAudio = $scope.step.actionSubmit.comment;
					break;
				case 'image':
					$scope.isPhoto= true;
					$scope.stepSubmit.editPhoto = $scope.step.actionSubmit.attachment ;
					$scope.stepSubmit.messagePhoto = $scope.step.actionSubmit.comment;
					break;
				case 'text':
					getText.get($scope.step.actionSubmit.attachment, function(text) {
						//$scope.step.actionSubmit.comment = text;
					});
					$scope.stepSubmit.messageText = $scope.step.actionSubmit.comment;
					$scope.isText = true;
					break;
                case 'document':
                    $scope.isDocument = true;
                    $scope.stepSubmit.editDocument = $scope.step.actionSubmit.attachment;
                    $scope.stepSubmit.messageDocument = $scope.step.actionSubmit.comment;
                    break;
			}
		}

		function handlerResponse(response) {
			if (response && response.actionSubmit) {
				$scope.step.actionSubmit = $scope.editAction = response.actionSubmit;
				if (!$scope.isEdit) {
					$scope.step.actionSubmitCount += 1;
					$rootScope.currentPath.phases[$scope.positionToCurrent.lessen].levels[$scope.positionToCurrent.level].levelPoints += $rootScope.currentPath.phases[$scope.positionToCurrent.lessen].levels[$scope.positionToCurrent.level].actions[$scope.positionToCurrent.step].points;
				}
				switchType($scope.step.actionSubmit.attachmentType);
				$scope.stepSubmit.videoFiles = [];
			}
			else {
				$scope.editAction = null;
				$scope.step.actionSubmitCount -= 1;

				if ($scope.stepSubmit.messageText) {
					$scope.stepSubmit.messageText = '';
				}
			}
			$rootScope.currentPath.phases[$scope.positionToCurrent.lessen].levels[$scope.positionToCurrent.level].actions[$scope.positionToCurrent.step].actionSubmit = $scope.editAction;
			$rootScope.currentPath.phases[$scope.positionToCurrent.lessen].levels[$scope.positionToCurrent.level].actions[$scope.positionToCurrent.step].completed = response ? true: false;
			$rootScope.currentPath.phases[$scope.positionToCurrent.lessen].levels[$scope.positionToCurrent.level].actions[$scope.positionToCurrent.step].completedOnce = true;
			$scope.$emit('currentPath', $rootScope.currentPath);
			$scope = $rootScope.getCurrentPath($scope, $rootScope.currentPath);
			$scope.isEdit = false;
		}

		function stepEdit(e) {
			e.preventDefault();
			$scope.isEdit = !$scope.isEdit;
		}

		// this function switches whether a post should be public or not
		function hideShow(e) {
			e.preventDefault();
			$scope.isPublic = !$scope.isPublic;
		}

		function openStep(lessenId, levelId, stepId) {
			$location.path('path/step/'+ lessenId + '&'+ levelId + '&'+ stepId );
			$document.scrollTop(0);
		}

		function messageTextWatcher(text) {
			if (!text) {
				return;
			}
			$scope.isDisable = !((text.length > 0) && $scope.isText);
		}

		function isEditWatcher(val) {
			if (val) {
				$scope.editAction = $scope.step.actionSubmit;
				$scope.step.actionSubmit = null;
				$scope.isDisable = false;
			}
			else {
                console.log($scope.step, $scope.editAction);
                if($scope.step && $scope.editAction){
				    $scope.step.actionSubmit = $scope.editAction;
                }
			}
		}

		// Ben added functions
		function changeSubmitBoxStatus(state) {
			if (!['minimize', 'lightbox'].includes(state)) {
				throw new Error("invalid option for changeSubmitBoxStatus")
			}

			if (state === 'minimize') {
				if ($scope.submitCollapseState === collapseOptions.collapsed) {
					$scope.submitCollapseState = collapseOptions.default
				} else {
					$scope.submitCollapseState = collapseOptions.collapsed
				}
			} else if (state === 'lightbox') {
				if ($scope.submitCollapseState === collapseOptions.expanded) {
					$scope.submitCollapseState = collapseOptions.default
				} else {
					$scope.submitCollapseState = collapseOptions.expanded
				}
			}
		}

		/*
		Notes on usage
			- Determine if you have a response by whether $step.editAction is null (null = no response)
			- 

		*/


		// whether the user has responded to this activity or not
		$scope.iHaveCompleted = !!$scope.editAction
		$scope.$watch("editAction", () => {
			$scope.iHaveCompleted = !!$scope.editAction
		})

		// get recent activity (mostly copied from SubmitsController)
		$scope.stepActivity = []

		// for pagination? Not 100% sure
		$scope.page = 1
		$scope.onLoadPageMore = () => {
			$scope.page += 1;
			getStepSubmits($scope.page)
		}

		function getStepSubmits(page) {
			submitService.getActionSubmits($routeParams.id, page, 25).then(function(response) {
				$rootScope.isLoaded = false;
				$rootScope.handleResponse($scope, response, onGetStepSubmitsResponse);
			}, function(error) {
				$rootScope.isLoaded = false;
				$scope.$emit('errorMessage', error);
			});
		}

		function onGetStepSubmitsResponse(response) {
			$scope.stepActivity = $scope.stepActivity.concat(response.feeds);
		}

		getStepSubmits($scope.page)

		// callback for pressing a "button" type activity response
		$scope.stepSubmit.button = null
		$scope.onPressActivityButton = (label) => {
			if ($scope.stepSubmit.button !== label) {
				$scope.stepSubmit.button = label
			} else {
				$scope.stepSubmit.button = null
			}
			
		}

		// rewritten submitAttache() function
		$scope.submitStepResponse = () => {
			$scope.formData = new FormData();
			let somethingAttached = false

			// attach photo
			if ($scope.stepSubmit.photoFiles && $scope.stepSubmit.photoFiles.length !== 0) {
				$scope.formData.append('file', $scope.stepSubmit.photoFiles[$scope.stepSubmit.photoFiles.length - 1], $scope.stepSubmit.photoFiles[$scope.stepSubmit.photoFiles.length - 1].name);
				$scope.formData.append('attachmentType', 'image');
				somethingAttached = true
			}

			// attach video
			if ($scope.stepSubmit.videoFiles && $scope.stepSubmit.videoFiles.length !== 0) {
				$scope.formData.append('file', $scope.stepSubmit.videoFiles[$scope.stepSubmit.videoFiles.length - 1], $scope.stepSubmit.videoFiles[$scope.stepSubmit.videoFiles.length - 1].name);
				$scope.formData.append('attachmentType', 'video');
				somethingAttached = true
			}

			// attach audio
			if ($scope.stepSubmit.audioFiles && $scope.stepSubmit.audioFiles.length !== 0) {
				$scope.formData.append('file', $scope.stepSubmit.audioFiles[$scope.stepSubmit.audioFiles.length - 1], $scope.stepSubmit.audioFiles[$scope.stepSubmit.audioFiles.length - 1].name);
				$scope.formData.append('attachmentType', 'audio');
				somethingAttached = true
			}

			// attach text
			if ($scope.stepSubmit.messageText) {
				$scope.formData.append('comment', $scope.stepSubmit.messageText);
				$scope.formData.append('attachmentType', 'text');
				somethingAttached = true
			}

            // attach document
            if ($scope.stepSubmit.documents && $scope.stepSubmit.documents.length !== 0) {
                $scope.formData.append('file', $scope.stepSubmit.documents[$scope.stepSubmit.documents.length - 1], $scope.stepSubmit.documents[$scope.stepSubmit.documents.length - 1].name);
                $scope.formData.append('attachmentType', 'document');
                somethingAttached = true
            }

			// attach text fields
			if ($scope.step.submitOptions.list && $scope.step.submitOptions.type === 'textFields') {

				// verify  at least one field has a response
				let someHaveResponse = false
				$scope.step.submitOptions.list.forEach(listItem => {
					if (listItem.field && listItem.field.length > 0) {
						someHaveResponse = true
					} else {
						listItem.field = ""
					}
				})

				if (someHaveResponse) {
					const textFields = calculation.fieldGluing($scope.step.submitOptions.list);
					$scope.formData.append('comment', textFields);
					$scope.formData.append('attachmentType', 'text');
					somethingAttached = true
				}
			}

			// attach button response
			if ($scope.stepSubmit.button) {
				$scope.formData.append('comment', $scope.stepSubmit.button);
				$scope.formData.append('attachmentType', 'text');
				somethingAttached = true
			}

			if (!somethingAttached) {
				console.warn(`Rejecting submit because nothing was attached`)
				return
			}

			$rootScope.isLoaded = true;

			// submit! 
			if (!$scope.isEdit) {
				submitService
					.actionsSubmitFiles($scope.formData, $scope.step.id, $scope.isPublic)
					.then(
						function (response) {
						$rootScope.isLoaded = false;
						if (response.statusCode != 200) {
							$scope.$emit("errorMessage", { statusText: response.message });
						}
						handlerResponse(response);
						},
						function (error) {
							$rootScope.isLoaded = false;
							$scope.$emit("errorMessage", error);
						}
					);

			}
			// submitting in edit mode
			else {
				submitService
				.setSubmit($scope.formData, $scope.editAction.id, $scope.isPublic)
				.then(
					function (response) {
						$rootScope.isLoaded = false;
						handlerResponse(response);
					},
					function (error) {
						$scope.$emit("errorMessage", { statusText: error });
					}
				);


			}
		}

		// set attachment type on my response (if available)
		$scope.myPostAttachmentType = null
		$scope.$watch("editAction", () => {
			if ($scope.editAction && $scope.editAction.attachment) {

				const imageEndings = [".png", ".jpg", ".jpeg", ".gif", ".png", ".eps", ".raw", ".tiff"]
				const videoEndings = [".mp4"];
				const audioEndings = [".amr", ".aac", ".m4a"];
                const documentEndings = [".doc", ".docx", ".txt", ".pdf", ".ppt", ".pptx", ".xls", ".xlsx"]
				
				imageEndings.forEach(ending => {
					if ($scope.editAction.attachment.includes(ending)) {
						$scope.myPostAttachmentType = "image"
					}
				})
				
				videoEndings.forEach(ending => {
					if ($scope.editAction.attachment.includes(ending)) {
						$scope.myPostAttachmentType = "video"
					}
				})
				
				audioEndings.forEach(ending => {
					if ($scope.editAction.attachment.includes(ending)) {
						$scope.myPostAttachmentType = "audio"
					}
				})

                /*documentEndings.forEach(ending => {
                    if ($scope.editAction.attachment.includes(ending)) {
                        $scope.myPostAttachmentType = "document"
                    }
                })*/

                if ($scope.editAction.attachmentType === 'document'){
                    $scope.myPostAttachmentType = 'document';
                    //$scope.originalName   = post.activity.originalName;
                }
				
			}
		})

		// determines whether the user has attached something to their submission (before submitting)
		$scope.somethingIsAttached = false
		$scope.$watch("stepSubmit.photoFiles", checkIfSomethingIsAttached)
		$scope.$watch("stepSubmit.videoFiles", checkIfSomethingIsAttached)
		$scope.$watch("stepSubmit.audioFiles", checkIfSomethingIsAttached)
        $scope.$watch("stepSubmit.documents",  checkIfSomethingIsAttached)
		function checkIfSomethingIsAttached() {
			if ($scope.stepSubmit) {
				if ($scope.stepSubmit.photoFiles.length > 0) {
					$scope.somethingIsAttached = true
				} else if ($scope.stepSubmit.videoFiles.length > 0) {
					$scope.somethingIsAttached = true
				} else if ($scope.stepSubmit.audioFiles.length > 0) {
					$scope.somethingIsAttached = true
				} else if ($scope.stepSubmit.documents.length > 0) {
                    $scope.somethingIsAttached = true
                } else {
					$scope.somethingIsAttached = false
				}
			}
		}

		// allows removing an attachment
		$scope.removeAllAttachments = () => {
			$scope.stepSubmit.photoFiles = []
			$scope.stepSubmit.videoFiles = []
			$scope.stepSubmit.audioFiles = []
            $scope.stepSubmit.documents  = []
		}

		// setup info needed for "Next Step" button
		$scope.nextStepUrl = null
		if ($rootScope.nextStepLookup && $scope.step && $rootScope.nextStepLookup[$scope.step.id]) {
			const nextStepInfo = $rootScope.nextStepLookup[$scope.step.id]
			$scope.nextStepUrl = `#/path/step/${nextStepInfo.phaseID}&${nextStepInfo.levelID}&${nextStepInfo.actionID}`
		}

        $scope.initRedactor = function (){
            //$R('#step_description_red', {
            //    imageUpload: '/7591d6529e7a257789b3499334417536bacaaaef/upload/',
            //    plugins: ['table', 'alignment', 'clips', 'counter', 'definedlinks', 'filemanager', 'fontcolor', 'fontfamily', 'fontsize', 'fullscreen', 'handle', 'imagemanager', 'inlinestyle',
            //        'limiter', 'properties', 'specialchars', 'textdirection', 'textexpander', 'variable', 'video', 'widget'],
            //    fileUpload: '/7591d6529e7a257789b3499334417536bacaaaef/upload/'
            //    //fileManagerJson: '/your-folder/files.json'
            //});
        };

        $scope.initRedactor();
	}

})();
