(function () {
  "use strict";

  // Main controller.
  require("../controller/main/main.controller.js");

  // Path controllers.
  require("../controller/path/current-path.controller.js");
  require("../controller/path/path-store.controller.js");
  require("../controller/path/path-landing.controller.js");
  require("../controller/path/step-landing.controller.js");

  // Events controllers.
  require("../controller/events/events.controller.js");
  require("../controller/events/event-landing.controller.js");
  // require('../controller/events/edit-event.controller.js');
  require("../controller/events/add-event.controller.js");

  // Profile controllers.
  require("../controller/profile/profile.controller.js");
  require("../controller/profile/edit-profile.controller.js");
  require("../controller/profile/login.controller.js");
  require("../controller/profile/logout.controller.js");
  require("../controller/profile/sign-up.controller.js");
  require("../controller/profile/unsubscribe.controller.js");
  require("../controller/profile/push.controller.js");

  // Attendees controller.
  require("../controller/attendees/attendees.controller.js");

  // Pathmail controllers.
  require("../controller/pathmail/pathmail.controller.js");
  require("../controller/pathmail/thread.controller.js");
  require("../controller/pathmail/new-thread.controller.js");

  // Pathmates controllers.
  require("../controller/pathmates/pathmates.controller.js");

  // Total points controller.
  require("../controller/points/total-points.controller.js");

  // Settings controller.
  require("../controller/settings/settings.controller.js");

  // Submits controller.
  require("../controller/submits/submits.controller.js");

  // Announcements controller
  require("../controller/announcements/announcements.controller.js");

  // Global search controllers
  require("../controller/search/global-search.controller.js");
  require("../controller/search/global-search-results.controller.js");
})();
