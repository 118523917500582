(function() {
	'use strict';

	angular.module('unleeshApp').controller('PathStoreController', PathStoreController);
	PathStoreController.$inject = ['$scope', '$rootScope', '$location', 'pathService', '$q'];

	function PathStoreController($scope, $rootScope, $location, pathService, $q) {
		var pathChanges = {};

		$scope.$parent.headerTitle = 'Path Store';

		$scope.currentTitle = '';
		$scope.modalOpen = false;
		$scope.togglerOn = true;
        $scope.accessTogglerOn = true;
		$scope.noPathsSelected = false;
		$scope.panelIsShown = false;
		$scope.paths = [];

		$scope.setPath = setPath;
		$scope.showPanel = showPanel;
		$scope.doneHiding = doneHiding;
		$scope.togglePaths = togglePaths;
        $scope.toggleAccessCode = toggleAccessCode;
		$scope.showAllPaths = showAllPaths;
		$scope.handleCancel = function() {
			$scope.modalOpen = false;
		};
		$rootScope.$on('$routeChangeStart', function() {
			$scope.modalOpen = false;
		});

        /**
         * Get available paths, after that we get paths statuses and apply them to scope paths.
         */
        $rootScope.isLoaded = true;
        pathService.getPathsStarted_NEW().then(function(data) {
            var paths = data.paths;
            loadCallback(paths);
            $rootScope.isLoaded = false;
        }, function(error) {
            $rootScope.isLoaded = false;
        });

        function loadCallback(paths) {
            var outPaths = [],
                togglerOn = true,
                noPathsSelected = true,
                pathWasFound;

            angular.forEach(paths, function(e){
               if(e.shown){
                   noPathsSelected = false;
               }
            });

            $scope.noPathsSelected = noPathsSelected;
            $scope.togglerOn = togglerOn;
            $scope.paths = paths;

        }

		function showPanel(e) {
			e.preventDefault();
			$scope.panelIsShown = true;
		}

		function doneHiding(e) {
			e.preventDefault();
			$scope.panelIsShown = false;
			if (Object.keys(pathChanges).length !== 0) {
				applyChanges();
			}
			$scope.noPathsSelected = isNoPathsSelected();
			pathChanges = {};
		}

		function togglePaths(e) {
			e.preventDefault();
			$scope.togglerOn = !$scope.togglerOn;
			angular.forEach($scope.paths, function(path, index) {
				if (path.shown == $scope.togglerOn) {
					return true;
				}
				changePath(path.id, path, $scope.togglerOn);
			});
		}

        function toggleAccessCode(e) {
            e.preventDefault();
            $scope.accessTogglerOn = !$scope.accessTogglerOn;
            console.log('access_code');
        }

		function showAllPaths() {
			angular.forEach($scope.paths, function(path, index) {
				if (path.selected == true) {
					return true;
				}
				changePath(path.id, path, true);
			});
			$scope.togglerOn = true;
		}

		function isNoPathsSelected() {
			var noPathsSelected = true;
			angular.forEach($scope.paths, function(path) {
				if (path.selected) {
					noPathsSelected = false;
					return false;
				}
			});
			return noPathsSelected;
		}

		function applyChanges() {
			var pathsToSave = {paths: []};
			angular.forEach(pathChanges, function(status, pathId) {
				pathsToSave.paths.push({pathId: parseInt(pathId), shown : (status)? 1 : 0});
			});
			pathService.savePathStatuses(pathsToSave);
		}

		function setPath(e, id, title, index) {
			e.preventDefault();
			if ($scope.panelIsShown) {
				changePath(id, $scope.paths[index]);
				checkTogglerState();
				return;
			}
			$scope.currentTitle = title;
			$scope.modalOpen = true;

			$scope.handleOk = function() {
				$scope.modalOpen = false;
				pathService.setCurrentPath(id, $rootScope.user.token).then(function(response) {
					$rootScope.handleResponse($scope, response, function() {
						if (!$rootScope.currentPath) {
							$rootScope.currentPath = {};
						}
						$rootScope.currentPath.id = id;
						$location.path('/path/view/' + id);
					});
				});
			};
		}

		/**
		 * Change path status, if the status variable is not provided then status will be toggled.
		 */
		function changePath(id,path, status) {
			if (!status) {
				status = !path.shown;
			}
			if (pathChanges[id] && pathChanges[id] != status) {
				delete pathChanges[id];
			}
			else {
				pathChanges[id] = status;
			}
			path.shown = status;
		}

		function checkTogglerState() {
			var togglerOn = true,
				noPathsSelected = true;
			angular.forEach($scope.paths, function(path) {
				if (!path.shown) {
					togglerOn = false;
				}
				noPathsSelected = false;
			});
			$scope.noPathsSelected = noPathsSelected;
			$scope.togglerOn = togglerOn;
		}
	}

})();
