(function() {
    'use strict';

    angular.module('unleeshApp').service('postService', postService);

    postService.$inject = ['doRequest', 'config', 'US']

    function postService(doRequest, config, US) {
        var $this = this;

        $this.postUrlSuffix = function () {
            return 'posts';
        };

        $this.discussionsUrlSuffix = function () {
            return 'discussions';
        };
        $this.replyUrlSuffix = function () {
            return 'replies';
        };
        //url

        $this.setPostUrl = function () {
            return config.appHost + US.urlSeparator() + $this.discussionsUrlSuffix() + US.urlSeparator() + $this.postUrlSuffix() + US.urlSeparator();
        };

        $this.getPostViewUrl = function (id) {
            return $this.setPostUrl() + id + US.urlSeparator();
        };


        $this.getDeletePostUrl = function (id) {
            return config.appHost + US.urlSeparator() + $this.discussionsUrlSuffix() + US.urlSeparator() + $this.postUrlSuffix() + US.urlSeparator() + id + US.urlSeparator();
        };
        $this.getDeleteReplyUrl = function (id) {
            return config.appHost + US.urlSeparator() + $this.discussionsUrlSuffix() + US.urlSeparator() + $this.replyUrlSuffix() + US.urlSeparator() + id + US.urlSeparator();
        };

        /**
         * REQUEST POSTs
         *
         * POST
         * @param id
         * @param callback
         * @returns {*}
         */
        $this.getPostView = function (id, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getPostViewUrl(id),
                {token: US.getToken()},
                callback
            )
        };

        /**
         * GET
         * @param callback
         * @returns {*}
         */
        $this.getPosts = function (page, limit, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.setPostUrl(),
                {
                    page: page,
                    limit: limit,
                    token: US.getToken()
                },
                callback
            )
        };

        /**
         * POST
         * @param message
         * @param callback
         * @returns {*}
         */
        $this.addPost = function (message, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.POST,
                $this.setPostUrl(),
                {
                    message: message,
                    token: US.getToken()
                },
                callback
            )
        };
        /**
         * POST
         * Add post to path
         * @param message
         * @param callback
         * @returns {*}
         */
        $this.addPostFile = function (entity, callback) {
            var $this = this;
            return doRequest.doRequestFile(
                US.REQUEST_TYPE.POST,
                $this.setPostUrl() + '?token=' + US.getToken(),
                entity,
                callback
            )
        };

        /**
         * DELETE
         * @param id
         * @param callback
         * @returns {*}
         */
        $this.deletePost = function (id, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.DELETE,
                $this.getDeletePostUrl(id),
                {token: US.getToken()},
                callback
            )
        };

        /**
         * DELETE
         * @param id
         * @param callback
         * @returns {*}
         */
        $this.deleteReply = function (id, callback) {
            var $this = this;
            return doRequest.doRequest(
                US.REQUEST_TYPE.DELETE,
                $this.getDeleteReplyUrl(id),
                {token: US.getToken()},
                callback
            )
        };

        /**
         * POST
         * Set post not file
         * @param entity
         * @param callback
         * @returns {*}
         */
        $this.setPost = function (entity, callback) {
            var $this = this;
            return doRequest.doRequest(
                $this.REQUEST_TYPE.POST,
                $this.setPostUrl() + "?token=" + US.getToken,
                entity,
                callback
            )
        };

        /**
         * POST multi
         * Set post to file
         * @param entity
         * @param callback
         * @returns {*}
         */
        $this.setPost = function (entity, callback) {
            var $this = this;
            return doRequest.doRequestFile(
                US.REQUEST_TYPE.POST,
                $this.setPostUrl() + "?token=" + US.getToken,
                entity,
                callback
            )
        };



    }
})();