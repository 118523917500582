'use strict';

angular.module('unleeshApp')
	.directive('progress', function() {
		return {
			restrict: 'A',
			scope: {
				total: '@',
				all: '@'
			},
			replace:false,
			link: function(scope, elem, attrs) {
				scope.$watch('all', function(val){
					var total = parseInt(attrs.total);
					var all = parseInt(attrs.all);
					if((total!= NaN)&&(all!= NaN)) {
						var progress = Math.round(total/all*100);
						angular.element(elem).attr('style','width:' + (progress) + '%');
					}
				})
			}
		};
	});