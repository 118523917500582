var angular = require('angular');
(function() {
    'use strict';

    require('../../node_modules/video.js/dist/video-js/video.novtt.js');
    require('./unleesh-app.js');

    require('./directives.js');
    require('./factory.js');
    require('./services.js');
    require('./filters.js');

    require('./controllers.js');
    require('../config/route-config.js');

    require('../config/storage-config.js');
    require('../config/sce-delegate-config.js');
    require('../config/clip-config.js');

    Array.prototype.diff = function(a) {
        return this.filter(function(i) {return a.indexOf(i) < 0;});
    };
    if (typeof String.prototype.startsWith != 'function') {
        String.prototype.startsWith = function(str) {
            return this.indexOf(str) === 0;
        };
    }

    // TODO make this as service or by some another way. 100% it should be removed.
    window.PostsScopeTrait = require('../trait/posts-trait.js');

    angular.element(document).ready(function() {
        angular.bootstrap(document, ['unleeshApp']);
    });

})();
