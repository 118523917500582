(function() {
	'use strict';

	angular.module('unleeshApp').directive('addMore', addMore);

	function addMore($timeout, $window, $parse) {

		return {
			restrict: 'A',
			scope: {
				loadPage: '=',
				pageIsLoading: '@load',
				stopLoad: '@'
			},
			replace: false,
			compile: compile
		};

		function compile() {

			return function(scope, element, attr) {
				angular.element($window).bind('scroll', appliedCheck);
				angular.element($window).bind('resize' , appliedCheck);
				scope.$watch('bottom', bottomWatcher);
				scope.$watch('stopLoad', stopLoadWatcher);

				function stopLoadWatcher(status) {
					if (status == 'true') {
						element.append('<li class="add-is-more"><span></span> Loading...</li>');
					}
					else if(status == 'false') {
						$('.add-is-more').remove();
					}
				}

				function bottomWatcher(val) {
					if (scope.$eval(scope.pageIsLoading) && val) {
						scope.loadPage();
					}
				}

				function appliedCheck() {
					scope.$apply(check);
				}

				function check() {
					scope.bottom = ($(window).scrollTop() + $(window).height() > $(document).height() - 100);
				}
			};
		}
	}

})();
