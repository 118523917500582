// TODO bad service, split it to files not by type 'urls' but by functionality.
(function () {
	'use strict';

	angular.module('unleeshApp').service('US', USService);
	USService.$inject = ['$rootScope', 'config', 'doRequest'];

	function USService($rootScope, config, doRequest) {
		var $this = this;
		$this.REQUEST_TYPE = {
			GET: 'GET',
			DELETE: 'DELETE',
			POST: 'POST',
			PUT: 'PUT'
		};
		$this.token = '';

		$this.timeout = 30000;

		$this.setToken = function (token) {
			$this.token = token;
		};

		$this.getToken = function () {
			return $this.token;
		};

		$this.clearToken = function () {
			$this.token = '';
		};

		$this.deleteUrlSuffix = function () {
			return 'delete';
		};

		$this.listUrlSuffix = function () {
			return 'list';
		};

		/**
		 * Use all method to prefix Url
		 * @returns {string}
		 */
		$this.urlSeparator = function () {
			return '/';
		};
		/*
		 * Users
		 * */
		//settings
		$this.newUrlSuffix = function () {
			return 'users';
		};

		/*
		 * Settings Request
		 * */
		$this.get$rootScope = function () {
			return $rootScope;
		};

		/*
		 * Comments
		 * */
		//settings
		$this.commentUrlSuffix = function () {
			return 'comments';
		};

		$this.submitUrlSuffix = function () {
			return 'submits';
		};



		//like
		$this.appreciationUrlSuffix = function () {
			return 'appreciation';
		};
		$this.replyUrlSuffix = function () {
			return 'replies';
		};
		$this.discussionsUrlSuffix = function () {
			return 'discussions';
		};

		//url
		$this.getPostLikeUrl = function (id) {
			return config.appHost + $this.urlSeparator() + $this.discussionsUrlSuffix() + $this.urlSeparator() + $this.replyUrlSuffix() + $this.urlSeparator() + id + $this.urlSeparator() + $this.appreciationUrlSuffix() + $this.urlSeparator();
		};

		$this.getSubmitLikeUrl = function (submitId, id) {
			return config.appHost + $this.urlSeparator() + $this.submitUrlSuffix() + $this.urlSeparator() + submitId + $this.urlSeparator() + $this.commentUrlSuffix() + $this.urlSeparator() + id + $this.urlSeparator() + $this.appreciationUrlSuffix() + $this.urlSeparator();
		};



		/**
		 * REQUEST LIKE
		 *
		 * POST
		 * add like to post or submit
		 * @param id - commentId or discussions id
		 * @param type - 'post' or "submit'
		 * @param submitId - id submits
		 * @param callback
		 * @returns {*}
		 */
		$this.addLike = function (id, type, submitId, callback) {
			var $this = this;
			var URL = '';
			if (type == 'post') {
				URL = $this.getPostLikeUrl(id);
			} else if (type == 'submit') {
				URL = $this.getSubmitLikeUrl(submitId, id);
			}

			return doRequest.doRequest(
				$this.REQUEST_TYPE.POST,
				URL,
				{token: $this.getToken()},
				callback
			)
		};

		/**
		 * DELETE
		 * Delete like post or submit
		 * @param id
		 * @param type
		 * @param submitId
		 * @param callback
		 * @returns {*}
		 */
		$this.deleteLike = function (id, type, submitId, callback) {
			var $this = this;
			var URL = '';
			if (type == 'post') {
				URL = $this.getPostLikeUrl(id);
			} else if (type == 'submit') {
				URL = $this.getSubmitLikeUrl(submitId, id);
			}
			return doRequest.doRequest(
				$this.REQUEST_TYPE.DELETE,
				URL,
				{token: $this.getToken()},
				callback
			)
		};
	}

})();
