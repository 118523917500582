(function () {
  "use strict";

  angular
    .module("unleeshApp")
    .directive("messageAttachForm", messageAttachForm);

  function messageAttachForm() {
    return {
      scope: {
        message: "=",
        submitCallback: "=",
        messageSending: "=",
        formatVertically: "=", // if true, puts textarea and submit area in a column
      },
      templateUrl: "sources/template/message-attach.html",
      controller: controller,
    };

    function controller($scope) {
      var messageIsSending = false;
      $scope.messageIsSending = messageIsSending;

      $scope.attachModalIsOpened = false;
      $scope.toggleAttachBox = toggleAttachBox;
      $scope.changeAttach = changeAttach;
      $scope.submit = submit;
      $scope.showSubmitLoading = false;

      $scope.$watch("messageSending", messageSendingWatcher);
      $scope.$watch("message.attacheType", attacheTypeWatcher);

      function submit() {
        if (messageIsSending) {
          return;
        }
        messageIsSending = true;
        $scope.messageIsSending = messageIsSending;
        $scope.showSubmitLoading = true;
        $scope.submitCallback();
      }

      function attacheTypeWatcher(attacheType) {
        if (attacheType) {
          $scope.attachModalIsOpened = true;
        }
      }

      function messageSendingWatcher(messageSending) {
        if (messageSending === false) {
          messageIsSending = false;
          $scope.messageIsSending = messageIsSending;
          $scope.attachModalIsOpened = false;
        }
      }

      function toggleAttachBox(e) {
        e.preventDefault();
        $scope.attachModalIsOpened = !$scope.attachModalIsOpened;
      }

      function changeAttach(files, type) {
        if (!files || !files.length) {
          return;
        }
        $scope.message.attacheType = type;
      }

      $scope.clearAttachments = () => {
        $scope.message.files = null;
        $scope.message.attacheType = "";
      };
    }
  }
})();
