(function() {
    'use strict';

    require('../services/unleesh.service.js');
    require('../services/user.service.js');
    require('../services/pathmail.service.js');
    require('../services/event.service.js');
    require('../services/path.service.js');
    require('../services/post.service.js');
    require('../services/submit.service.js');
    require('../services/recent-activity.service.js');
    require('../services/format-date.service.js');
    require('../services/media.service.js');
    require('../services/calculation-formulas.service.js');
    require('../services/zoom.service.js');
    require('../services/search.service.js');

})();
