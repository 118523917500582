'use strict';

var openDialogCount = 0;

angular.module('unleeshApp').directive('modalView', function ($timeout, $rootScope) {
    return {
        scope: {
            okButton: '@',
            okCallback: '=',
            cancelButton: '@',
            cancelCallback: '=',
            open: '@',
            dialogClass: '=',
            title: '@',
            width: '@',
            height: '@',
            show: '@',
            hide: '@',
            autoOpen: '@',
            resizable: '@',
            closeOnEscape: '@',
            hideCloseButton: '@',
            modal: '@',
            position: '@',
            isModal: '@'
        },
        replace: false,
        transclude: true,
        template: '<div ng-transclude></div>',
        link: link
    };

    function link(scope, $element, attrs) {
        var position = attrs.position || {my: 'top', at: 'top+70', of: window};
        var hideCloseButton = attrs.hideCloseButton || true;
        var dialogOptions = {
            dialogClass: attrs.dialogClass || ' ',
            autoOpen: attrs.autoOpen || false,
            title: attrs.title,
            width: attrs.width || 350,
            height: attrs.height || 'auto',
            modal: attrs.modal || true,
            draggable: attrs.draggable || false,
            resizable: attrs.resizable,
            closeOnEscape: attrs.closeOnEscape || true,
            position: position,
            show: {effect: 'fadeIn', duration: 200},
            hide: {effect: 'fadeOut', duration: 200},

            close: function () {
                scope.open = false;
                $(this).parent().promise().done(function() {
                    openDialogCount--;
                });
            },

            open: function (event, ui) {
                scope.open = true;
                if (hideCloseButton) {
                    $('.ui-dialog-titlebar-close', ui.dialog).hide();
                }
                $(this).parent().promise().done(function() {
                    openDialogCount++;
                });
            }
        };

        dialogOptions['buttons'] = [];
        if (attrs.okButton) {
            var btnOptions = {
                text: attrs.okButton,
                tabIndex: -1,
                click: function () {
                    scope.$apply(scope.okCallback());
                }
            };
            dialogOptions['buttons'].push(btnOptions);
        }

        if (attrs.cancelButton) {
            var btnOptions = {
                text: attrs.cancelButton,
                tabIndex: -1,
                click: function () {
                    if (!scope.cancelCallback) {
                        return;
                    }
                    scope.$apply(scope.cancelCallback());
                }
            };
            dialogOptions['buttons'].push(btnOptions);
        }

        $element.dialog(dialogOptions);

        attrs.$observe('open', function (val) {
            if (val !== 'true') {
                $element.dialog('close');
                return;
            }

            if (openDialogCount > 0) {
                setTimeout(function() {
                    if (openDialogCount > 0) {
                        return;
                    }
                    $element.dialog('option', 'position', position);
                    $element.dialog('open');
                }, 300);
            }
            else {
                $element.dialog('open');
            }
        });

        attrs.$observe('title', function (val) {
            $element.dialog('option', 'title', val);
        });

        $rootScope.$on('$routeChangeStart', function () {
            // Remove modals to not duplicate them on route changing.
            if ($element.hasClass('ui-dialog-content')) {
                $element.dialog('destroy');
            }
            $element.remove();
        });
    }
});
