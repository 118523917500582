(function() {
    'use strict';

    angular.module('unleeshApp').controller('EventsController', EventsController);
    EventsController.$inject = ['$scope', '$rootScope', '$location', 'formatDate', 'eventService'];

    function EventsController($scope, $rootScope, $location, formatDate, eventService) {
        $scope.$parent.headerTitle = 'My Breakouts';

        $scope.events = [];
        $scope.expiredEvents = [];
        $scope.activeEvents  = [];
        $scope.userHasCurrentPath = ($rootScope.currentPath !== undefined && $rootScope.currentPath.id !== undefined);
        if (!$scope.userHasCurrentPath) {
            return;
        }
        $rootScope.$emit('showPlus', '#/event/add');
        $rootScope.isLoaded = true;

        eventService.getListEvents($rootScope.user.token)
            .then(function(response) {
                $rootScope.isLoaded = false;
                if(response.statusCode == 200) {
                    $scope.events = response.events.map(function(event) {
                        return {
                            address: event.address,
                            description: event.description,
                            endDate: event.endDate,
                            host: event.host,
                            icon: event.icon,
                            id: event.id,
                            path: event.path,
                            startDate: formatDate.getDateTodayOrTomorrow(event.startDate),
                            status: event.status,
                            title: event.title,
                            expired: event.expired
                        }
                    });
                }else if(response.statusCode == 401) {
                    $scope.$emit('userLogout', response);
                }

                resetSearch()
            }, function(response) {
                $rootScope.isLoaded = false;
                //console.log('Error: ',response);
            });

        $scope.viewEvent = function(id) {
            $location.path('/event/view/'+ id);
        }

        function resetSearch() {
            $scope.searchInput = ""

            $scope.activeEvents   = $scope.events.filter(event => {
                if(event.expired == false){
                    return true
                }

                return false
            });

            $scope.expiredEvents = $scope.events.filter(event => {
                if(event.expired == true){
                    return event
                }

                return false
            });

            $scope.filteredActiveEvents  = $scope.activeEvents.slice()
            $scope.filteredExpiredEvents = $scope.expiredEvents.slice()
        }

        // searching has two functions -- filtering the immediately shown, and then searching for older threads
        resetSearch()
        $scope.$watch("searchInput", () => {
            // If search is empty, show all threads
            if (!$scope.searchInput || $scope.searchInput.length === 0) {
                resetSearch()
                return
            }

            const searchTerm = $scope.searchInput.toLowerCase()

            $scope.filteredExpiredEvents = $scope.expiredEvents.filter(event => {
                // match on breakout name
                if (event.title.toLowerCase().includes(searchTerm)) {
                    return true
                }

                // match on the name of the path
                if (event.path.title.toLowerCase().includes(searchTerm)) {
                    return true
                }

                // match on the host's name
                if (`${event.host.firstName} ${event.host.lastName}`.toLowerCase().includes(searchTerm)) {
                    return true
                }

                // no match, don't include
                return false
            })

            $scope.filteredActivedEvents = $scope.activeEvents.filter(event => {
                // match on breakout name
                if (event.title.toLowerCase().includes(searchTerm)) {
                    return true
                }

                // match on the name of the path
                if (event.path.title.toLowerCase().includes(searchTerm)) {
                    return true
                }

                // match on the host's name
                if (`${event.host.firstName} ${event.host.lastName}`.toLowerCase().includes(searchTerm)) {
                    return true
                }

                // no match, don't include
                return false
            })
        })
    }
})();