module.exports = function PostsScopeTrait($scope, US, postService, submitService, RAService, $rootScope, video, audio, $routeParams, $location) {

	var $this = this;

	$scope.isOpenSource = false;
	$scope.replySending = false;
	$scope.isCommentSource = false;
	$scope.isOpenComment = false;
	$scope.stopLoad = true;

	$this.getTextToFile = function(file, callback) {
		if(!file) return;
        var rawFile = new XMLHttpRequest();
		rawFile.open("GET", file, false);
		rawFile.onreadystatechange = function ()
		{
			if(rawFile.readyState === 4)
			{
				if(rawFile.status === 200 || rawFile.status == 0)
				{
					var allText = rawFile.responseText;
					callback(allText);
				}
			}
		};
		rawFile.send(null);
	};

    /**
     * open window dialog for display audio, video, image
     * @param type
     * @param src
     */
    $scope.openSource = function(type, src) {
		if($scope.isOpenComment) {
			$scope.isCommentSource = true;
		}
        switch (type) {
			case 'video':
				$scope.load = {video: true};
				$('.profile-sours').append(video.elem);
				video.play(src);
				break;
			case 'audio':
				$scope.load = {audio: true};
				$('.profile-sours').append(audio.elem);
				audio.play(src);
				break;
            case 'image':
                $scope.load = {image: true, src: src};
                break;
        }
		$scope.isOpenSource = true;

	};

    $scope.handleOkSource = function() {
		if($scope.isCommentSource) {
			$scope.isCommentSource = false;
		}
		if($scope.load.video) {
			video.stop();
			video.elem.remove();
		}
		if($scope.load.audio) {
			audio.stop();
			$('.profile-sours audio').remove();
		}
		$scope.isOpenSource = false;
    };
    $scope.handleCancelSource = function() {
		if($scope.isCommentSource) {
			$scope.isCommentSource = false;
		}
		$scope.isOpenSource = false;
	};
    $scope.handleOkComment = function() {
		$scope.isCommentSource = false;
		$scope.isOpenComment = false;
		if($scope.message.message) {
			$scope.message.message = '';
		}
    };
    $scope.handleCancelComment = function() {
		$scope.isCommentSource = false;
		$scope.isOpenComment = false;
		if($scope.message.message) {
			$scope.message.message = '';
		}
    };

	$this.recentActivityResponse = function(response) {
		// changed by Ben
		// $scope.recentActivity = $scope.recentActivity.concat(response.feeds);
		$scope.recentActivity = response.feeds ? response.feeds : []

		$scope.isActivityLoadPage = true;
		angular.forEach($scope.recentActivity, function(val, key) {
			var activityObj = null;
			switch (val.activityType) {
				case 'ActionSubmitComment':
					activityObj = val.activity.actionSubmit;
					break;
				case 'ActionSubmit':
					activityObj = val.activity;
					break;
			}

			// Ben commented this out. I'm pretty sure it doesn't do anything, and it was causing errors on the test server. 
			// if (activityObj && activityObj.attachmentType == 'text') {
			// 	$this.getTextToFile(activityObj.attachment, function (text) {
			// 		if(text != null) {
			// 			//activityObj.comment = text
			// 		}
			// 	});
			// }
		});
	};
	$scope.getRecentActivity = function (page, limit) {
		$scope.stopLoad = true;
		RAService.getFeedListRecentActivity(page, limit).then(function(response) {
			$scope.stopLoad = false;
			$rootScope.handleResponse($scope, response, $this.recentActivityResponse);
        }, function(error) {
			$scope.stopLoad = false;
			$scope.$emit('errorMessage', error);
        });
    };

    $scope.getPostIndexById = function(id) {
        var out = -1;
        angular.forEach($scope.posts, function(post, postIndex) {
            if (post.id == id) {
                out = postIndex;
                return false;
            }
        });
        return out;
    };

    $scope.getReplyIndexById = function(postIndex, replyId) {
        var out = -1;
        angular.forEach($scope.posts[postIndex].replies, function(reply, replyIndex) {
            if (reply.id == replyId) {
                out = replyIndex;
                return false;
            }
        });
        return out;
    };

    $scope.deletePost = function(id) {
		postService.deletePost(id).then(function(response) {
			$rootScope.handleResponse($scope, response, function() {
				deletePost(id);
			});
        }, function(error) {
			$scope.$emit('errorMessage', error);
        });
    };

    $scope.deleteReply = function(postId, replyId, replyIndex) {
		postService.deleteReply(replyId).then(function(response) {
            $rootScope.handleResponse($scope, response, function() {
				deleteReply(postId, replyId, replyIndex);
			});
        }, function(error) {
			$scope.$emit('errorMessage', error);
        });
    };

	function deletePost(id) {
		var index = $scope.getPostIndexById(id);
		$scope.posts.splice(index, 1);
		$scope.isOpenComment = false;
	}

	function deleteReply(postId, replyId, replyIndex) {
		$scope.comments.replies.splice(replyIndex, 1);
		var postIndex = $scope.getPostIndexById(postId);
		var rIndex = $scope.getReplyIndexById(postIndex, replyId);
		$scope.posts[postIndex].replies.splice(rIndex, 1);
		delete [rIndex];
	}

    $scope.loadMorePage = function() {
		$scope.isActivityLoadPage = false;
        if ($scope.isOpenComment || $scope.isOpenSource) {
            return;
        }
        $scope.pageScroll += 1;
        $scope.getRecentActivity($scope.pageScroll, $scope.limitLoad);
    };

	/**
	 * Show details
	 * @param e - event
	 * @param index - index to array
	 * @param type - type comment Reply/Post/ActionSubmit/ActionSubmitComment
	 * @param id
	 */
	$scope.showComment = function (e, index, type, id) {
        e.preventDefault();
		$scope.message = {
			message: '',
			files: [],
			attacheType: ''
		};
        switch (type){
            case 'Reply':
            case 'Post':
				$rootScope.isLoaded = true;
				postService.getPostView(id).then(function(response) {
                    $rootScope.handleResponse($scope, response, getPostView);
					$rootScope.isLoaded = false;
                }, function(error) {
					$rootScope.isLoaded = false;
                    $scope.$emit('errorMessage', error);
                });
                $scope.typeComments = 'post';
                break;
            case 'ActionSubmit':
            case 'ActionSubmitComment':
                $scope.comments = $scope.recentActivity[index].activity;
                $scope.typeComments = 'submit';
				$scope.isOpenComment = true;
                break;
        }
		function getPostView(response) {
			$scope.comments = response.post;
			$scope.isOpenComment = true;
		}
		/**
		 * submit form post/replay/submitAction/ActionSubmitComment files or not files
		 */
		$scope.submitMessage = function () {
			var formData = new FormData();
			if($scope.message.message) {
				formData.append('message', $scope.message.message);
			}
			if($scope.message.files && $scope.message.files.length) {
				formData.append('file', $scope.message.files[$scope.message.files.length - 1], $scope.message.files[$scope.message.files.length - 1].name);
				formData.append('attachmentType', $scope.message.attacheType);
			}
            $scope.replySending = true;
			submitService.setListCommentsFile(formData, id, type).then(function(response) {
                $rootScope.handleResponse($scope, response, setListCommentsFile);
                $scope.replySending = false;
            }, function(error) {
                $scope.$emit('errorMessage', error);
            });

        };
		function setListCommentsFile(response) {
			if((type == 'Post')||(type == 'Reply')){
				$scope.comments.replies.push(response.reply);
				$scope.posts[index].replies.push(response.reply);
			}
			if((type == 'ActionSubmitComment')||(type == 'ActionSubmit')){
				if($scope.comments.actionSubmit) {
					$scope.comments.actionSubmit.posts.push(response.post)
				}else {
					$scope.comments.posts.push(response.post)
				}
			}
			$scope.message = {
				message: '',
				files: [],
				attacheType: ''
			};
			$scope.isAttach = false;
		}
		// add like


		$scope.addLike = function(e, userId, id, isLike, index, submitId) {
			if(userId == $rootScope.user.id) {
				$scope.$emit('errorMessage', {statusText: 'You cannot appreciate your own reply'});
				return;
			}
			if($scope.loadedLike == true) {
				return;
			}
			var elemLoding = $(e.currentTarget).children('.loadLike');
			elemLoding.show();
			$scope.loadedLike = true;
			if(!isLike) {
				US.addLike(id, $scope.typeComments, submitId)
					.then(function(response) {
						$scope.loadedLike = false;
						$rootScope.handleResponse($scope, response, addLike);
					}, function(error) {
						$scope.loadedLike = false;
						elemLoding.hide();
						error.isLogin = true;
						$scope.$emit('errorMessage', error);
					});
			} else {
				US.deleteLike(id, $scope.typeComments, submitId)
					.then(function(response) {
						$scope.loadedLike = false;
						$rootScope.handleResponse($scope, response, deleteLike);
					}, function(error) {
						$scope.loadedLike = false;
						elemLoding.hide();
						error.isLogin = true;
						$scope.$emit('errorMessage', error);
					})
			}
			function addLike(response) {
				elemLoding.hide();
				if(response.statusCode == 200) {
					if($scope.typeComments == 'post'){
						$scope.comments.replies[index] = response.reply;
					}
				}else if(response.statusCode == 201) {
					if($scope.typeComments == 'submit'){
						$scope.comments.posts[index].appreciations = 1;
						$scope.comments.posts[index].isAlreadyAppreciated = true;
					}
					if($scope.typeComments == 'post'){
						$scope.comments.replies[index] = response.reply;
					}
				}
			}
			function deleteLike (response) {
				elemLoding.hide();
				if(response.statusCode == 200) {
					if($scope.typeComments == 'post'){
						$scope.comments.replies[index] = response.reply;

					}
					if($scope.typeComments == 'submit'){
						$scope.comments.posts[index].appreciations = 0;
						$scope.comments.posts[index].isAlreadyAppreciated = false;
					}
				}else if(response.statusCode == 201) {
					if($scope.typeComments == 'submit'){
						$scope.comments.posts[index].appreciations = 0;
						$scope.comments.posts[index].isAlreadyAppreciated = false;
					}
					if($scope.typeComments == 'post'){
						$scope.comments.replies[index] = response.reply;
					}
				}
			}
		};

		/**
		 * attache files to forms
		 */
		$scope.isAttach = false;
		$scope.showAttach = function(e) {
			e.preventDefault();
			$scope.isAttach = true;
		};
		$scope.clearPreView = function() {
			$scope.message.files = [];
			$scope.message.attacheType = '';
		};

		$scope.openAttache = function (type) {
			$scope.message.files = [];
			$scope.message.attacheType = type;
		};
    };
};
