(function() {
    'use strict';

    angular.module('unleeshApp').controller('GlobalSearchController', GlobalSearchController);
    GlobalSearchController.$inject = ['$scope', '$rootScope', '$location', 'formatDate'];

    function GlobalSearchController($scope, $rootScope, $location, formatDate) {

        console.log('SHOOT');
        $scope.$parent.headerTitle = 'Global Search';

        $scope.searchResults = [];
        $scope.userHasCurrentPath = ($rootScope.currentPath !== undefined && $rootScope.currentPath.id !== undefined);
        if (!$scope.userHasCurrentPath) {
            return;
        }

        $scope.submitSearchRequest = function(){
            console.log($scope.searchInput);
            console.log('CHCHCHC');
            if($scope.searchInput){
                $location.path('search/'+ $scope.searchInput );
            }
        }


    }
})();