angular.module('unleeshApp').controller('LogoutController', ['$scope', '$rootScope', 'userService',
	function($scope,$rootScope, userService) {
		var logout = userService.logout();
		$rootScope.isLoaded = true;
		logout.then(function(response) {
			$rootScope.isLoaded = false;
			$scope.$emit('userLogout', response);
		}, function(error) {
			$rootScope.isLoaded = false;
			$scope.$parent.goBack();
		})
	}
]);