/*  Shows a popup directive with content inside.
    DO NOT use for confirmations. Use Swal.fire for that. This is meant to render selectable content inside (eg., pick a path from a list)

    Usage:
      Modal is expected to be shown with an ng-if. Put your show condition in this ng-if
      Modal will fade in on it's rendering.
      Modal will fade out by internalling starting to close, then calling onClose() after a delay

To find old modals, search for "modal-view"
*/

(function () {
  "use strict";

  angular.module("unleeshApp").directive("modal", () => {
    return {
      templateUrl: "sources/template/modal.html",
      transclude: true,
      controller: controller,
      scope: {
        title: "@",
        onCancel: "=", // called when clicking outside the modal
        onDone: "=", // called when clicking done
      },
    };
  });

  controller.$inject = ["$scope"];

  function controller($scope) {
    // show determines the modals opacity, and allows for fading in/out. show: true = opacity: 1
    $scope.show = false;
    setTimeout(() => {
      $scope.show = true;
      refreshPage();
    }, 75);

    $scope.cancelModal = () => {
      // start fading out
      $scope.show = false;

      // 250ms, call onCancel function
      setTimeout(() => {
        $scope.onCancel();
        refreshPage();
      }, 250);
    };

    $scope.pressDone = () => {
      // start fading out
      $scope.show = false;

      // 250ms, call onCancel function
      setTimeout(() => {
        $scope.onDone();
        refreshPage();
      }, 250);
    };

    function refreshPage() {
      // checking $$phase doesn't seem to help here, so we just do a try/catch
      try {
        $scope.$apply();
      } catch (err) {
        console.log(err);
      }
    }
  }
})();
