(function() {
    'use strict';

    angular.module('unleeshApp').config(config);
    config.$inject = ['$sceDelegateProvider'];

    function config($sceDelegateProvider) {
        $sceDelegateProvider.resourceUrlWhitelist([
            // Allow same origin resource loads.
            'self',
            // Allow loading from our assets domain.  Notice the difference between * and **.
            'http**',
            'blob:http**'
        ]);

        // The blacklist overrides the whitelist so the open redirect here is blocked.
//		$sceDelegateProvider.resourceUrlBlacklist([
//			'http://87.251.89.35:8082/**'
//		]);
    }
})();
