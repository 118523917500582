'use strict'
angular.module('unleeshApp').controller('EditProfileController', ['$scope',  '$rootScope', 'userService',
	function($scope, $rootScope, userService) {
		$scope.$parent.headerTitle = 'Edit Profile';
		$rootScope.$emit('showBackLink');

		$scope.profile = $rootScope.user;
		$scope.clearDate = function() {
			$scope.profile.birthdate = '';
		};

		$scope.submitProfile = function() {
			$scope.formData = new FormData;
			if($scope.profile.firstName){
				$scope.formData.append('firstName',$scope.profile.firstName);
			} else {

			}
			if($scope.profile.lastName) {
				$scope.formData.append('lastName', $scope.profile.lastName);
			}
			if($scope.profile.zip) {
				$scope.formData.append('zip', $scope.profile.zip);
			} else {
				$scope.formData.append('zip', '');
			}
			if($scope.profile.birthdate) {
				$scope.formData.append('birthdate', $scope.profile.birthdate);
			} else {
				$scope.formData.append('birthdate', '');
			}
			if($scope.profile.profession) {
				$scope.formData.append('profession', $scope.profile.profession);
			} else {
				$scope.formData.append('profession', '');
			}
			if($scope.profile.company) {
				$scope.formData.append('company', $scope.profile.company);
			} else {
				$scope.formData.append('company', '');
			}
			if($scope.profile.education) {
				$scope.formData.append('education',$scope.profile.education);
			} else {
				$scope.formData.append('education', '');
			}
			if($scope.profile.email) {
				$scope.formData.append('email',$scope.profile.email);
			}
            if($scope.profile.twitter_uid) {
                $scope.formData.append('twitterUid',$scope.profile.twitter_uid);
            } else {
                $scope.formData.append('twitterUid', '');
            }
            if($scope.profile.gplus_uid) {
                $scope.formData.append('gplusUid',$scope.profile.gplus_uid);
            } else {
                $scope.formData.append('gplusUid', '');
            }

			if($scope.profile.password) {
				$scope.formData.append('password',$scope.profile.password);
			}

			if($scope.profile.files && $scope.profile.files.length!= null) {
				$scope.formData.append('file',$scope.profile.files[$scope.profile.files.length - 1] ,$scope.profile.files[$scope.profile.files.length - 1].name);
			}

			if($scope.form.$valid) {
				$rootScope.isLoaded = true;
				userService.editProfile($scope.formData ,true)
					.then(function(response) {
						$rootScope.handleResponse($scope, response, responseEdit);
						$rootScope.isLoaded = false;
					}, function(response) {
						$rootScope.isLoaded = false;
					});
			}

		};

		function responseEdit(response) {
			$rootScope.user = response.user;
			$scope.$emit('userEdit', response.user);
		}

	}
]);