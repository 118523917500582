'use strict'

angular.module('unleeshApp').controller('EventLandingController', ['$scope', 'US', '$rootScope', '$routeParams', 'formatDate', '$location', '$timeout', 'video', 'audio', 'eventService', 'postService', 'submitService', 'RAService',
	function($scope, US, $rootScope, $routeParams, formatDate, $location, $timeout, video, audio, eventService,  postService, submitService, RAService) {
		var currentStatus = -1;

		$rootScope.$emit('showBackLink');
		$rootScope.$emit('showPlus', '#/event/add');

		$scope.statusOpen = false;
		$scope.changeFocusLocation = false;
		$scope.isSelectUser = false;
		$scope.deleteEvent = false;
		$scope.isLoadIcon = false;
		$scope.chatMessage = {
			message: '',
			attacheType: '',
			files: []
		};
		$scope.posts = [];
		$scope.limit = 25;
		$scope.pageScroll = 1;
		$scope.stopLoadEvent = false;
		$rootScope.isLoaded = true;
		eventService.getEvent($routeParams.id, $rootScope.user.token).then(function(response) {
			$rootScope.isLoaded = false;
			$rootScope.handleResponse($scope, response, getEventCallback);
		}, function (error) {
			if (error.status === 404) {
				Swal.fire({
					icon: "error",
					title: "Event not found",
					text: `This event is already over or was deleted.`,
				})
				$location.path('/events');
			} else {
				$scope.$emit('errorMessage', error);
			}
			$rootScope.isLoaded = false;
		});
		// $scope.$on('mapInitialized', onMapInitialize);
		/**
		 * Close window if change pouting
		 */
		$rootScope.$on('$routeChangeStart', function() {
			$scope.changeFocusLocation = false;
			$scope.isSelectUser = false;
		});

		// Hide status bar and show panel statuses
		$scope.changeStatus = function(e, codeStatus) {
			e.preventDefault();
			currentStatus = codeStatus;
			$scope.event.status = -1;
			$scope.statusOpen = true;
		};

		//load next 10 comments
		$scope.loadMoreEventPage = function() {
			if ($scope.isOpenComment || $scope.isOpenSource) {
				return;
			}
			$scope.pageScroll += 1;
			$scope.getComments($scope.pageScroll, $scope.limit);
		};

		$scope.openAttache = function(type) {
			$scope.attacheType = type;
		};

		//Event status edit
  		$scope.editStatus = function(codeStatus) {

			eventService.editEventStatus({token: $rootScope.user.token, id: $scope.event.id, status: codeStatus})
				.then(function(response) {
					$rootScope.handleResponse($scope, response, isEditEventStatus);
					if(response.statusCode == 403) {
						$scope.event.status = currentStatus;
					}
				}, function(error) {
					$scope.$emit('errorMessage', error);
					$scope.event.status = currentStatus;
				});
			$scope.statusOpen = false;
		};


		// Get comment to this event
		$scope.getComments = function(page, limit) {
			$scope.stopLoadEvent = true;
			eventService.getListCommentsEvent($routeParams.id, page, limit)
				.then(function(response) {
					$scope.stopLoadEvent = false;
					$rootScope.handleResponse($scope, response, isGetListComment);
				}, function(error) {
					$scope.stopLoadEvent = false;
					$scope.$emit('errorMessage', error);
				});
		};
		//add comment
		$scope.addCommentEvent = function() {
			$scope.formData = new FormData();
			if($scope.chatMessage.message) {
				$scope.formData.append('message',$scope.chatMessage.message);
			}
			if($scope.chatMessage.files.length) {
				$scope.formData.append('file', $scope.chatMessage.files[$scope.chatMessage.files.length - 1], $scope.chatMessage.files[$scope.chatMessage.files.length - 1].name);
				$scope.formData.append('attachmentType', $scope.chatMessage.attacheType);
			}
			$scope.isLoadIcon = true;
			eventService.addCommentToEvent($scope.formData, $scope.event.id)
				.then(function(response) {
					$rootScope.handleResponse($scope, response, isAddComment);
				}, function(error) {
					$scope.$emit('errorMessage', error);
				});
		};

		// open Modal window
		$scope.openLocation = function() {
			$scope.changeFocusLocation = true;
			if ($scope.latlng) {
				$scope.map.setCenter($scope.latlng);
			}
		};

		$scope.openInvitees = function(e, status) {
			var filterStatus = status || null;
			e.preventDefault();
			$rootScope.isLoaded = true;
			function getUsersEventInvitees(response) {
				if(filterStatus !== null) {
					$scope.inviteesUsers = response.users.filter(function(users) {
						return users.status == filterStatus;
					});
				} else {
					$scope.inviteesUsers = response.users;
				}
			}

			eventService.getUsersEventInvitees({id: $scope.event.id, token: $rootScope.user.token})
				.then(function(response) {
					$scope.isSelectUser = true;
					$rootScope.isLoaded = false;
					$rootScope.handleResponse($scope, response, getUsersEventInvitees);
				}, function(error) {
					$rootScope.isLoaded = false;
					$scope.$emit('errorMessage', error);
				})
		};

		$scope.openDeleteMessage = function(e) {
			e.preventDefault();
			$scope.deleteEvent = true;
		};

		$scope.showAttach = function (e) {
			e.preventDefault();
			$scope.isAttach = true;
		};

		// TODO make it by another way (prototypes or something else).
		new window.PostsScopeTrait($scope, US, postService, submitService, RAService, $rootScope, $routeParams, $location, video, audio);

		/**
		 * callback function
		 */
		function getEventCallback(response) {
			$scope.event = response.event;
			$scope.$parent.headerTitle = $scope.event.title;
			$scope.event.start_date = formatDate.getDateTodayOrTomorrow($scope.event.startDate);
			$scope.$watch('event.status', function(val) {
				if((val == 4) && ($scope.event.host.id == $rootScope.user.id)) {
					$scope.event.status = formatDate.isDifferenceHour($scope.event.startDate) ? 6 : val;
				} else if(val == 1) {
					$scope.statusOpen = true;
				}
			});
			// initLocationOnMap();
			$scope.getComments($scope.pageScroll, $scope.limit);
		}

		function isEditEventStatus(response) {
			$scope.event = response.event;
			$scope.event.start_date = formatDate.getDateTodayOrTomorrow($scope.event.startDate);
		}

		function deleteEvent() {
			$location.path('/events');
		}

		function isGetListComment(response) {
			$scope.posts = $scope.posts.concat(response.posts.map(post => {
				if (!post.activityType) {
					post.activityType = "EventComment"
				}
				return post
			}));
			$scope.isLoadPageEvent = true;
		}

		function isAddComment(response) {
			// Set activity type so that <activity></activity> recognizes it
			response.post.activityType = "EventComment"

			$scope.posts.unshift(response.post)
			$scope.chatMessage = {
				message: '',
				attacheType: '',
				files: []
			};
			$scope.isLoadIcon = false;
			$scope.formData = null;
			$scope.isAttach = false;
			
		}

		//handler modal delete
		$scope.handleYes = function() {
			$rootScope.isLoaded = true;
			eventService.deleteEvent($scope.event.id)
				.then(function(response) {
					$scope.deleteEvent = false;
					$rootScope.handleResponse($scope, response, deleteEvent);
					$rootScope.isLoaded = false;
				}, function(error) {
					$rootScope.isLoaded = false;
					$scope.$emit('errorMessage', error);
				})
		};
		$scope.handleNo = function() {
			$scope.deleteEvent = false;
		};

		// close show attendees modal
		$scope.handleOk = function(){
			$scope.isSelectPath = false; // don't think is used anymore
			$scope.changeFocusLocation = false;
			$scope.isSelectUser = false;

		};

		function onMapInitialize(evt, map) {
			$scope.map = map;
			initLocationOnMap();
		}

		/**
		 * This function will be executed only when both map and event are initiated.
		 */
		function initLocationOnMap() {
			if (!$scope.map || !$scope.event.latitude || !$scope.event.longitude) {
				return;
			}
			$scope.latlng = new google.maps.LatLng($scope.event.latitude, $scope.event.longitude);
			if ($scope.changeFocusLocation) {
				putMarkerOnMap();
				return;
			}
			$scope.$watch('changeFocusLocation', function() {
				if (!$scope.changeFocusLocation) {
					return;
				}
				$scope.$watch('changeFocusLocation', function() {});
				putMarkerOnMap();
			});
		}

		function putMarkerOnMap() {
			$timeout(function() {
				google.maps.event.trigger($scope.map, 'resize');
				$scope.map.setCenter($scope.latlng);
				var marker = new google.maps.Marker({
					position: $scope.latlng,
					map: $scope.map
				});
				var geocoder = new google.maps.Geocoder();
				geocoder.geocode({latLng: $scope.latlng}, function(responses) {
					if (responses && responses.length > 0) {
						var infoWindow = new google.maps.InfoWindow();
						infoWindow.setContent(responses[0].formatted_address);
						infoWindow.open($scope.map, marker);
					}
				});
			}, 100);
		}

		// some bespoke regex for url detection. 
		// guarenteed to not work in all cases. but probably good enough
		const url_regex = /https?:\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/i;

		// determines whether the event location is a url or not
		$scope.locationIsURL = false
		$scope.$watch("event.address", () => {
			if ($scope.event && $scope.event.address) {
				if (url_regex.test($scope.event.address)) {
					$scope.locationIsURL = true
				} else {
					$scope.locationIsURL = false
				}
			}
		})


		$scope.showChangeStatusMenu = function() {
			$scope.statusOpen = true;
		};
	}
]);