'use strict';
angular.module('unleeshApp').controller('TotalPointsController',['$scope', '$rootScope', 'pathService',
	function($scope, $rootScope, pathService) {
		$scope.$parent.headerTitle = 'Total Points';
		$scope.paths = [];
		$rootScope.isLoaded = true;
		pathService.getPathsPointsList().then(function(response) {
			$rootScope.isLoaded = false;
			$rootScope.handleResponse($scope, response, getPathsPointsList);
		}, function(error) {
			$rootScope.isLoaded = false;
			$scope.$emit('errorMessage', error);
			$scope.currentPathInited = true;
		});

		function getPathsPointsList(response) {
			$scope.paths = response.paths;
			$scope.points = sumPoints(response.paths);
		}
		function sumPoints(array) {
			var sum = 0;
			angular.forEach(array, function(val) {
				sum += parseFloat(val.points);
			});
			return sum;
		}
	}
]);
