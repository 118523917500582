(function() {
	'use strict';

	angular.module('unleeshApp').controller('PathLandingController', PathLandingController);
	PathLandingController.$inject = [
        '$scope',
        'US',
        '$routeParams',
        '$rootScope',
        '$location',
        'video',
        'audio',
        '$document',
        'pathService',
        'postService',
        'submitService',
        'RAService',
        'localStorageService'];

	function PathLandingController($scope, US, $routeParams, $rootScope, $location, video, audio, $document, pathService, postService, submitService, RAService, localStorage) {
		var $this = this;
		if(!$rootScope.currentPath) {
			$scope.$emit('errorMessage', {statusText: "Please, select path"});
			$location.path('/path-store');
		}
		if($rootScope.currentPath.id != $routeParams.id) {
			$routeParams.id = $rootScope.currentPath.id;
		}
		$rootScope.$emit('showBackLink');

		$scope.$parent.titleClass = '';
		$scope.$parent.headerTitle = 'Current Path';
		$scope.isOpenSource = false;
		$scope.recentActivity = [];
		$scope.posts = [];

		$scope.pageScroll = 1;
		$scope.postsPageScroll = 1;
		$scope.limitLoad = 10;
		$scope.postsLimitLoad = 10;
		$scope.isLoaPage = false;
		$scope.isPostsLoadPage = false;
		$scope.postSending = false;
		$scope.stopLoadComment = false;
		$scope.collapsed     = localStorage.get('collapsed');
        $scope.collapsedEdit = localStorage.get('collapsedEdit');

        $scope.editSectionData = {
            'title': "",
        }

		// The array check is because collapsed used to be an array
		if (!$scope.collapsed || Array.isArray($scope.collapsed)) {
			$scope.collapsed = {}
		}

        if (!$scope.collapsedEdit || Array.isArray($scope.collapsedEdit)) {
            $scope.collapsedEdit = {}
        }


		/**
		 * Open select step
		 * @param lessenId
		 * @param levelId
		 * @param stepId
		 */
		$scope.openStep = function(lessenId, levelId, stepId) {
			$location.path('path/step/'+ lessenId + '&'+ levelId + '&'+ stepId );
			$document.scrollTop(0);
		};
		function callbackGetPosts(response) {
			$scope.posts = $scope.posts.concat(response.posts);
			$scope.isPostsLoadPage = true;
		}
		$scope.getPosts = function(page, limit) {
			$scope.stopLoadComment = true;
			postService.getPosts(page, limit).then(function(response) {
				$scope.stopLoadComment = false;
				$rootScope.handleResponse($scope, response, callbackGetPosts);
			}, function(responce) {
				$scope.stopLoadComment = false;
			});
		};

		$scope.loadMorePostsPage = function() {
			if ($scope.isOpenComment || $scope.isOpenSource) {
				return;
			}
			$scope.postsPageScroll += 1;
			$scope.getPosts($scope.postsPageScroll, $scope.postsLimitLoad);
		};
		function callbackAddPost(response) {
			$scope.posts.unshift(response.post);
			$scope.isAttach = false
		}
		$scope.showAttach = function(e) {
			e.preventDefault();
			$scope.isAttach = true;
		};
		$scope.openAttache = function (type) {
			$scope.message.files = [];
			$scope.attacheType = type;
		};
		$scope.message = {};
		$scope.chatMessage = {
			message: '',
			attacheType: '',
			files: []
		};
		$scope.postSubmit = function() {
			var formData = new FormData();
			if ($scope.chatMessage.message.trim() == '') {
				return false;
			}
			formData.append('message', $scope.chatMessage.message);
			if($scope.chatMessage.files.length) {
				formData.append('file', $scope.chatMessage.files[$scope.chatMessage.files.length - 1], $scope.chatMessage.files[$scope.chatMessage.files.length - 1].name);
				formData.append('attachmentType', $scope.chatMessage.attacheType);
			}

			$scope.replySending = true;
			$scope.postSending = true;
			postService.addPostFile(formData)
				.then(function(response) {
					$rootScope.handleResponse($scope, response, callbackAddPost);
					$scope.chatMessage = {
						message: '',
						attacheType: '',
						files: []
					};
					$scope.replySending = false;
					$scope.postSending = false;
				}, function(error) {
					$scope.postSending = false;
					$scope.$emit('errorMessage', error);
				});
		};
		$scope.clearPreView = function() {
			$scope.chatMessage.files = [];
			$scope.attacheType = '';
		};
		
		$scope.collapseSection = (id) => {
			$scope.collapsed[id] = !$scope.collapsed[id]
			try {
				localStorage.set('collapsed', $scope.collapsed);
			} catch(err) { /* just being extra safe */ }
		}

        $scope.collapseEdit = (id) => {
            $scope.collapsedEdit[id] = !$scope.collapsedEdit[id]


            try {
                localStorage.set('collapsedEdit', $scope.collapsedEdit);
            } catch(err) { /* just being extra safe */ }
        }

        $scope.secureEditSection = (id) => {
            $scope.path.phases.forEach(function(val) {
               if(val.id == id){
                   $scope.editSectionData['title'] = val.phaseName;
               }
            });

            pathService.secureEditSection(id, $scope.editSectionData, $rootScope.user.token)
                .then(function (response){
                    //location.reload();
                    $scope.collapseEdit(id);
                }, function (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.responseJSON.status,
                    })
                }
            );
        }

		// TODO make it by another way (prototypes or something else).
		new window.PostsScopeTrait($scope, US, postService, submitService, RAService, $rootScope, video, audio, $routeParams, $location);

		/**
		 * count points to sections
		 * @param scope
		 * @returns {boolean}
		 */
		$this.stepPoints = function(scope) {
			if(typeof (scope.path) == "undefined") {
				return false;
			}
			var isHide = false;
			var isHideSection = false;
			scope.path.phases.forEach(function(val) {
				val.totalPoints = 0;
				val.allPoints = 0;
				val.isHideSection = isHideSection;
				val.levels.forEach(function(lVal) {
					lVal.isHide = isHide;
						if(scope.path.totalPoints < lVal.targetPoints) {
							isHide = true;
							isHideSection = true;
						}
					lVal.actions.forEach(function(sVal) {
						val.allPoints += sVal.points;
						if(sVal.completedOnce){
							val.totalPoints += sVal.points;
						}

					});
				});
			});
		};

		$rootScope.isLoaded = true;
        /*pathService.getPath($routeParams.id).then(function(response) {
            $rootScope.isLoaded = false;
            $scope.path = response.path;
            $scope.path.phases.sort(function(obj1,obj2) {
                return obj1.phaseNumber - obj2.phaseNumber;
            });
            $scope.$parent.headerTitle = response.path.title;
            $scope.$emit('currentPath', $scope.path);
            $this.stepPoints($scope);
        }, function(error) {
            $rootScope.isLoaded = false;
            $scope.$emit('errorMessage', error);
        });*/

        pathService.getPathv3($routeParams.id).then(function(response) {
            $rootScope.isLoaded = false;
            var path =  response.path;
            $scope.$parent.headerTitle = response.path.title;
            pathService.getPathPhases($routeParams.id).then(function(response) {
                path.phases = response.phases;
                path.phases.sort(function(obj1,obj2) {
                    return obj1.phaseNumber - obj2.phaseNumber;
                });

                $scope.path = path;
                $scope.$emit('currentPath', $scope.path);
                $this.stepPoints($scope);
            });

        }, function(error) {
            $rootScope.isLoaded = false;
            $scope.$emit('errorMessage', error);
        });

		$scope.getPosts($scope.postsPageScroll, $scope.postsLimitLoad);
		$scope.getRecentActivity($scope.pageScroll, $scope.limitLoad);


    }
})();
