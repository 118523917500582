'use strict';

angular.module('unleeshApp')
	.directive('pathmailsNew', function (pathmailService, $rootScope, $timeout) {
		return{
			restrict: 'A',
			scope: {
				interval:'@'
			},
			link: function (scope, element, attr) {
				function getNewPathmails() {
					pathmailService.getNewPathmailThreads()
						.then(function(response) {
							if(response.statusCode == 200) {
								$rootScope.countNewPathmails = response.count;
								trigger = $timeout(function() {
									getNewPathmails();
								}, interval)
							}else if(response.statusCode == 401) {
								$rootScope.$emit('userLogout', response);
							}
						}, function() {
							trigger = $timeout(function() {
								getNewPathmails();
							}, interval)
						});
				}
				var interval = attr.interval || 50000;
				$rootScope.countNewEvent = 0;

				var trigger = null;
				getNewPathmails();
			}
		};
	});
