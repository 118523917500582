(function() {
	'use strict';

	angular.module('unleeshApp').controller('CurrentPathController', CurrentPathController);
	CurrentPathController.$inject = ['$scope', '$rootScope', '$location', '$document', 'pathService'];

	function CurrentPathController($scope, $rootScope, $location, $document, pathService) {
		$scope.$parent.headerTitle = 'Current Path';
		// $scope.modalOpen = false;
		$scope.currentPathInited = false;
		$scope.userHasCurrentPath = false;
        $scope.accessTogglerOn    = false;
        $scope.modalTogglerOn    = true;

        $rootScope.isLoaded = true;

        $scope.toggleAccessCode = toggleAccessCode;
        $scope.accessCode       = accessCode;

        $scope.toggleModal      = toggleModal;
        $scope.secureEditPath   = secureEditPath;

        $scope.data = {
            'accessCode': "",
        };

        $scope.editPathData = {
            'title': "",
            'description': "",
        }

        //Load current path
        pathService.loadCurrentPath().then(function(response) {
            $rootScope.isLoaded = false;
            $rootScope.handleResponse($scope, response, currentPathLoadCallback);
        }, function (error){
            console.log('ERROR');
            $rootScope.isLoaded = false;
            $scope.$emit('errorMessage', error);
            $scope.currentPathInited = true;
        });

        //Load available paths
        pathService.getPathsStarted_NEW().then(function(response) {
            $rootScope.handleResponse($scope, response, pathsLoadCallback);
        }, function (error){
            $rootScope.isLoaded = false;
            $scope.$emit('errorMessage', error);
        });

        function currentPathLoadCallback(response) {
            $scope.userHasCurrentPath = (response.path !== undefined && response.path !== null);
            if ($scope.userHasCurrentPath) {
                $scope = $rootScope.getCurrentPath($scope, response.path);
                $scope.currentPath = response.path;

                $scope.editPathData = {
                    'title': response.path.title,
                    'description': response.path.description,
                }
            } else {

            }
            $scope.currentPathInited = true;
        }

        function pathsLoadCallback(response) {
            if(response.paths !== undefined){
                var paths = {
                    'currentPath' : [],
                    'inProgressPaths': [],
                    'completedPaths': []
                };
                response.paths.forEach(function(item, index){
                   if(item.status == 1){
                       paths.currentPath = item;
                   } else if(item.status == 0) {
                       paths.inProgressPaths.push(item);
                   } else if(item.status == 2 ) {
                       paths.completedPaths.push(item);
                   }
                });
                $scope.path = paths;
            } else {
                // console.log('No paths loaded');
            }
        }

        function toggleAccessCode(e) {
            //e.preventDefault();
            $scope.accessTogglerOn = !$scope.accessTogglerOn;
            console.log('access_code');
        }

        function toggleModal(e) {
            $scope.modalTogglerOn = !$scope.modalTogglerOn;
            console.log('path_edit');
            return false;
        }

        function secureEditPath(path_id){
            $scope.formData = new FormData();

            if($scope.editPathData['title']){
                $scope.formData.append('title', $scope.editPathData['title']);
            }

            if($scope.editPathData['description']){
                $scope.formData.append('description', $scope.editPathData['description']);
            }

            if($scope.editPathData['icon'] && $scope.editPathData['icon'].length!= null) {
                $scope.formData.append('icon',$scope.editPathData['icon'][$scope.editPathData['icon'].length - 1] ,$scope.editPathData['icon'][$scope.editPathData['icon'].length - 1].name);
            }

            console.table(Object.fromEntries($scope.formData));

            pathService.secureEditPath(path_id, $scope.formData, $rootScope.user.token)
                .then(function (response){
                    location.reload();
                }, function (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.responseJSON.status,
                    })
                }
            );
        }

        function accessCode(e){
            toggleAccessCode(e);
            pathService.useAccessCode($scope.data.accessCode, $rootScope.user.token)
                .then(function (response) {
                    location.reload();
                }, function (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.responseJSON.status,
                })
            });
        }

		function setCurrentPath(response) {
			$rootScope.currentPath.id = response.idPath;
			$location.path('/path/view/' + response.idPath);
		}

		// };
		// Open select step
		$scope.openStep = function (lessenId, levelId, stepId) {
			$location.path('path/step/' + lessenId + '&' + levelId + '&' + stepId);
			$document.scrollTop(0);
        };

        // added by Ben

        function changePath(path_id) {
            $rootScope.isLoaded = true;
            pathService.setCurrentPath(path_id, $rootScope.user.token)
                .then(function (response) {
                    $rootScope.isLoaded = false;
                    response.idPath = path_id;
                    $rootScope.handleResponse($scope, response, setCurrentPath);
                }, function (error) {
                    console.log(error)
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: `Had an error while switching paths`,
                    })
                });
        }

        // function called when clicking on a path card
        // If it's the current path, just opens it. If it's not, prompts to set path to current path.
        // this uses path.status to determine what to do. Here's what the values of status means:
        // path.status == 0 --> Path is "in progress"
        // path.status == 1 --> Path is current path
        // path.status == 2 --> Path is completed
        $scope.goToPath = (path) => {
            // if path is current path, just open it
            if (parseInt(path.status) === 1) {
                $location.path("path/view/" + path.id);
            }
            // otherwise, prompt to switch current path to it
            else {
                // the original code used path.title, but at least in the test data path.title was undefined, 
                // and path.name seems to be the correct value. 
                const title = path.title ? path.title : path.name

                // Show switch path confirmation
                Swal.fire({
                    icon: 'question',
                    title: 'Switch paths',
                    text: `Would you like to switch your path to ${title}?`,
                    focusConfirm: true,
                    confirmButtonText: "Switch",
                    showCancelButton: true
                }).then(result => {
                    if (result.isConfirmed) {
                        changePath(path.id)
                      }
                  })
            }
        }

        // setTimeout(() => {
        //      //Load available paths
        //     pathService.getPathsStarted_NEW().then(function(response) {
        //         $rootScope.handleResponse($scope, response, pathsLoadCallback);
        //     }, function (error){
        //         $rootScope.isLoaded = false;
        //         $scope.$emit('errorMessage', error);
        //     });
        // }, 3000) 
	}

})();