(function() {
    'use strict';

    // Videojs hack for the firefox.
    window['WebVTT'] = true;

    angular.module('unleeshApp').factory('video', videoFactory);
    angular.module('unleeshApp').factory('audio', audioFactory);
    videoFactory.$inject = ['$document'];
    audioFactory.$inject = ['$document'];

    // TODO Remake on prototypes?
    function videoFactory($document) {
        var video = $document[0].createElement('video');
        var options = {
            controls: true,
            width: false,
            height: false
        };
        var player = videojs(video, options);
        player.addClass('video-js vjs-default-skin');
        player.on('fullscreenchange', onFullScreenChange);
        addRotateButton();

        var videoWidth, videoHeight, wrapperWidth, wrapperHeight;
        var wrapper = player.contentEl(), rotation = 0, needToPlay = false;

        player.on('loadedmetadata', onMetaDataLoad, false);
        player.ready(function() {
            if (needToPlay) {
                player.play();
            }
        });

        return {
            play: function(src) {
                needToPlay = true;
                rotation = 0;

                player.src(src);
                player.load();
                player.play();
            },
            stop: function() {
                needToPlay = false;
                player.pause();
                player.currentTime(0);
            },
            elem: wrapper
        };

        function addRotateButton() {
            var button = player.controlBar.addChild('button');
            button.addClass('vjs-rotate-button');
            button.el().onclick = onRotateClick;
        }

        function initDimensions() {
            if (videoWidth) {
                return;
            }
            videoWidth = video.videoHeight;
            videoHeight = video.videoWidth;
            wrapperHeight = wrapper.offsetHeight;
            wrapperWidth = wrapper.offsetWidth;
        }

        function adjustDimensionsByRotate(rotation) {
            initDimensions();
            var is90Rotation = ((rotation % 180) != 0);
            console.log('Full Screen', player.isFullscreen());
            player.isFullscreen()
                ? adjustFullScreenDimensions(is90Rotation)
                : adjustSmallDimensions(is90Rotation);
        }

        function adjustSmallDimensions(is90Rotation) {
            adjustFullScreenDimensions(is90Rotation);
        }

        function adjustFullScreenDimensions(is90Rotation) {
            if (is90Rotation) {
                video.style.width = 'auto';
                video.style.height = (100 * videoWidth / videoHeight) + '%';
            }
            else {
                video.style.width = '100%';
                video.style.height = '100%';
            }
        }

        function changeRotation() {
            var style = 'rotate(' + rotation + 'deg)';
            video.style.webkitTransform = style;
            video.style.mozTransform    = style;
            video.style.msTransform     = style;
            video.style.oTransform      = style;
            video.style.transform       = style;
        }

        function onFullScreenChange() {
            adjustDimensionsByRotate(rotation);
        }

        function onMetaDataLoad() {
            adjustDimensionsByRotate(0);
        }

        function onRotateClick() {
            rotation += 90;
            rotation = (rotation == 360) ? 0 : rotation;
            changeRotation(rotation);
            adjustDimensionsByRotate(rotation);
        }
    }

    function audioFactory($document) {
        var audioElement = $document[0].createElement('audio');
        return {
            play: function(filename) {
                audioElement.src = filename;
                audioElement.controls = true;
                audioElement.style.display = 'block';
                audioElement.play();
            },
            stop: function() {
                audioElement.style.display = 'none';
                audioElement.pause();
            },
            elem: audioElement
        };
    }

})();
