(function () {
    'use strict';

    angular.module('unleeshApp').directive('activeTracking', activeTracking);

    function activeTracking($location) {
        return {
            restrict: 'A',
            scope: false,
            link: link
        };

        function link(scope, element) {
            setActiveMenuItem();
            scope.$on('$locationChangeSuccess', setActiveMenuItem);

            function setActiveMenuItem() {
                var path = $location.path();
                if (!path) {
                    return;
                }
                var isCurrentPathPage = !!path.match(/\/path\/view\/\d*(?=\\?|$)/);
                angular.forEach(element.find('a'), function(menuItem) {

                    // Search for current path menu item to set it active because of isCurrentPathPage matching.
                    if (isCurrentPathPage) {
                        var isCurrentPathItem = !!menuItem.href.match('#/current-path(?=\\?|$)');
                        menuItem = angular.element(menuItem);
                        if (!isCurrentPathItem) {
                            menuItem.removeClass('selected');
                            return true;
                        }
                        menuItem.addClass('selected');
                        return false;
                    }

                    var isActive = !!menuItem.href.match('#' + path + '(?=\\?|$)');
                    menuItem = angular.element(menuItem);
                    isActive ? menuItem.addClass('selected') : menuItem.removeClass('selected');
                });
            }
        }
    }

})();
