(function() {
	'use strict';

	angular.module('unleeshApp').controller('NewThread', NewThread);
	NewThread.$inject = ['$scope', '$rootScope', '$routeParams', 'pathmailService', '$window', '$timeout', '$location'];

	function NewThread($scope, $rootScope, $routeParams, pathmailService, $window, $timeout, $location) {
		var selectedPath = 0,
			selectedPathIndex = -1,
			forwardMessage;
		if($routeParams.copy && $routeParams.tread) {
			getMessages($routeParams.tread, $routeParams.copy);
		}
		$scope.$parent.headerTitle = 'New Pathmail';
		$scope.accounts = [];
		$scope.paths = [];
		$scope.inputData = {};
		$scope.sendToPaths = false;
		$scope.search = '';
		$scope.message = {
			message: '',
			attacheType: '',
			files: []
		};
		$scope.messageIsSending = false;

		$scope.toggleRecipients = toggleRecipients;
		$scope.addNewThread = addNewThread;
		$scope.selectPath = selectPath;
		$scope.selectUser = selectUser;
		$scope.removeRecipient = removeRecipient;

		getUsersAndPaths();

		function toggleRecipients() {
			$scope.sendToPaths = !$scope.sendToPaths;
			$scope.inputData = {};
			if ($scope.sendToPaths && selectedPathIndex >= 0) {
				$scope.inputData[selectedPathIndex] = $scope.paths[selectedPathIndex].title;
			}
			else if(!$scope.sendToPaths) {
				refreshInputWithUsers();
			}
			recipientListChangeCallback();

			resetSearch()
			checkShowResponseCover()
		}

		function getUsersAndPaths() {
			$scope.loading = true;
			pathmailService.getUsersAndPaths().then(function(response) {
				$rootScope.handleResponse($scope, response, function() {
					$scope.accounts = response.users;
					$scope.paths = response.paths;
					$scope.filteredAccounts = $scope.accounts.slice()
					$scope.filteredPaths = $scope.paths.slice()
					$scope.loading = false;
					if($routeParams.id) {
						getUserParams($routeParams.id);
					}
				});
			});
		}

		function addNewThread() {
			if (!selectedPath && $scope.sendToPaths) {
				$scope.$emit('errorMessage', {statusText: 'You should select at least one path.'});
				return;
			}
			var selectedUsers;
			if (!$scope.sendToPaths) {
				selectedUsers = getSelectedUsers();
			}
			if (!$scope.sendToPaths && !selectedUsers.length) {
				$scope.$emit('errorMessage', {statusText: 'You should select at least one user.'});
				return;
			}

			$rootScope.isLoaded = true;
			$scope.messageIsSending = true;
			var formData = new FormData();
			if ($scope.message.message) {
				formData.append('message', $scope.message.message);
			}
			if ($scope.message.files && $scope.message.files.length) {
				if($scope.message.files[$scope.message.files.length - 1].url) {
					formData.append('file', $scope.message.files[$scope.message.files.length - 1].url);
					formData.append('attachmentType', $scope.message.attacheType);
				} else {
					formData.append('file', $scope.message.files[$scope.message.files.length - 1], $scope.message.files[$scope.message.files.length - 1].name);
					formData.append('attachmentType', $scope.message.attacheType);
				}
			}

			if ($scope.sendToPaths) {
				formData.append('path', selectedPath);
			}
			else {
				angular.forEach(selectedUsers, function(userId, index) {
					formData.append('recipients[' + index + ']', userId);
				});
			}

			pathmailService.addNewThread(formData).then(function(response) {
				$rootScope.handleResponse($scope, response, function (response) {
					$scope.messageIsSending = false;
					$timeout(function() {
						$rootScope.isLoaded = false;

						// $window.history.back();
						if (response.thread && response.thread.id) {
							$location.path(`/pathmail/thread/${response.thread.id}`);
						} else {
							$location.path(`/pathmail`);
						}
					},1000);
				});
			});
		}

		function getSelectedUsers() {
			var selectedUsers = [];
			angular.forEach($scope.accounts, function(account) {
				if (account.selected) {
					selectedUsers.push(account.id);
				}
			});
			return selectedUsers;
		}

		function selectPath(path, index) {
			if ($scope.paths[selectedPathIndex]) {
				$scope.paths[selectedPathIndex].selected = false;
			}
			$scope.search = '';
			selectedPathIndex = index;
			selectedPath = path.id;
			path.selected = !path.selected;
			$scope.inputData = {selectedPathIndex: $scope.paths[selectedPathIndex].title};
			recipientListChangeCallback();
		}

		function selectUser(account) {
			account.selected = !account.selected;
			$scope.search = '';
			$scope.inputData = {};
			refreshInputWithUsers();
			recipientListChangeCallback();
		}

		function refreshInputWithUsers() {
			angular.forEach($scope.accounts, function(account, index) {
				if (!account.selected) {
					return;
				}
				$scope.inputData[index] = account.firstName + ' ' + account.lastName;
			});
		}

		function removeRecipient(index) {
			if ($scope.sendToPaths) {
				$scope.paths[selectedPathIndex].selected = false;
				selectedPath = 0;
				selectedPathIndex = -1;
				$scope.inputData = {};
			}
			else {
				$scope.accounts[index].selected = false;
				delete $scope.inputData[index];
			}

			checkShowResponseCover()
		}

		function getUserParams(id) {
			angular.forEach($scope.accounts, function(account, index) {
				if (account.id == id) {
					selectUser(account);
				}
			});
		}

		function getMessages(tread,id) {
			$rootScope.isLoaded = true;
			pathmailService.getPathmailThread(tread, {messages: id}).then(function (response) {
				$rootScope.handleResponse($scope, response, getMessagesCallback);
			});
		}

		function recipientListChangeCallback() {
			$timeout(function() {
				$scope.$broadcast('fixedPanelHeightChange');
			});
		}

		function getMessagesCallback(response) {
			angular.forEach(response.pathmailMessages, function(message) {
				if(message.id == $routeParams.copy) {
					forwardMessage = message;
				}
			});

			$scope.message.message = forwardMessage.message;
			if(forwardMessage.attachmentType) {
				var xhr = new XMLHttpRequest();
				xhr.open("GET", forwardMessage.attachment, true);
				xhr.responseType = "arraybuffer";
				xhr.onload = onXHRLoad;
				xhr.send();
			}
			else {
				$rootScope.isLoaded = false;
			}
		}

		function onXHRLoad(e) {
			var arrayBufferView = new Uint8Array(this.response);
			var blob;
			switch (forwardMessage.attachmentType) {
				case 'image':
					blob = new Blob([arrayBufferView], {type: forwardMessage.attachmentType + "/jpeg"});
					break;
				case 'video':
					blob = new Blob([arrayBufferView], {type: forwardMessage.attachmentType + "/mp4"});
					break;
				case 'audio':
					var cache = forwardMessage.attachment.split('.');
					blob = new Blob([arrayBufferView], {type: forwardMessage.attachmentType + "/" + cache[cache.length - 1]});
					break;
			}
			$scope.message.files.push(blob);
			$scope.message.attacheType = forwardMessage.attachmentType;
			$scope.$apply(function() {
				$rootScope.isLoaded = false;
			});
		}


		// added by Ben

		// clears and resets search
		$scope.filteredAccounts = []
		$scope.filteredPaths = []
		function resetSearch() {
			$scope.search = ''
			$scope.filteredAccounts = $scope.accounts.slice()
			$scope.filteredPaths = $scope.paths.slice()
		}
		resetSearch() // call once to setup

        // on search box input
        $scope.$watch("search", () => {
            // If search is empty, show all users 
            if (!$scope.search || $scope.search.length === 0) {
				resetSearch()
                return
            }

			const searchTerm = $scope.search.toLowerCase()

			if ($scope.sendToPaths) {
				$scope.filteredPaths = $scope.paths.filter(path => {
					return path.title.toLowerCase().includes(searchTerm)
				})
			} else {
				$scope.filteredAccounts = $scope.accounts.filter(account => {
					const firstName = account.firstName ? account.firstName.toLowerCase() : "";
					const lastName = account.lastName ? account.lastName.toLowerCase() : "";
					
					// match on any part of their name
					if (`${firstName} ${lastName}`.includes(searchTerm)) {
						return true
					}

					// no match, don't include
					return false
				})
			}
		})
		
		// show/hide response cover
		$scope.showResponseCover = true
		$scope.$watch("inputData", checkShowResponseCover)
		function checkShowResponseCover() {
			if (Object.keys($scope.inputData).length > 0) {
				$scope.showResponseCover = false
			} else {
				$scope.showResponseCover = true
			}
		}
	}

})();
