'use strict';

angular.module('unleeshApp').directive('timeWatch',
	function() {
		return {
			restrict: 'A',
			scope: false,
			link: function(scope, elem, attrs) {
				scope.$watch('event.start_time_hours', function(val) {
					if(typeof(val) !== 'number') {
						val = parseInt(val);
					}
					if ((val > 12)){
						scope.form.validTimeStart = true;
						scope.form.start_time_hours.$valid = false;
						scope.event.start_time_hours = 12;
					} else if((val < 1)){
						scope.form.validTimeStart = true;
						scope.form.start_time_hours.$valid = false;
						scope.event.start_time_hours = 0;
					} else {
						scope.form.validTimeStart = false;
						scope.form.start_time_hours.$valid = true;
					}
					elem.find('[name="start_time_hours"]').val(scope.event.start_time_hours);
				});
				scope.$watch('event.start_time_minute', function(val) {
					if(typeof(val) !== 'number') {
						val = parseInt(val);
					}
					if ((val > 59)){
						scope.form.validTimeStart = true;
						scope.form.start_time_minute.$valid = false;
						scope.event.start_time_minute = 59;
					} else if((val < 0)){
						scope.form.validTimeStart = true;
						scope.form.start_time_minute.$valid = false;
						scope.event.start_time_minute = 0;
					} else {
						scope.form.validTimeStart = false;
						scope.form.start_time_minute.$valid = true;
					}
					elem.find('[name="start_time_minute"]').val(scope.event.start_time_minute);
				});
				scope.$watch('event.end_time_hours', function(val) {
					if(typeof(val) !== 'number') {
						val = parseInt(val);
					}
					if ((val > 12)){
						scope.form.validTimeEnd = true;
						scope.form.end_time_hours.$valid = false;
						scope.event.end_time_hours = 12;
					} else if((val < 1)){
						scope.form.validTimeEnd = true;
						scope.form.end_time_hours.$valid = false;
						scope.event.end_time_hours = 0;
					} else {
						scope.form.validTimeEnd = false;
						scope.form.end_time_hours.$valid = true;
					}
					elem.find('[name="end_time_hours"]').val(scope.event.end_time_hours);
				});
				scope.$watch('event.end_time_minute', function(val) {
					if(typeof(val) !== 'number') {
						val = parseInt(val);
					}
					if ((val > 59)){
						scope.form.validTimeEnd = true;
						scope.form.end_time_minute.$valid = false;
						scope.event.end_time_minute = 59;
					} else if((val < 0)){
						scope.form.validTimeEnd = true;
						scope.form.end_time_minute.$valid = false;
						scope.event.end_time_minute = 0;
					} else {
						scope.form.validTimeEnd = false;
						scope.form.end_time_minute.$valid = true;
					}
					elem.find('[name="end_time_minute"]').val(scope.event.end_time_minute);
				});
			}
		}
	}
);
