(function() {
    'use strict';

    angular.module('unleeshApp').service('pathmailService', pathmailService);
    pathmailService.$inject = ['doRequest', 'config', 'US'];

    function pathmailService(doRequest, config, US) {
        var $this = this;

        $this.getPathmailUrlSuffix = function() {
            return 'pathmail';
        };

        $this.getThreadsUrlSuffix = function() {
            return 'threads';
        };

        $this.getMessagesUrlSuffix = function() {
            return 'messages';
        };

        $this.getUserAndPathsUrlSuffix = function() {
            return 'users-paths';
        };

        $this.getPathmailThreadsUrl = function() {
            return config.appHost + US.urlSeparator() + $this.getPathmailUrlSuffix() + US.urlSeparator() + $this.getThreadsUrlSuffix() + US.urlSeparator();
        };

        $this.getPathmailNewThreadUrl = function() {
            return config.appHost + US.urlSeparator() + $this.getPathmailUrlSuffix() + US.urlSeparator() + $this.getThreadsUrlSuffix() + US.urlSeparator();
        };

        $this.getPathmailThreadUrl = function(threadId) {
            return config.appHost + US.urlSeparator() + $this.getPathmailUrlSuffix() + US.urlSeparator() + $this.getThreadsUrlSuffix() + US.urlSeparator() + threadId + US.urlSeparator();
        };

        $this.getPathmailMessagesUrl = function() {
            return config.appHost + US.urlSeparator() + $this.getPathmailUrlSuffix() + US.urlSeparator() + $this.getMessagesUrlSuffix() + US.urlSeparator();
        };

        $this.getPathmailUserAndPathsUrl = function() {
            return config.appHost + US.urlSeparator() + $this.getPathmailUrlSuffix() + US.urlSeparator() + $this.getUserAndPathsUrlSuffix() + US.urlSeparator();
        };

        /**
         * GET
         * Get pathmails threads.
         */
        $this.getPathmailThreads = function (data, callback) {
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getPathmailThreadsUrl() + '?token=' + US.getToken(),
                data,
                callback
            )
        };
        /**
         * GET
         * Get new pathmails threads to notification.
         */
        $this.getNewPathmailThreads = function (callback) {
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getPathmailThreadsUrl() + '?token=' + US.getToken(),
                {onlyNewItemsCount: 1},
                callback
            )
        };

        /**
         * GET
         * Get pathmails single thread.
         */
        $this.getPathmailThread = function (threadId, data, callback) {
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getPathmailThreadUrl(threadId) + '?token=' + US.getToken(),
                data,
                callback
            )
        };

        /**
         * POST
         */
        $this.sendMessageToThread = function (threadId, entity, callback) {
            return doRequest.doRequestFile(
                US.REQUEST_TYPE.POST,
                $this.getPathmailThreadUrl(threadId) + '?token=' + US.getToken(),
                entity,
                callback
            )
        };

        /**
         * POST
         */
        $this.deleteMessage = function (messageId, callback) {
            return doRequest.doRequest(
                US.REQUEST_TYPE.DELETE,
                $this.getPathmailMessagesUrl() + '?token=' + US.getToken() + '&messages=' + messageId,
                {},
                callback
            )
        };

        /**
         * POST
         */
        $this.getUsersAndPaths = function (data, callback) {
            return doRequest.doRequest(
                US.REQUEST_TYPE.GET,
                $this.getPathmailUserAndPathsUrl() + '?token=' + US.getToken(),
                data,
                callback
            )
        };

        /**
         * POST
         */
        $this.addNewThread = function(entity, callback) {
            return doRequest.doRequestFile(
                US.REQUEST_TYPE.POST,
                $this.getPathmailThreadsUrl() + '?token=' + US.getToken(),
                entity,
                callback
            )
        }
    }
})();
