(function() {
    'use strict';

    angular.module('unleeshApp').controller('GlobalSearchResultsController', GlobalSearchResultsController);
    GlobalSearchResultsController.$inject = ['$scope', '$routeParams', '$rootScope', '$location', 'formatDate', 'searchService', 'pathService'];

    function GlobalSearchResultsController($scope, $routeParams, $rootScope, $location, formatDate, searchService, pathService) {

        $scope.$parent.headerTitle = 'Global Search';

        $scope.searchResults = [];
        $scope.userHasCurrentPath = ($rootScope.currentPath !== undefined && $rootScope.currentPath.id !== undefined);
        if (!$scope.userHasCurrentPath) {
            return;
        }

        var lastMessageCreated = '';
        $scope.formattedThreads = [];

        $scope.collapsedPaths    = [];
        $scope.collapsedSections = [];
        $scope.collapsedSteps    = [];

        var query = $routeParams;
        console.log($routeParams);

        $scope.searchInput = query.string;

        searchService.search(query).then(function(response) {
            $scope.foundUsers    = response.users;
            $scope.foundPaths    = response.paths;
            $scope.foundEvents   = response.breakouts;
            $scope.foundMessages = [];

            if(response.threads.length) {
                var lastThread = response.threads[response.threads.length - 1];
                var lastMessage = lastThread.messages[0];
                lastMessageCreated = lastMessage.created;
                $scope.foundMessages = $scope.foundMessages.concat(response.threads);
                $scope.threadsAreLoading = false;
            }

            setupFormattedThreads()
            setupFormattedPaths()

            console.log($scope.foundPaths);

        }, function(response) {
            $rootScope.isLoaded = false;
        });

        $scope.submitSearchRequest = function(){
            console.log($scope.searchInput);
            if($scope.searchInput){
                $location.path('search/'+ $scope.searchInput );
            }
        }

        $scope.viewEvent = function(id) {
            $location.path('/event/view/'+ id);
        }

        function setCurrentPath(response) {
            $rootScope.currentPath.id = response.idPath;
            $location.path('/path/view/' + response.idPath);
        }

        $scope.changePath = function(path_id) {
            $rootScope.isLoaded = true;
            return pathService.setCurrentPath(path_id, $rootScope.user.token)
                .then(function (response) {
                    $rootScope.isLoaded = false;
                    response.idPath = path_id;
                    $rootScope.currentPath.id = path_id;
                    $rootScope.currentPath.phases = false;
                    //$rootScope.handleResponse($scope, response, setCurrentPath);
                }, function (error) {
                    console.log(error)
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: `Had an error while switching paths`,
                })
            });

        }

        $scope.goToPath = (path) => {
            // if path is current path, just open it
            if (parseInt(path.status) === 1) {
                $location.path("path/view/" + path.id);
            }
            // otherwise, prompt to switch current path to it
            else {
                // the original code used path.title, but at least in the test data path.title was undefined,
                // and path.name seems to be the correct value.
                const title = path.title ? path.title : path.name

                // Show switch path confirmation
                Swal.fire({
                    icon: 'question',
                    title: 'Switch paths',
                    text: `Would you like to switch your path to ${title}?`,
                    focusConfirm: true,
                        confirmButtonText: "Switch",
                        showCancelButton: true
                }).then(result => {
                    if (result.isConfirmed) {
                        $scope.changePath(path.id)
                    }
                })
            }
        }

        function setupFormattedPaths(){
            $scope.formattedPaths = $scope.foundPaths.map(origin_path => {

                let formatted_path = origin_path

                formatted_path.id     = origin_path.id;
                //$scope.collapsedPaths[id] = origin_path.id;

                let searchString = $scope.searchInput;
                pathService.getPathPhases(origin_path.id).then(function(response) {
                    formatted_path.phases = response.phases;
                    formatted_path.phases.sort(function(obj1,obj2) {
                        return obj1.phaseNumber - obj2.phaseNumber;
                    });

                    formatted_path.phases.forEach(function(phase){
                        $scope.collapseSection(phase.id);
                        phase.levels.forEach(function(level){
                            level.actions.forEach(function(action){
                                if(!action.title || !action.description){
                                    $scope.collapseStep(action.id);
                                    return;
                                }
                                if(!action.title.toLowerCase().includes($scope.searchInput.toLowerCase()) &&
                                    !action.description.toLowerCase().includes($scope.searchInput.toLowerCase())
                                ){
                                    $scope.collapseStep(action.id);
                                } else {
                                    if($scope.collapsedSections[phase.id]){
                                        $scope.collapseSection(phase.id);
                                    }
                                }
                            });
                        });
                    });

                }, function (error) {
                    $rootScope.isLoaded = false;
                    $scope.$emit('errorMessage', error);
                });

                console.log(formatted_path);

                return formatted_path;
            })
        }

        function setupFormattedThreads() {
            $scope.formattedThreads = $scope.foundMessages.map(thread => {
                let formatted_thread = {}

                const message = thread.messages[0]

                // set photo or photo initials
                if (message.author.photo && message.author.photo.length > 0) {
                    formatted_thread.photo = message.author.photo
                } else {
                    let firstInitial = message.author.firstName ? message.author.firstName[0] : ""
                    let lastInitial  = message.author.lastName ? message.author.lastName[0] : ""
                    formatted_thread.initials = `${firstInitial}${lastInitial}`.toUpperCase()
                }

                // setup name
                formatted_thread.name = `${message.author.firstName} ${message.author.lastName}`

                // setup time
                formatted_thread.time = message.created

                // setup path name
                if (thread.paths.length > 1) {
                    formatted_thread.path = thread.paths.map(path => path.title).join(', ')
                } else {
                    if(thread.paths[0]){
                        formatted_thread.path = thread.paths[0].title
                    }
                }

                // setup sent to
                if (thread.toAll === true) {
                    formatted_thread.sent_to = "Sent to: Entire Path"
                } else if (thread.participants && thread.participants.length > 1) {
                    // the +1 is because apparently it doesn't include yourself in the list of participants
                    formatted_thread.sent_to = `Sent to: ${thread.participants.length + 1} people`

                    // setup sent to as clickable that will show who's going
                    formatted_thread.sentToIsClickable = true;
                    formatted_thread.participants = thread.participants
                } else {
                    formatted_thread.sent_to = `Sent to: Just you`
                }

                // setup message
                formatted_thread.message = message.message

                // This is done because of a server/API bug and should be removed when that bug is fixed
                // For pathmail previews that contain links to events, the server was sending the incorrect link structure.
                // 		What was sent:			<a href="/event/325/">
                //		What it should be:		<a href="#/event/view/325/">
                // This regex fixes this incorrect link. Note: This doesn't happen within threads for some reason.
                const linkMatchRegex = /<a href="\/event\/\d+?\/">/i
                if (linkMatchRegex.test(formatted_thread.message)) {
                    formatted_thread.message = formatted_thread.message.replace(linkMatchRegex, string => {
                        return string.replace("/event/", "#/event/view/")
                    })
                }

                // add id
                formatted_thread.id = thread.id

                return formatted_thread
            })
        }

        // when clicking on a thread
        $scope.goToThread = (id) => {
            $location.path(`/pathmail/thread/${id}`)
        }

        $scope.collapseSection = (id) => {
            $scope.collapsedSections[id] = !$scope.collapsedSections[id]
            try {
                localStorage.set('collapsedSections', $scope.collapsedSections);
            } catch(err) { /* just being extra safe */ }
        }

        $scope.collapsePath = (id) => {
            $scope.collapsedPaths[id] = !$scope.collapsedPaths[id]
            try {
                localStorage.set('collapsedPaths', $scope.collapsedPaths);
            } catch(err) { /* just being extra safe */ }
        }

        $scope.collapseStep = (id) => {
            $scope.collapsedSteps[id] = !$scope.collapsedSteps[id]
            try {
                localStorage.set('collapsedSteps', $scope.collapsedSteps);
            } catch(err) { /* just being extra safe */ }
        }

        $scope.goToStep = (path, stepId, sectionId, lessenId) => {
            if (parseInt(path.status) === 1) {
                $location.path(`/path/step/${stepId}&${sectionId}&${lessenId}`);
            } else {

                const title = path.title ? path.title : path.name;
                Swal.fire({
                    icon: 'question',
                    title: 'Switch paths',
                    text: `Would you like to switch your path to ${title}?`,
                    focusConfirm: true,
                        confirmButtonText: "Switch",
                        showCancelButton: true
                }).then(result => {
                    if (result.isConfirmed) {
                        $scope.changePath(path.id).then(function(){
                            $location.path(`/path/step/${stepId}&${sectionId}&${lessenId}`);
                        });

                    }
                })
            }
        }

    }
})();