(function () {
    'use strict';

    angular.module('unleeshApp').controller('PathmailThread', PathmailThread);
    PathmailThread.$inject = ['$scope', '$rootScope', '$routeParams', 'pathmailService', 'moment'];

    function PathmailThread($scope, $rootScope, $routeParams, pathmailService, moment) {
        var threadId = $routeParams.id,
            lastMessageId = 0,
            firstTimeLoading = true,
            today = moment();
        var messageToDelete = {group: '', index: -1};
        var TODAY = 'Today';

        $scope.$parent.headerTitle = '';
        $scope.$parent.isMessage = true;
        $scope.messagesAreLoading = false;
        $scope.messageGroups = [];
        $scope.author = {};
        $scope.threadId = threadId;
        $scope.message = {
            message: '',
            attacheType: '',
            files: []
        };
        $scope.messageIsSending = false;
        $scope.deleteModalIsOpen = false;
        $scope.modalAttachmentIsOpened = false;

        $scope.openAttachment = openAttachment;
        $scope.submitMessage = submitMessage;
        $scope.deleteMessage = deleteMessage;
        $scope.onDeleteModalOk = onDeleteModalOk;
        $scope.onDeleteModalClose = onDeleteModalClose;
        $scope.showEditPanel = showEditPanel;

        getMessages();

        function getMessages() {
            $scope.messagesAreLoading = true;
            pathmailService.getPathmailThread(threadId, {messageId: lastMessageId}).then(function (response) {
                $rootScope.handleResponse($scope, response, getMessagesCallback);
            });
        }

        function getMessagesCallback(response) {

            var messages = response.pathmailMessages.reverse();
            if (!messages.length) {
                return;
            }
            if(!messages[messages.length - 1].isRead) {
                if($rootScope.countNewPathmails > 0){
                    $rootScope.countNewPathmails--;
                }
            }
            if (firstTimeLoading) {
                $scope.author = messages[messages.length - 1].author;
                $scope.$parent.headerTitle = $scope.author.firstName + ' ' + $scope.author.lastName;
                firstTimeLoading = false;
            }
            addNewGroupedMessages(messages);

            // Set lastMessageId to last one to load only new messages after this request.
            lastMessageId = messages[messages.length - 1].id;

            $scope.messagesAreLoading = false;
        }

        function submitMessage() {
            $scope.messageIsSending = true;
            var formData = new FormData();
            if ($scope.message.message) {
                formData.append('message', $scope.message.message);
            }
            if ($scope.message.files && $scope.message.files.length) {
                formData.append('file', $scope.message.files[$scope.message.files.length - 1], $scope.message.files[$scope.message.files.length - 1].name);
                formData.append('attachmentType', $scope.message.attacheType);
            }
            pathmailService.sendMessageToThread(threadId, formData).then(function (response) {
                $rootScope.handleResponse($scope, response, addMessageCallback);
                $scope.message = {
                    message: '',
                    attacheType: '',
                    files: []
                };
                $scope.messageIsSending = false;
            });
        }

        function addMessageCallback(response) {
            if(isGroup(TODAY, $scope.messageGroups)) {
                $scope.messageGroups[$scope.messageGroups.length - 1].messages.push(response.pathmailMessage);
            } else {
                var newGroup = {groupName: TODAY, messages:[]};
                newGroup.messages.push(response.pathmailMessage);
                $scope.messageGroups.push(newGroup);
            }

            scrollToBottom()
        }

        /**
         * Add messages in date groups to output them grouped by date.
         */
        var notSortArray =[];
        function addNewGroupedMessages(messages) {
            // TODO need to order groups to new specification of this page.
            angular.forEach(messages, function(message) {
                var date = moment(message['created']);
                var dateFormat;

                if (date.isSame(today, 'week')) {
                    dateFormat = 'dddd';
                }
                else if (!date.isSame(today, 'year')) {
                    dateFormat = 'MMMM Do, YYYY';
                }
                else {
                    dateFormat = 'MMMM Do';
                }
                if(date.isSame(today, 'day')) {
                    uniqueName(TODAY, notSortArray);
                    return true;
                }
                uniqueName(date.format(dateFormat), notSortArray);

            });

            angular.forEach(notSortArray, function(el) {
                $scope.messageGroups.push({groupName: el, messages:[]});
            });

            var objectMessageGroup = {};
            angular.forEach(messages, function (message) {
                var date = moment(message['created']);
                var group;

                var dateFormat;
                if (date.isSame(today, 'week')) {
                    dateFormat = 'dddd';
                }
                else if (!date.isSame(today, 'year')) {
                    dateFormat = 'MMMM Do, YYYY';
                }
                else {
                    dateFormat = 'MMMM Do';
                }
                if (date.isSame(today, 'day')) {
                    group = getGroup(objectMessageGroup, TODAY);
                    group.push(message);
                    return true;
                }
                group = getGroup(objectMessageGroup, date.format(dateFormat));
                group.push(message);
            });
            angular.forEach($scope.messageGroups, function(el) {
                angular.forEach(objectMessageGroup, function(val, key) {
                    if(el.groupName == key) {
                        el.messages = val;
                    }
                });
            });

            // add indicators for message grouping
            $scope.messageGroups.forEach(group => {
                for (let i = 0; i < group.messages.length; i++) {

                    const curMessage = group.messages[i]

                    // check if next message is from same person
                    if (i < group.messages.length - 1) {
                        if (group.messages[i + 1].author.id === curMessage.author.id) {
                            curMessage.nextIsSameAuthor = true
                        }
                    }

                    // check if last message is from same person
                    if (i > 0) {
                        if (group.messages[i - 1].author.id === curMessage.author.id) {
                            curMessage.prevIsSameAuthor = true
                        }
                    }

                    // set info for class (is directly copied as class)
                    // if me
                    if (curMessage.author.id === $rootScope.user.id) {
                        if (curMessage.nextIsSameAuthor && curMessage.prevIsSameAuthor) {
                            curMessage.bordersClass = "pmt__bubble-me-borders-middle"
                        } else if (curMessage.nextIsSameAuthor) {
                            curMessage.bordersClass = "pmt__bubble-me-borders-first"
                        } else if (curMessage.prevIsSameAuthor) {
                            curMessage.bordersClass = "pmt__bubble-me-borders-last"
                        }
                    } else {
                        if (curMessage.nextIsSameAuthor && curMessage.prevIsSameAuthor) {
                            curMessage.bordersClass = "pmt__bubble-other-borders-middle"
                        } else if (curMessage.nextIsSameAuthor) {
                            curMessage.bordersClass = "pmt__bubble-other-borders-first"
                        } else if (curMessage.prevIsSameAuthor) {
                            curMessage.bordersClass = "pmt__bubble-other-borders-last"
                        }
                    }
                }

            })

            scrollToBottom()
        }

        function deleteMessage(groupsIndex, group, index) {
            $scope.deleteModalIsOpen = true;
            messageToDelete = {groupsIndex: groupsIndex,group: group, index: index};
        }

        function onDeleteModalOk() {
            var groupsIndex = messageToDelete.groupsIndex,
                group = messageToDelete.group,
                index = messageToDelete.index;
            var message = $scope.messageGroups[groupsIndex].messages[index];
            pathmailService.deleteMessage(message.id).then(function(response) {
                $rootScope.handleResponse($scope, response, function() {
                    group = $scope.messageGroups[groupsIndex].messages;
                    group.splice(index, 1);
                    $scope.deleteModalIsOpen = false;
                    if($scope.messageGroups[groupsIndex].messages.length == 0) {
                        $scope.messageGroups.splice(groupsIndex, 1)
                    }
                });
            });
            messageToDelete = {groupsIndex: '', group: '', index: -1};
        }

        function onDeleteModalClose() {
            messageToDelete = {groupsIndex: '', group: '', index: -1};
            $scope.deleteModalIsOpen = false;
        }

        function openAttachment(type, src) {
            $scope.modalAttachmentType = type;
            $scope.modalAttachmentSrc = src;
            $scope.modalAttachmentIsOpened = true;
        }

        function getGroup(groups, name) {
                if (groups[name]) {
                    return groups[name];
                }
            var group = [];
            groups[name] = group;
            return group;
        }
        function showEditPanel(id) {
            $(id).toggle();
        }
        function uniqueName(name, array) {
            if($.inArray(name, array) === -1) {
                array.push(name);
            }
        }
        function isGroup(name, array) {
            for(var i= 0; i < array.length; i++) {
                if(array[i].groupName == name) return true;
            }
            return false;
        }

        // scrolls to bottom of thread
        const pageContentDivElement = document.getElementById("pageContentDivID");
        function scrollToBottom() {
            setTimeout(() => {
                pageContentDivElement.scrollTop = pageContentDivElement.scrollHeight;
            })
        }
    }

})();

