(function () {
    'use strict';

    angular.module('unleeshApp').controller('LoginController', LoginController);
    LoginController.$inject = ['$scope', '$rootScope', 'userService', '$location'];

    function LoginController($scope, $rootScope, userService, $location) {
        $scope.$parent.headerTitle = '';
        $scope.resetPasswordNotice = '';
        $scope.forgetModalIsOpened = false;
        $scope.noticeModalIsOpened = false;
        $scope.accessCodeVisible   = false;

        $scope.login = login;
        $scope.facebookLogin = facebookLogin;
        // $scope.resetPassword = resetPassword;
        $scope.signUp        = signUp;

        $scope.accessCode    = accessCode;
        $scope.closeNoticeModal = function() {
            $scope.noticeModalIsOpened = false;
        };
        $scope.closeForgetModal = function() {
            $scope.forgetModalIsOpened = false;
        };
        // $scope.okForgetModal = okForgetModal;

        $scope.signIn = {
            email: "",
            password: "",
            accessCode: ""
        }

        function login() {
            if (!$scope.emailIsValid || !$scope.passwordIsValid) {
                return;
            }

            if($scope.signIn.accessCode != false){
                console.log($scope.signIn.accessCode);
                $scope.signIn.accessCode = $scope.signIn.accessCode.toUpperCase();
            }

            $rootScope.isLoaded = true;
            userService.login($scope.signIn).then(function(response) {
                $rootScope.isLoaded = false;
                $scope.$emit('userAuthorized', response.user);
            }, function (error) {
                if (error.status === 404) {
                    Swal.fire({
                        icon: "error",
                        title: "Email not found",
                        text: `We couldn't find an account with that email`,
                        });
                } else if (error.status === 400) {
                    Swal.fire({
                        icon: "error",
                        title: "Incorrect password",
                        text: `Please retype your password`,
                        });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.responseJSON.status,
                        });
                }
                    
                $rootScope.isLoaded = false;
                error.isLogin = true;
                $scope.$emit('errorMessage', error);
            });
        }

        function facebookLogin() {
            FB.login(function (response) {
                if (response.status !== 'connected') {
                    return;
                }
                var userId = response.authResponse.userID,
                    accessToken = response.authResponse.accessToken;
                $rootScope.isLoaded = true;
                userService.facebookLogin(userId, accessToken).then(function(response) {
                    $rootScope.isLoaded = false;
                    response.user.fbLogin = true;
                    $scope.$emit('userAuthorized', response.user);
                }, function(error) {
                    $rootScope.isLoaded = false;
                    error.isLogin = true;
                    $scope.$emit('errorMessage', error);
                });
            }, {scope: 'public_profile,email'});
        }

        function accessCode() {
            $scope.accessCodeVisible = !$scope.accessCodeVisible;
            $scope.signIn.accessCode  = '';
        }


        // resetting your password doesn't take you to another screen. 
        // it expects you to type your password on this screen - then just sends an email.
        $scope.resetPassword = () => {
            if ($scope.signIn && $scope.signIn.email && $scope.signIn.email.length > 3) {
                Swal.fire({
                    icon: "warning",
                    title: "Reset password",
                    text: `Are you sure you want to reset your password?`,
                    focusCancel: true,
                    confirmButtonText: "Reset password",
                    showCancelButton: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                        okForgetModal();
                    }
                  });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Please enter your email",
                    text: `Enter your email first before resetting`,
                  });
            }
        }

        function signUp() {
            $location.path('/sign-up');
        }

        function okForgetModal() {
            $scope.forgetModalIsOpened = false;
            userService.passwordReset($scope.signIn.email).then(function(response) {
                $rootScope.handleResponse($scope, response, function() {
                    // $scope.resetPasswordNotice = 'Thanks. Check your email.';
                    // $scope.noticeModalIsOpened = true;
                    Swal.fire({
                        title: "Check your email",
                        text: `Thanks! Check your email for a reset form.`,
                      });
                });
            }, function(error) {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `Had an error while resetting password`,
                  });
            });
        }

        $scope.emailIsValid = null;
        $scope.showInvalidEmailMessage = false
        $scope.$watch("signIn.email", () => {
            if ($scope.signIn && $scope.signIn.email.length >= 3 && $scope.signIn.email.includes("@")) {
                $scope.emailIsValid = true;
            } else {
                $scope.emailIsValid = false;
            }

            if (!$scope.signIn || $scope.signIn.email.length === 0) {
                $scope.showInvalidEmailMessage = false
            } else {
                $scope.showInvalidEmailMessage = !$scope.emailIsValid
            }
        })

        $scope.passwordIsValid = null;
        $scope.showInvalidPasswordMessage = false
        $scope.$watch("signIn.password", () => {
            const password = $scope.signIn.password

            $scope.passwordIsValid = false;
            $scope.showInvalidPasswordMessage = false

            if (password.length >= 6) {
                $scope.passwordIsValid = true;
            } else if (password.length >= 1) {
                $scope.showInvalidPasswordMessage = true
            }
        })
    }

})();
