(function () {
  "use strict";

  angular
    .module("unleeshApp")
    // don't forget about the name normalization ... loadingSpinner = loading-spinner
    .directive("loadingSpinner", () => {
      return {
        template:
          '<div class="lds-ring-small"><div></div><div></div><div></div><div></div></div>',
      };
    })
    .directive("loadingSpinnerLarge", () => {
      return {
        template:
          '<div class="lds-ring"><div></div><div></div><div></div><div></div></div>',
      };
    });
})();
