(function() {
    'use strict';

    angular.module('unleeshApp').directive('fixedPanel', fixedPanel);
    fixedPanel.$inject = ['$window'];

    function fixedPanel($window) {
        var fixedBottom = angular.element('.fixed-panel-bottom');
        return {
            scope: {
                fromTop: '@'
            },
            link: link
        };

        function link(scope, element) {
            var win = angular.element($window);
            win.on('resize', onResize);
            win.on('scroll', onScroll);
            scope.$on('fixedPanelHeightChange', onFixedPanelHeightChange);

            onResize();
            onScroll();

            function onResize() {
                element.width(element.parent().width());
                recalculateBottomPadding();
            }

            function onScroll() {
                var top = (win.scrollTop() > scope.fromTop) ? 0 : scope.fromTop - win.scrollTop();
                element.css({top: top + 'px'});
                recalculateBottomPadding();
            }

            function recalculateBottomPadding() {
                fixedBottom.css({'padding-top': element.height()});
            }

            function onFixedPanelHeightChange() {
                recalculateBottomPadding();
            }
        }
    }
})();
