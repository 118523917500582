(function () {
  "use strict";

  angular.module("unleeshApp").directive("attachment", function () {
    return {
      restrict: "E",
      templateUrl: "sources/template/attachment.html",
      scope: {
        open: "=",
        type: "=",
        src: "=",
        comment: "=",
      },
    };
  });
})();
