(function() {
    'use strict';

    angular.module('unleeshApp').controller('UnsubscribeController', UnsubscribeController);
    UnsubscribeController.$inject = ['$scope', '$rootScope', 'US', '$routeParams', 'localStorageService', 'video', 'audio', 'getText', 'userService', 'pathService',  'postService', 'submitService', 'RAService'];

    function UnsubscribeController($scope, $rootScope, US, $routeParams, localStorage, video, audio, getText, userService, pathService, postService, submitService, RAService) {
        $scope.isCurrentUserProfile = (!$routeParams.id || $routeParams.id == $scope.user.id);
        var filterRAIds = $scope.isCurrentUserProfile ? (localStorage.get('filterRAIds') || []) : [];

        initDefaultScopeVariables();
        initCurrentUserProfile();

        $rootScope.isLoaded = true;
        userService.getUserProfile($scope.userId).then(function(response) {
            $rootScope.handleResponse($scope, response, function() {
                $scope.user = response.user;
                loadPaths(false, true);
            });
        });

        userService.getUserUnsubscribeSettings($scope.userId).then(function(response) {
            $rootScope.handleResponse($scope, response, function() {
                $scope.Items = response.settings;

                //Temporary wrap for on\off checkboxes
                angular.forEach($scope.Items, function(item, key) {
                    if ($scope.Items[key] == 'off' ){
                        $scope.Items[key] = false;
                    } else {
                        $scope.Items[key] = true;
                    }
                });

            });
        });

        // select all items
        $scope.selectAll = function() {
            angular.forEach($scope.Items, function(item, key) {
                $scope.Items[key] = $scope.allSelected;
            });
        };

        // Close popups if we change location.
        $rootScope.$on('$routeChangeStart', $routeChangeStartHandler);
        $scope.$on('filterModalTitleInitiated', filterModalTitleInitiatedHandler);

        function initDefaultScopeVariables() {
            $scope.$parent.headerTitle = 'Unsubscribe settings';
            $scope.isChangeShow = false;
            $scope.$parent.titleClass = [];
            $scope.filter = $scope.isCurrentUserProfile ? (localStorage.get('filter') || {}) : {};
            $scope.isLoaPage = false;
            $scope.filterTitle = 'None';
            $scope.pathsNotEmpty = false;
            $scope.activityIsLoading = false;
            $scope.quoteChanging = false;
            $scope.allSelected = false;
            $scope.submitUpdateSubscribe = submitUpdateSubscribe;
            $scope.currentPathStatus     = $rootScope.currentPath.emailNotifications;

            $scope.filterModalVariables = {
                isOpen: false,
                title: 'None',
                filteredPathIds: filterRAIds.slice()
            };
        }

        function initCurrentUserProfile() {
            $scope.userId = $routeParams.id || $scope.user.id;

            $scope.changeQuote = function(e) {
                e.preventDefault();
                $scope.isChangeShow = true;
            };

            $scope.clearQuote = function() {
                $scope.isChangeShow = false;
            };

            $scope.updateQuote = function() {
                $scope.formData = new FormData;

                if($scope.user.quote) {
                    $scope.formData.append('quote', $scope.user.quote);
                } else {
                    $scope.formData.append('quote', '');
                }
                $scope.quoteChanging = true;
                function editQuote(response) {
                    $rootScope.user = response.user;
                    $scope.clearQuote();
                    $scope.$emit('userEdit', response.user);
                }
                userService.editProfile($scope.formData, false).then(function(response) {
                    $rootScope.handleResponse($scope, response, editQuote);
                    $scope.quoteChanging = false;
                }, function(error) {
                    $scope.$emit('errorMessage', error);
                });
            };
        }

        function filterModalTitleInitiatedHandler(event, title) {
            $scope.filterTitle = title;
        }


        function submitUpdateSubscribe(){

            $scope.formData = new FormData;

            angular.forEach($scope.Items, function(item, key) {
                if ($scope.Items[key] == false ){
                    $scope.formData.append(key, 'off');
                } else {
                    $scope.formData.append(key, 'on');
                }
            });

            userService.editSubscribeSettings($scope.formData,true)
                .then(function(response) {
                    $rootScope.handleResponse($scope, response, responseEdit);
                    $rootScope.isLoaded = false;
                }, function(response) {
                    $rootScope.isLoaded = false;
                });

        }

        function $routeChangeStartHandler() {
            $scope.filterModalVariables.isOpened = false;
        }

        // added by Ben
        $scope.inEditMode = false;
        $scope.profile = $rootScope.user;
        $scope.profile.formValid = true

        $scope.toggleEditMode = () => {
            $scope.inEditMode = !$scope.inEditMode;
        }

        // handles submitting the new info for the profile
        $scope.submitUpdateProfile = function () {
            // init form data
            $scope.formData = new FormData;

            // Append data
            [
                "zip",
                "birthdate"
            ].forEach((data_id) => {
                $scope.formData.append(
                    data_id,
                    $scope.profile[data_id] ? $scope.profile[data_id] : ""
                );
            });

            $rootScope.isLoaded = true;
            userService.editProfile($scope.formData ,true)
                .then(function(response) {
                    $rootScope.handleResponse($scope, response, responseEdit);
                    $rootScope.isLoaded = false;
                }, function(response) {
                    $rootScope.isLoaded = false;
                });

        };

        function responseEdit(response) {
            // refresh the page
            setTimeout(() => {
                location.reload()
            }, 1000);
        }
    }
})();
