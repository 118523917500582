// removes <p></p> tags from a string
function removePTags(text) {
  return text.replace(/<\/?p>/gm, "");
}

// Preps a comment for displaying in a post
angular.module("unleeshApp").filter("prepComment", function () {
  return function (text) {
    text = String(text);

    // Remove any paragraph tags
    text = removePTags(text);

    // Replace string breaks with html breaks
    text = text.replace(/\r?\n|\r/gm, "<br>");

    return text;
  };
});
