(function() {
	'use strict';

	angular.module('unleeshApp').controller('PathMail', PathMailController);
	PathMailController.$inject = ['$scope', '$rootScope','pathmailService', '$window', '$location'];

	function PathMailController($scope, $rootScope, pathmailService, $window, $location) {
		$scope.$parent.headerTitle = 'Pathmail';
		$scope.noCurrentPathMessage = 'You haven\'t started any paths yet.<br />Choose your path at the Path Store.';
		// If we don't have current path then do nothing and show link to the path store.
		if (!$rootScope.currentPath || !$rootScope.currentPath.id) {
			return;
		}

		$rootScope.$emit('showPlus', '#/pathmail/threads/new-thread');

		var page = 1,
			limit = 10,
			lastMessageCreated = '',
			search = '',
			threadsLoad = true;

		$scope.search = '';
		$scope.searching = false;
		$scope.threadsAreLoading = true;

		$scope.threads = [];
		$scope.noThreads = true;
		$scope.stopLoadMails = false;
		$scope.onSearchSubmit = onSearchSubmit;
		$scope.loadMoreThreads = loadMoreThreads;

		getThreads('general');

		function getThreads(loading) {
			if (loading === 'general') {
				$rootScope.isLoaded = true;
			}
			else if(loading === 'local') {
				$scope.searching = true;
			}
			$scope.threadsAreLoading = true;
			var data = {
				page: page,
				limit: limit,
				lastMessageCreated: lastMessageCreated,
				search: search
			};
			pathmailService.getPathmailThreads(data).then(function(response) {
				$scope.stopLoadMails = false;
				$rootScope.handleResponse($scope, response, getThreadsCallback);
				$rootScope.isLoaded = $scope.searching = false;
			});
		}

		function getThreadsCallback(response) {
			if (response.threads.length) {
				threadsLoad = false;
			}
			if(threadsLoad) {
				$scope.noThreads = true;
				return;
			}
			$scope.noThreads = false;
			if(response.threads.length) {
				var lastThread = response.threads[response.threads.length - 1];
				var lastMessage = lastThread.messages[0];
				lastMessageCreated = lastMessage.created;
				$scope.threads = $scope.threads.concat(response.threads);
				$scope.threadsAreLoading = false;
			}

			setupFormattedThreads()
		}

		function onSearchSubmit() {
			$window.scrollTo(0, 0);
			page = 1;
			search = $scope.search;
			$scope.threads = [];
			getThreads('local');
		}

		function loadMoreThreads() {
			page++;
			$scope.stopLoadMails = true;
			getThreads();
		}

		// added by Ben

		/*
			There are 3 lists that hold thread info
				$scope.threads 			- original info, what is loaded from server
				$scope.formattedThreads - a converted list that makes formatting/displaying easy
				$scope.filteredThreads  - equal to formattedThreads until searching, then contains a filtered list
		*/

		// transforms original threads data structure to a nicely formatted structure for rendering
		$scope.formattedThreads = []
		function setupFormattedThreads() {
			$scope.formattedThreads = $scope.threads.map(thread => {
				let formatted_thread = {}

				const message = thread.messages[0]

				// set photo or photo initials
				if (message.author.photo && message.author.photo.length > 0) {
					formatted_thread.photo = message.author.photo
				} else {
                    let firstInitial = message.author.firstName ? message.author.firstName[0] : ""
                    let lastInitial  = message.author.lastName ? message.author.lastName[0] : ""
                    formatted_thread.initials = `${firstInitial}${lastInitial}`.toUpperCase()
				}

				// setup name
				formatted_thread.name = `${message.author.firstName} ${message.author.lastName}` 

				// setup time
				formatted_thread.time = message.created

				// setup path name
				if (thread.paths.length > 1) {
					formatted_thread.path = thread.paths.map(path => path.title).join(', ')
				} else {
                    if(thread.paths[0]){
					    formatted_thread.path = thread.paths[0].title
                    }
				}

				// setup sent to
				if (thread.toAll === true) {
					formatted_thread.sent_to = "Sent to: Entire Path"
				} else if (thread.participants && thread.participants.length > 1) {
					// the +1 is because apparently it doesn't include yourself in the list of participants
					formatted_thread.sent_to = `Sent to: ${thread.participants.length + 1} people`

					// setup sent to as clickable that will show who's going
					formatted_thread.sentToIsClickable = true;
					formatted_thread.participants = thread.participants
				} else {
					formatted_thread.sent_to = `Sent to: Just you`
				}

				// setup message
				formatted_thread.message = message.message

				// This is done because of a server/API bug and should be removed when that bug is fixed 
				// For pathmail previews that contain links to events, the server was sending the incorrect link structure. 
				// 		What was sent:			<a href="/event/325/">
				//		What it should be:		<a href="#/event/view/325/">
				// This regex fixes this incorrect link. Note: This doesn't happen within threads for some reason.
				const linkMatchRegex = /<a href="\/event\/\d+?\/">/i
				if (linkMatchRegex.test(formatted_thread.message)) {
					formatted_thread.message = formatted_thread.message.replace(linkMatchRegex, string => {
						return string.replace("/event/", "#/event/view/")
					})
				}

				// add id
				formatted_thread.id = thread.id

				return formatted_thread
			})

			resetSearch()
		}
		$scope.$watch("threads", setupFormattedThreads)

		// when clicking on a thread
		$scope.goToThread = (id) => {
			$location.path(`/pathmail/thread/${id}`)
		}

		// callback for clicking "sent to" -- only applies for when sent to groups (eg sent to 3 people) 
		$scope.showSentToParticipants = false
		$scope.toggleSentToParticipants = (participants) => {
			if (participants) {
				$scope.sentToParticipants = participants
			}
			
			$scope.showSentToParticipants = !$scope.showSentToParticipants
		}


		function resetSearch() {
			$scope.search = ""
			$scope.filteredThreads = $scope.formattedThreads.slice()
		}

		// searching has two functions -- filtering the immediately shown, and then searching for older threads
		resetSearch()
		$scope.$watch("search", () => {
			// If search is empty, show all threads 
            if (!$scope.search || $scope.search.length === 0) {
				resetSearch()
                return
            }

			const searchTerm = $scope.search.toLowerCase()

			$scope.filteredThreads = $scope.formattedThreads.filter(thread => {
				
				// match on any part of their name
				if (thread.name.toLowerCase().includes(searchTerm)) {
					return true
				}

				// match on the name of the path
				if (thread.path.toLowerCase().includes(searchTerm)) {
					return true
				}

				// match on the message
				if (thread.message.toLowerCase().includes(searchTerm)) {
					return true
				}

				// no match, don't include
				return false
			})
		})
	}

})();
