(function(){
    'use strict';

    angular.module('unleeshApp').filter('parseLink', parseLink);

    function parseLink() {
        return function (value) {
            if (!value) return '';
            value = value.replace(/href="\/event\//g,'href="#/event/view/');
            return value;
        };
    }

})();