'use strict';

angular.module('unleeshApp').service('formatDate', [ '$rootScope', '$filter',
	function($rootScope, $filter) {
		var $this = this;

		$this.date = function() {
			return $filter('date')
		};

		//suffix

		$this.getTodaySuffix = function(){
			return 'today';
		};

		$this.getTomorrowSuffix = function(){
			return 'tomorrow';
		};

		$this.getCurrentDateTime = function() {
			return new Date();
		};

		$this.getCurrentDate = function() {
			var $this = this;

			return $filter('date')($this.getCurrentDateTime(), 'yyyy-MM-dd')
		};
		$this.getCurrentTime = function() {
			return $filter('date')($this.getCurrentDateTime(), 'HH:mm')
		};
		/**
		 *
		 * @param date
		 * @returns {{}}
		 */
		$this.formatDate = function(date) {
			return {date:$filter('date')(date,'yyyy-MM-dd'), hours: $filter('date')(date,'hh'), minutes: $filter('date')(date,'mm'), marker: $filter('date')(date,'a') };
		};

		$this.setISO = function(date) {
			return $filter('date')(date,'yyyy-MM-dd HH:mm:ssZ');
		};

		/**
		 * Get data to display
		 * @param date
		 * @returns format data "today d, y h:mm a" or "tomorrow d, y h:mm a" or "MMM d, y h:mm a"
		 */
		$this.getDateTodayOrTomorrow = function(date) {
			var currentDate = {
				day: $filter('date')($this.getCurrentDateTime(),'dd'),
				monday: $filter('date')($this.getCurrentDateTime(),'MM'),
				year: $filter('date')($this.getCurrentDateTime(),'yyyy')
			};
			var watchDate = {
				day: $filter('date')(date,'dd'),
				monday: $filter('date')(date,'MM'),
				year: $filter('date')(date,'yyyy')
			};
			if(currentDate.year === watchDate.year) {
				if(currentDate.monday === watchDate.monday) {
					if(currentDate.day === watchDate.day) {
						return $this.getTodaySuffix() + ' ' + $filter('date')(date, 'h:mm a');
					} else {
						if(currentDate.day - watchDate.day == -1) {
							return $this.getTomorrowSuffix() + ' ' + $filter('date')(date, 'h:mm a');
						} else {
							return 	$filter('date')(date, 'MMMM d h:mm a')
						}
					}
				} else {
					return 	$filter('date')(date, 'MMMM d h:mm a')
				}
			} else {
				return 	$filter('date')(date, 'y MMMM d h:mm a')
			}
		};

		/**
		 * return true/false if left one hour
		 * @param date
		 * @returns {boolean}
		 */
		$this.isDifferenceHour = function(date) {
			var currentDate = {
				day: $filter('date')($this.getCurrentDateTime(),'dd'),
				monday: $filter('date')($this.getCurrentDateTime(),'MM'),
				year: $filter('date')($this.getCurrentDateTime(),'yyyy'),
				hours: $filter('date')($this.getCurrentDateTime(),'h')
			};
			var watchDate = {
				day: $filter('date')(date,'dd'),
				monday: $filter('date')(date,'MM'),
				year: $filter('date')(date,'yyyy'),
				hours: $filter('date')(date,'h')
			};
			if (currentDate.year === watchDate.year) {
				if (currentDate.monday === watchDate.monday) {
					if (currentDate.day === watchDate.day) {
						if (((currentDate.hours - watchDate.hours) >= -1) && (currentDate.hours - watchDate.hours) <= 0)  {
							//console.log('differed',currentDate.hours - watchDate.hours);
							return true;
						} else {
							return false;
						}
					} else {
						return 	false;
					}
				} else {
					return 	false;
				}
			} else {
				return false;
			}
		};

		/**
		 *
		 * @param str
		 * @returns {Date}
		 */
		$this.parseDate = function(str) {
			var mdy = str.split('-');
			return new Date(mdy[0], mdy[1]-1, mdy[2]);
		};

		/**
		 *
		 * Diff day
		 * @param first
		 * @param second
		 * @returns {number}
		 */
		$this.dayDiff = function(first, second) {
			return (first - second)/(1000*60*60*24);
		};

		$this.parseTime = function(str) {
			var hm = str.split(':');
			return new Date(0, 0, 0, hm[0], hm[1]);
		};

		$this.diffTime = function(fisrt, second) {
			fisrt = this.parseTime(fisrt);
			second = this.parseTime(second);
			return (second - fisrt)/(1000*60*60);
		}

	}
]);
