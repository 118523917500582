(function () {
    'use strict';

    angular.module('unleeshApp').controller('PathMatesController', PathMatesController);
    PathMatesController.$inject = ['$scope', 'pathService', 'userService', '$rootScope', '$q'];

    function PathMatesController($scope, pathService, userService, $rootScope, $q) {
        $scope.$parent.headerTitle = 'Pathmates';
        $scope.noCurrentPathMessage = 'You currently have no Pathmates.<br />Join a Path and find some friends!';
        // If we don't have current path then do nothing and show link to the path store.
        if (!$rootScope.currentPath || !$rootScope.currentPath.id) {
            return;
        }

        var prevFilterIds = [];

        $scope.users = [];
        $scope.filteredUsers = [];
        $scope.filterTitle = 'None';
        $scope.filter = {currentPath: {}, inProgressPaths: [], completedPaths: []};
        $scope.pathsNotEmpty = true;
        $scope.usersAreLoading = false;
        // $scope.userName = '';
        $scope.filterModalVariables = {
            isOpened: false,
            title: 'None',
            filteredPathIds: []
        };

        $scope.filterApplyHandler = filterApplyHandler;
        $scope.openFilterModal = openFilterModal;
        $scope.closeFilterModal = closeFilterModal;

        $scope.$on('filterModalTitleInitiated', function (event, title) {
            $scope.filterTitle = title;
        });

        $rootScope.isLoaded = true;
        $q.all([
            pathService.getPathsStarted_NEW(),
            userService.getListUsers([])
        ]).then(function(response) {
            pathsResponseHandler(response[0]);
            userResponseHandler(response[1]);
            $rootScope.isLoaded = false;
        });

        function closeFilterModal() {
            $scope.filterModalVariables.filteredPathIds = prevFilterIds.slice();
            $scope.filterModalVariables.isOpened = false;
            $scope.filterModalVariables.title = $scope.filterTitle;
        }

        function filterApplyHandler() {
            if (!$scope.filterModalVariables.filteredPathIds.length) {
                $scope.$emit('errorMessage', {statusText: 'You must select at least one path'});
                return;
            }
            // If we have same paths then don't do anything, only close popup filter.
            if (!$scope.filterModalVariables.filteredPathIds.diff(prevFilterIds).length && !prevFilterIds.diff($scope.filterModalVariables.filteredPathIds).length) {
                $scope.filterModalVariables.isOpened = false;
                return;
            }
            loadUsers($scope.filterModalVariables.filteredPathIds);
            $scope.filterTitle = $scope.filterModalVariables.title;
            $scope.filterModalVariables.isOpened = false;
            prevFilterIds = $scope.filterModalVariables.filteredPathIds.slice();
        }

        function openFilterModal() {
            $rootScope.isLoaded = true;
            pathService.getPathsStarted_NEW().then(function (response) {
                $rootScope.handleResponse($scope, response, function(response) {
                    $scope.filterModalVariables.isOpened = true;
                    pathsResponseHandler(response);
                    $rootScope.isLoaded = false;
                });
            });
        }

        function pathsResponseHandler(response) {
            if(response.paths !== undefined){
                var paths = {
                    'currentPath' : [],
                    'inProgressPaths': [],
                    'completedPaths': []
                };
                response.paths.forEach(function(item, index){
                    if(item.status == 1){
                        paths.currentPath = item;
                    } else if(item.status == 0) {
                        paths.inProgressPaths.push(item);
                    } else if(item.status == 2 ) {
                        paths.completedPaths.push(item);
                    }
                });
                $scope.path = paths;
            } else {
            }

            $scope.filter = paths;
            $scope.pathsNotEmpty = true;
        }

        function loadUsers(pathsIds) {
            $rootScope.isLoaded = true;
            $scope.usersAreLoading = true;
            userService.getListUsers(pathsIds).then(function (response) {
                $rootScope.handleResponse($scope, response, userResponseHandler);
                $rootScope.isLoaded = false;
            });
        }

        function userResponseHandler(response) {
            $scope.usersAreLoading = false;

            $scope.users = response.users;

            // build last activity + about content
            $scope.users.forEach(user => {
                // build user.lastActivity message
                if (!user.lastUserAction) {
                    user.lastActivity = null
                } else {
                    user.lastActivity = `Posted a ${user.lastUserAction.attachmentType} to ${user.lastUserAction.action.title} and earned ${user.lastUserAction.action.points} ${user.lastUserAction.action.points !== 1 ? 'points' : 'point'}`
                }

                // build about message
                if (user.profession && user.company) {
                    user.about = `${user.profession} @ ${user.company}`
                } else if (user.profession) {
                    user.about = user.profession
                } else if (user.company) {
                    user.about = user.company
                } else {
                    user.about = null
                }

                // attempt to do activity linking
                if (user.lastActivity && user.lastUserAction.action.id && $rootScope.stepLookup) {
                    const stepID = user.lastUserAction.action.id
                    const stepInfo = $rootScope.stepLookup[stepID];
                    if (stepInfo) {
                        user.lastActivityLink = `#/path/step/${stepInfo.phaseID}&${stepInfo.levelID}&${stepID}`;
                    } else {
                        user.lastActivityLink = null
                    }
                } else {
                    user.lastActivityLink = null
                }
            })

            $scope.filteredUsers = $scope.users.slice()
        }

        // added by Ben

        $scope.searchInput = ''
        $scope.$watch("searchInput", () => {
            // If search is empty, show all users 
            if (!$scope.searchInput || $scope.searchInput.length === 0) {
                $scope.filteredUsers = $scope.users.slice()
                return
            }

            const searchTerm = $scope.searchInput.toLowerCase()
            $scope.filteredUsers = $scope.users.filter(user => {
                const firstName = user.firstName ? user.firstName.toLowerCase() : "";
                const lastName = user.lastName ? user.lastName.toLowerCase() : "";

                // match on any part of their name
                if (`${firstName} ${lastName}`.includes(searchTerm)) {
                    return true
                }

                // no match, don't include
                return false
            })
        })
    }

})();
