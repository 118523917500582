(function() {
    'use strict';

    angular.module('unleeshApp').directive('pathFilterModal', function() {
        return {
            scope: {
                paths: '=',
                variables: '=',
                okHandler: '=',
                cancelHandler: '='
            },
            templateUrl: 'sources/template/path-filter-modal.html',
            controller: modalController
        };
    });

    function modalController($scope) {
        $scope.allPathsChecked = false;

        $scope.selectFilter = selectFilter;
        $scope.toggleCheckbox = toggleCheckbox;

        $scope.$watch('paths', pathsWatcherHandler);

        function selectFilter(type, index, id) {
            var newState = false;
            if(type == 'current') {
                newState = !$scope.paths.currentPath.selected;
                $scope.paths.currentPath.selected = newState;
            }
            else if(type == 'available') {
                newState = !$scope.paths.inProgressPaths[index].selected;
                $scope.paths.inProgressPaths[index].selected = newState;
            }
            else if(type == 'completed') {
                newState = !$scope.paths.completedPaths[index].selected;
                $scope.paths.completedPaths[index].selected = newState;
            }

            if (newState == false) {
                $scope.allPathSelected = false;
            }
            else {
                $scope.allPathSelected = isAllPathsAreSelected($scope.paths);
            }
            processFilteredByState(id, newState);

            if ($scope.allPathSelected) {
                $scope.variables.title = 'All Paths';
            }
            else {
                $scope.variables.title = determineFilterTitle();
            }
        }

        function toggleCheckbox(e) {
            e.preventDefault();
            $scope.allPathSelected = !$scope.allPathSelected;
            $scope.variables.filteredPathIds = [];
            angular.forEach(getAllPaths($scope.paths), function(path) {
                path.selected = $scope.allPathSelected;
                processFilteredByState(path.id, path.selected)
            });
            if ($scope.allPathSelected) {
                $scope.variables.title = 'All Paths';
            }
            else {
                $scope.variables.title = 'None';
            }
        }

        function processFilteredByState(id, state) {
            if (state) {
                $scope.variables.filteredPathIds.push(id);
            }
            else {
                $scope.variables.filteredPathIds.splice($scope.variables.filteredPathIds.indexOf(id), 1);
            }
        }

        function processPaths(paths) {
            var allPaths = getAllPaths(paths);
            if (!allPaths.length) {
                $scope.paths = paths;
                return;
            }
            // If we complete path or it just became empty then we need to select all paths.
            if ((!paths.currentPath || !paths.currentPath.id)
            && ((!$scope.variables.filteredPathIds.length) || ($scope.variables.filteredPathIds.length == 1 && $scope.variables.filteredPathIds[0] == paths.currentPath.id))) {
                $scope.variables.filteredPathIds = [];
                angular.forEach(allPaths, function(path) {
                    if (!path.id) {
                        return true;
                    }
                    path.selected = true;
                    $scope.variables.filteredPathIds.push(path.id);
                });
            }

            if ($scope.variables.filteredPathIds.length) {
                var selectedCount = 0, notExisted;
                angular.forEach($scope.variables.filteredPathIds, function(pathId, index) {
                    notExisted = true;
                    angular.forEach(allPaths, function(path) {
                        if (path.id == pathId) {
                            path.selected = true;
                            notExisted = false;
                            selectedCount++;
                            return false;
                        }
                    });
                });
                $scope.allPathSelected = (selectedCount == allPaths.length);
            }
            else {
                if(paths.currentPath) {
                    paths.currentPath.selected = true;
                    $scope.variables.filteredPathIds.push(paths.currentPath.id);
                }
                if (!paths.inProgressPaths.length && !paths.completedPaths.length) {
                    $scope.allPathSelected = true;
                }
            }
            $scope.paths = paths;
        }

        function pathsWatcherHandler(paths) {
            if (!paths.inProgressPaths) {
                return;
            }
            processPaths(paths);
            $scope.variables.title = determineFilterTitle();
            $scope.$emit('filterModalTitleInitiated', $scope.variables.title);
        }

        function getAllPaths(paths) {
            var allPaths = paths.inProgressPaths.concat(paths.completedPaths);
            if(paths.currentPath && paths.currentPath.id) {
                allPaths.push(paths.currentPath);
            }
            return allPaths;
        }

        function calcFilteredCount(paths) {
            var filteredCount = 0;
            angular.forEach(paths, function(path) {
                if (path.selected) {
                    filteredCount++;
                }
            });
            return filteredCount;
        }

        function determineFilterTitle() {
            var allPaths = getAllPaths($scope.paths);

            if ($scope.paths.currentPath === undefined && !allPaths.length) {
                return 'Current Path';
            }
            var filteredCount = calcFilteredCount(allPaths);
            if (filteredCount == 0) {
                return 'None';
            }

            if (filteredCount == 1 && ($scope.paths.currentPath.id !== undefined && $scope.paths.currentPath.selected)) {
                return 'Current Path';
            }
            if (allPaths.length == filteredCount) {
                return 'All Paths';
            }
            if (filteredCount == 1) {
                return '1 Path';
            }
            return filteredCount + ' Paths';
        }

        function isAllPathsAreSelected() {
            if ($scope.paths.currentPath && $scope.paths.currentPath.id && !$scope.paths.currentPath.selected) {
                return false;
            }
            var out = true;
            angular.forEach($scope.paths.inProgressPaths, function(inProgressPath) {
                if (!inProgressPath.selected) {
                    out = false;
                    return false;
                }
            });
            if (!out) {
                return false;
            }
            angular.forEach($scope.paths.completedPaths, function(completedPath) {
                if (!completedPath.selected) {
                    out = false;
                    return false;
                }
            });
            return out;
        }
    }

})();
