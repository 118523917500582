(function () {
  "use strict";

  angular
    .module("unleeshApp")
    .controller("AddEventController", AddEventController);
  AddEventController.$inject = [
    "$scope",
    "$rootScope",
    "$routeParams",
    "$timeout",
    "$location",
    "formatDate",
    "eventService",
    "pathService",
  ];

  function AddEventController(
    $scope,
    $rootScope,
    $routeParams,
    $timeout,
    $location,
    formatDate,
    eventService,
    pathService
  ) {
    if ($location.$$url.includes("event/add")) {
      $scope.mode = "add";
    } else if ($location.$$url.includes("event/edit")) {
      $scope.mode = "edit";
    } else {
      throw new Error(
        `ERROR -- Unable to determine whether we're adding a new event or editing an existing one inside of add-event controller. Update path recognition!`
      );
    }

    var oldSelectUser = [],
      oldCount = null;

    $rootScope.$emit("showBackLink");

    $scope.event = {
      start_time: "AM",
      end_time: "AM",
    };
    $scope.$parent.headerTitle = "Add Breakout";
    $scope.availableUsers = null;
    $scope.countInvite = 0;
    $scope.startDateChange = {
      minDate: 0,
      changeMonth: true,
      changeYear: true,
      yearRange: "2014:2040",
    };
    $scope.endDateChange = {
      minDate: 0,
      //			maxDate:-1,
      changeMonth: true,
      changeYear: true,
      yearRange: "2014:2040",
    };
    $scope.changeFocusLocation = false;
    $scope.isSelectPath = false;
    $scope.isSelectUser = false;
    $scope.isSelectAllUsers = true;

    $scope.openLocation = openLocation;
    $scope.openSelectPath = openSelectPath;
    $scope.selectAllUsers = selectAllUsers;
    $scope.selectPath = selectPath;
    $scope.selectUsers = selectUsers;
    $scope.handleOk = handleOk;
    $scope.openSelectUser = openSelectUser;
    $scope.handleSelectUser = handleSelectUser;
    $scope.clearPath = clearPath;

    $scope.$watch("countInvite", countInviteWatcher);
    $rootScope.$on("$routeChangeStart", onRouteChange);

    $rootScope.isLoaded = true;

    pathService.getPathsStarted_NEW().then(
      function (data) {
        $scope.availablePath = data.paths;
        $rootScope.isLoaded = false;
      },
      function (error) {
        $rootScope.isLoaded = false;
        $scope.$emit("errorMessage", error);
      }
    );

    function openLocation() {
      $scope.event.location = $scope.event.location || "";
      if ($scope.event.location == "") {
        $scope.changeFocusLocation = true;
      }
    }

    function openSelectPath() {
      $scope.isSelectPath = !$scope.isSelectPath;
    }

    function selectAllUsers() {
      if (!$scope.availableUsers) {
        return;
      }
      angular.forEach($scope.availableUsers, function (val) {
        if (typeof val.selected == "undefined") {
          val.selected = true;
          $scope.isSelectAllUsers = true;
        } else {
          val.selected = $scope.isSelectAllUsers;
        }
      });
      if ($scope.isSelectAllUsers) {
        $scope.countInvite = $scope.availableUsers.length;
      } else {
        $scope.countInvite = 0;
      }
    }

    function selectPath(index) {
      angular.forEach($scope.availablePath, function (val) {
        val.selected = false;
      });
      $scope.availablePath[index].selected = true;
      $scope.event.path = $scope.availablePath[index];
      eventService
        .getUsersEvent({
          token: $rootScope.user.token,
          path: $scope.event.path.id,
        })
        .then(function (response) {
          $rootScope.handleResponse($scope, response, function (response) {
            $scope.availableUsers = response.users;
            $scope.selectAllUsers();
          });
        });
    }

    function selectUsers(index) {
      $scope.countInvite = $scope.availableUsers[index].selected
        ? $scope.countInvite - 1
        : $scope.countInvite + 1;
      $scope.availableUsers[index].selected = $scope.availableUsers[index]
        .selected
        ? false
        : true;
      $scope.event.users = $scope.availableUsers.filter(function (user) {
        return user.selected == true;
      });
    }

    // on close select path modal
    function handleOk() {
      $scope.isSelectPath = false;
      $scope.changeFocusLocation = false;
      $scope.isSelectUser = false;
    }

    function openSelectUser(e) {
      e.preventDefault();
      $scope.isSelectUser = true;
      oldSelectUser = angular.copy($scope.availableUsers);
      oldCount = angular.copy($scope.countInvite);
    }

    // on select users modal confirm/done
    function handleSelectUser() {
      if ($scope.event.path) {
        if ($scope.countInvite != 0) {
          $scope.isSelectUser = false;
        } else {
          $scope.$emit("errorMessage", {
            responseText: "",
            statusText: "Select someone",
          });
        }
      }

      $scope.isSelectUser = false;
    }

    function clearPath() {
      $scope.event.path = "undefined";
      $scope.availablePath.forEach(function (val) {
        val.selected = false;
      });
      $scope.availableUsers = null;
      $scope.statusPeople = { path: null, isGetUser: false };
    }

    function onRouteChange() {
      //if not login redirect page "login"
      $scope.changeFocusLocation = false;
      $scope.isSelectPath = false;
      $scope.isSelectUser = false;
      $scope.isSelectAllUsers = false;
    }

    function countInviteWatcher(count) {
      if (count == null || !$scope.availableUsers) {
        return;
      }
      if (count >= 0 && count != $scope.availableUsers.length) {
        $scope.isSelectAllUsers = true;
      }
      if (count == $scope.availableUsers.length) {
        $scope.isSelectAllUsers = false;
      }
    }

    // ---------------------------------
    // added by Ben

    $scope.$watch("event.start_date", startDateWatcher);
    $scope.$watch("event.end_date", () => removeInvalidMessage("end_date"));
    $scope.$watch("event.path", () => removeInvalidMessage("path"));
    $scope.$watch("event.title", () => removeInvalidMessage("title"));
    $scope.$watch("event.address", () => removeInvalidMessage("address"));

    $scope.showInvalidMessage = {
      title: false,
      start_date: false,
      end_date: false,
      path: false,
      address: false,
    };

    $scope.removeImage = () => {
      $scope.event.files = null;
      $scope.event.icon = null;
    };

    const start_picker = flatpickr("#flatpickr-start", {
      enableTime: true,
      altInput: true,
      altFormat: "l M j, Y  h:i K",
      dateFormat: "l M j, Y  h:i K",
      minDate: "today",
      maxDate: new Date().fp_incr(365), // 365 days from now
      defaultHour: 9,
    });

    const end_picker = flatpickr("#flatpickr-end", {
      enableTime: true,
      altInput: true,
      altFormat: "l M j, Y  h:i K",
      dateFormat: "l M j, Y  h:i K",
      minDate: "today",
      maxDate: new Date().fp_incr(365), // 365 days from now
      defaultHour: new Date().getHours() + 2,
    });

    // if we're editing an existing event, go load it!
    if ($scope.mode === "edit") {
      $rootScope.isLoaded = true;
      eventService.getEvent($routeParams.id, $rootScope.user.token).then(
        function (response) {
          if (response.statusCode == 200) {
            $scope.event = response.event;

            // set flatpickr instances to dates
            start_picker.setDate($scope.event.startDate, false, "Y-m-d h:i:s");
            end_picker.setDate($scope.event.endDate, false, "Y-m-d h:i:s");

            // here mostly just for form validation (submitting draws directly from the pickr instance)
            $scope.event.start_date = $scope.event.startDate;
            $scope.event.end_date = $scope.event.endDate;

            eventService
              .getUsersEventInvitees({
                id: $scope.event.id,
                token: $rootScope.user.token,
              })
              .then(
                function (response) {
                  if (response.statusCode == 200) {
                    $scope.inviteesUsers = response.users;
                    $scope.countInvite = $scope.inviteesUsers.length - 1;
                  } else if (response.statusCode == 401) {
                    $scope.$emit("userLogout", response);
                  }
                  $rootScope.isLoaded = false;
                },
                function (response) {
                  $rootScope.isLoaded = false;
                }
              );
          } else if (response.statusCode == 401) {
            $rootScope.isLoaded = false;
            $scope.$emit("userLogout", response);
          }

          // load the available users so that selected pathmates fills in
          eventService
            .getUsersEvent({
              token: $rootScope.user.token,
              path: $scope.event.path.id,
            })
            .then(function (response) {
              $rootScope.handleResponse($scope, response, function (response) {
                $scope.availableUsers = response.users;
                $scope.selectAllUsers();
              });
            });
        },
        function (response) {
          $rootScope.isLoaded = false;
        }
      );
    }

    // when the start date is changed, set the minDate on the endDate to the startDate
    function startDateWatcher() {
      const start_date = start_picker.selectedDates[0];
      end_picker.set("minDate", start_date);
      $scope.showInvalidMessage.start_date = false;
    }

    function removeInvalidMessage(key) {
      $scope.showInvalidMessage[key] = false;
    }

    function validateForm() {
      $scope.formIsValid = true;

      if (!$scope.event.title) {
        $scope.showInvalidMessage.title = "Please enter a title";
        $scope.formIsValid = false;
      } else {
        $scope.showInvalidMessage.title = false;
      }

      if (!$scope.event.address) {
        $scope.showInvalidMessage.address = "Please enter a location";
        $scope.formIsValid = false;
      } else {
        $scope.showInvalidMessage.address = false;
      }

      if (!$scope.event.start_date) {
        $scope.showInvalidMessage.start_date = "Please enter a start date";
        $scope.formIsValid = false;
      } else {
        $scope.showInvalidMessage.start_date = false;
      }

      if (!$scope.event.end_date) {
        $scope.showInvalidMessage.end_date = "Please enter an end date";
        $scope.formIsValid = false;
      } else {
        $scope.showInvalidMessage.end_date = false;
      }

      if (!$scope.event.path) {
        $scope.showInvalidMessage.path = "Please select a path";
        $scope.formIsValid = false;
      } else {
        $scope.showInvalidMessage.path = false;
      }

      return $scope.formIsValid;
    }

    $scope.formIsSubmitting = false;
    $scope.submitForm = () => {
      // don't repeat a submission if we're already submitting
      if ($scope.formIsSubmitting) {
        return false;
      }

      $scope.eventPost = {};
      $scope.formData = new FormData();

      if (!validateForm()) {
        return false;
      }

      // Add title
      $scope.eventPost.title = $scope.event.title;
      $scope.formData.append("title", $scope.event.title);

      // Add description
      if ($scope.event.description) {
        $scope.eventPost.description = $scope.event.description;
        $scope.formData.append("description", $scope.event.description);
      }

      // Add path
      if ($scope.event.path) {
        $scope.eventPost.path = $scope.event.path.id;
        $scope.formData.append("path", $scope.event.path.id);
      }

      // Add event
      if ($scope.event.address) {
        $scope.eventPost.address = $scope.event.address;
        $scope.formData.append("address", $scope.event.address);
      }

      // Add hmc
      if ($scope.event.hmc_audio) {
          $scope.eventPost.hmc_audio = $scope.event.hmc_audio;
          $scope.formData.append("hmc_audio", $scope.event.hmc_audio);
      }

      // get start and end times
      // we don't get these from the ng-model'd $scope.event, but rather directly from the element

      if(start_picker.selectedDates[0]){
          var start_date = formatDate.setISO(
            start_picker.selectedDates[0].toISOString()
          );
      } else {
          Swal.fire({
              icon: "error",
              title: "Bad start date",
              text: `Select correct start date`,
          });
      }
      if(end_picker.selectedDates[0]){
          var end_date = formatDate.setISO(
            end_picker.selectedDates[0].toISOString()
          );
      } else {
          Swal.fire({
              icon: "error",
              title: "Bad end date",
              text: `Select correct start date`,
          });
      }

      // todo, date validation
      // start time
      $scope.eventPost.start_date = start_date;
      $scope.formData.append("start_date", start_date);

      // end time
      $scope.eventPost.end_date = end_date;
      $scope.formData.append("end_date", end_date);

      // Add attendees
      if ($scope.event.users && $scope.event.users.length > 0) {
        $scope.eventPost.invitees = $scope.event.users.map(function (user) {
          return user.id;
        });

        $scope.event.users.forEach((user, index) => {
          $scope.formData.append(`invitees[${index}]`, user.id);
        });
      }

      // Add icon/photo
      if ($scope.event.files && $scope.event.files != null) {
        $scope.eventPost.file =
          $scope.event.files[$scope.event.files.length - 1];
        $scope.formData.append(
          "file",
          $scope.eventPost.file,
          $scope.eventPost.file.name
        );
      }

      // submit form!
      $rootScope.isLoaded = true;
      $scope.formIsSubmitting = true;

      if ($scope.mode === "add") {
        if ($scope.eventPost.file) {
          eventService
            .createEventFile($scope.formData, $rootScope.user.token)
            .then(createEventResponseHandler)
            .catch(createEventResponseErrorHandler);
        } else {
          eventService
            .createEvent($scope.eventPost, $rootScope.user.token)
            .then(createEventResponseHandler)
            .catch(createEventResponseErrorHandler);
        }
      } else if ($scope.mode === "edit") {
        if ($scope.eventPost.file) {
          eventService
            .editEventFile(
              $scope.formData,
              $rootScope.user.token,
              $scope.event.id
            )
            .then(createEventResponseHandler)
            .catch(createEventResponseErrorHandler);
        } else {
          eventService
            .editEvent($scope.eventPost, $rootScope.user.token, $scope.event.id)
            .then(createEventResponseHandler)
            .catch(createEventResponseErrorHandler);
        }
      }
    };

    // handler for create/edit event responses
    function createEventResponseHandler(response) {
      if ([200, 201].includes(response.statusCode)) {
        $location.path("/events");
      } else if (response.statusCode == 401) {
        $scope.$emit("userLogout", response);
      } else if (response.statusCode == 403) {
        //add message error;
      }
      $rootScope.isLoaded = false;
      $scope.formIsSubmitting = false;
    }
    function createEventResponseErrorHandler(error) {
      $rootScope.isLoaded = false;
      $scope.formIsSubmitting = false;
      $scope.$emit("errorMessage", error);
    }

    // show delete event confirmation
    $scope.showConfirmDeleteEvent = () => {
      Swal.fire({
        icon: "warning",
        title: "Delete event",
        text: `Are you sure you want to delete this event?`,
        focusCancel: true,
        confirmButtonText: "Delete",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          $scope.onDeleteEvent();
        }
      });
    };

    $scope.onDeleteEvent = function () {
      $rootScope.isLoaded = true;
      eventService.deleteEvent($scope.event.id).then(
        function (response) {
          $scope.deleteEvent = false;
          $rootScope.handleResponse($scope, response, () => {
            $location.path("/events");
          });
          $rootScope.isLoaded = false;
        },
        function (error) {
          $rootScope.isLoaded = false;
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Had an error while deleting event`,
          });
        }
      );
    };
  }
})();
