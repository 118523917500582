(function(){
    'use strict';

    angular.module('unleeshApp').factory('getText', getTextToFile);

    function getTextToFile() {
        return {
            get: function(file, callback) {
                if(!file) return;

                try {
                    const response = fetch(file);
                    const data = response.text();
                    callback(data);
                } catch (err) {
                    console.error(err);
                }

                /*var rawFile = new XMLHttpRequest();
                rawFile.open("GET", file, false);
                rawFile.onreadystatechange = function ()
                {
                    if(rawFile.readyState === 4)
                    {
                        if(rawFile.status === 200 || rawFile.status == 0)
                        {
                            var allText = rawFile.responseText;
                            callback(allText);
                        }
                    }
                };
                rawFile.send(null);*/
            }
        }
    }

})();
