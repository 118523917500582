'use strict';

var mathjs = require('mathjs');
angular.module('unleeshApp').service('calculation', [
	function() {
		var $this = this;
		$.extend($this, mathjs);
		$this.parseFormulas = function(formula, arrayArguments){
			angular.forEach(arrayArguments, function(value){
				formula = formula.replace(value.argumentName, value.value);
			});

			return $this.eval(formula);
		};

		$this.fieldGluing = function(arrayFields) {
			var str = '';
			angular.forEach(arrayFields, function(val) {
				str += val.label + ': ' + val.field + '\n';
			});
			return str;
		}

	}
]);