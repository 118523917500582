'use strict'
angular.module('unleeshApp')
	.directive('myTabs', function() {
		return {
			restrict: 'E',
			transclude: true,
			scope: {
				numberTabs: '@',
				type: '@'
			},
			controller: function($scope) {
				var panes = $scope.panes = [];
				$scope.select = function(pane) {
					angular.forEach(panes, function(pane) {
						pane.selected = false;
					});
					pane.selected = true;
					if(typeof (pane.headerTitle) !== 'undefined'){
						$scope.$parent.$parent.headerTitle = pane.headerTitle;
					}
				};

				this.addPane = function(pane) {
					if (panes.length === 0) {
						$scope.select(pane);
					}
					panes.push(pane);
				};
			},
			templateUrl: 'sources/template/directive/my-tabs.html'
		};
	})
	.directive('myPane', function() {
		return {
			require: '^myTabs',
			restrict: 'E',
			transclude: true,
			scope: {
				title: '@',
				headerTitle: '@'
			},
			link: function(scope, element, attrs, tabsCtrl) {
				tabsCtrl.addPane(scope);
			},
			templateUrl: 'sources/template/directive/my-pane.html'
		};
	});
