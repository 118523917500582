(function() {
    'use strict';

    angular.module('unleeshApp').filter('userName', function() {
        return function(users, namePart) {
            if (namePart == '') {
                return users;
            }
            namePart = namePart.toLowerCase();

            var filtered = [];
            angular.forEach(users, function(user) {
                var firstName = user.firstName.toLowerCase();
                var lastName = user.lastName.toLowerCase();
                var fullName = (firstName + ' ' + lastName).toLowerCase();

                if (firstName.startsWith(namePart) || lastName.startsWith(namePart)) {
                    filtered.push(user);
                }
                else if (fullName.startsWith(namePart)) {
                    filtered.push(user);
                }
            });
            return filtered;
        };
    });

})();
