(function() {
    'use strict';

    angular.module('unleeshApp').controller('ProfileController', ProfileController);
    ProfileController.$inject = ['$scope', '$rootScope', 'US', '$routeParams', 'localStorageService', 'video', 'audio', 'getText', 'userService', 'pathService',  'postService', 'submitService', 'RAService'];

    function ProfileController($scope, $rootScope, US, $routeParams, localStorage, video, audio, getText, userService, pathService, postService, submitService, RAService) {
        $scope.isCurrentUserProfile = (!$routeParams.id || $routeParams.id == $scope.user.id);
        var filterRAIds = $scope.isCurrentUserProfile ? (localStorage.get('filterRAIds') || []) : [];
        initDefaultScopeVariables();

        if ($scope.isCurrentUserProfile) {
            initCurrentUserProfile();
        }
        else {
            initAnotherUserProfile();
        }
        $rootScope.isLoaded = true;
        userService.getUserProfile($scope.userId).then(function(response) {
            $rootScope.handleResponse($scope, response, function() {
                $scope.user = response.user;
                loadPaths(false, true);
            });
        });

        // Close popups if we change location.
        $rootScope.$on('$routeChangeStart', $routeChangeStartHandler);
        $scope.$on('filterModalTitleInitiated', filterModalTitleInitiatedHandler);

        function initDefaultScopeVariables() {
            $scope.$parent.headerTitle = 'Profile';
            $scope.isOpenSource = false;
            $scope.isOpenComment = false;
            $scope.isCommentSource = false;
            $scope.replySending = false;
            $scope.isChangeShow = false;
            $scope.$parent.titleClass = [];
            $scope.recentActivity = [];
            $scope.recentActivityLoaded = false;
            $scope.filter = $scope.isCurrentUserProfile ? (localStorage.get('filter') || {}) : {};
            $scope.allPathSelected = false;
            $scope.typeComments ='';
            $scope.pageScroll = 1;
            $scope.limitLoad = 100;
            $scope.isLoaPage = false;
            $scope.filterTitle = 'None';
            $scope.pathsNotEmpty = false;
            $scope.activityIsLoading = false;
            $scope.favoriteLoading = false;
            $scope.quoteChanging = false;

            $scope.messageFormOpened = false;
            $scope.userMessage = {
                message: '',
                attacheType: '',
                files: []
            };
            $scope.messageSending = false;

            $scope.filterModalVariables = {
                isOpen: false,
                title: 'None',
                filteredPathIds: filterRAIds.slice()
            };

            $scope.openMessageForm = openMessageForm;
            $scope.closeMessageForm = closeMessageForm;
            $scope.sendMessageToUser = sendMessageToUser;
            $scope.loadMorePage = loadMorePage;
            $scope.openSettings = openSettings;
            $scope.handleOk = handleOk;
            $scope.handleCancel = handleCancel;
            /**
             * open window dialog for display audio, video, image
             * @param type
             * @param src
             */
            $scope.openSource = openSource;
            $scope.handleOkSource = handleOkSource;
            $scope.handleCancelSource = handleCancelSource;
            $scope.handleOkComment = handleOkComment;
            $scope.handleCancelComment = handleCancelComment;
            $scope.showComment = showComment;
            $scope.addLike = addLike;
            $scope.addFavorite = addFavorite;
        }

        function initAnotherUserProfile() {
            $scope.userId = $routeParams.id;
            $scope.user = {};
            $scope.$parent.headerTitle = '';
            $scope.userMessage.recipients = [$scope.userId];
        }

        function initCurrentUserProfile() {
            $scope.userId = $routeParams.id || $scope.user.id;

            $scope.changeQuote = function(e) {
                e.preventDefault();
                $scope.isChangeShow = true;
            };

            $scope.clearQuote = function() {
                $scope.isChangeShow = false;
            };

            $scope.updateQuote = function() {
                $scope.formData = new FormData;

                if($scope.user.quote) {
                    $scope.formData.append('quote', $scope.user.quote);
                } else {
                    $scope.formData.append('quote', '');
                }
                $scope.quoteChanging = true;
                function editQuote(response) {
                    $rootScope.user = response.user;
                    $scope.clearQuote();
                    $scope.$emit('userEdit', response.user);
                }
                userService.editProfile($scope.formData, false).then(function(response) {
                    $rootScope.handleResponse($scope, response, editQuote);
                    $scope.quoteChanging = false;
                }, function(error) {
                    $scope.$emit('errorMessage', error);
                });
            };
        }

        function filterModalTitleInitiatedHandler(event, title) {
            $scope.filterTitle = title;
        }

        function sendMessageToUser() {
            if ($scope.userMessage.message.trim() == '' || !$scope.userMessage.recipients.length) {
                return;
            }

        }

        function loadPaths(openSettings, loadRecentActivity) {
            $rootScope.isLoaded = true;
            pathService.getPathsStarted_NEW().then(function (response) {
                $rootScope.handleResponse($scope, response, function() {
                    $rootScope.isLoaded = false;
                    if(response.paths !== undefined){
                        var paths = {
                            'currentPath' : [],
                            'inProgressPaths': [],
                            'completedPaths': []
                        };
                        response.paths.forEach(function(item, index){
                            if(item.status == 1){
                                paths.currentPath = item;
                            } else if(item.status == 0) {
                                paths.inProgressPaths.push(item);
                            } else if(item.status == 2 ) {
                                paths.completedPaths.push(item);
                            }
                        });
                        $scope.path = paths;
                    } else {
                    }

                    if (openSettings) {
                        $scope.filterModalVariables.isOpened = true;
                    }
                    if ((!paths.currentPath || !paths.currentPath.id)
                        && (!paths.inProgressPaths || !paths.inProgressPaths.length)
                        && (!paths.completedPaths || !paths.completedPaths.length)) {
                        return;
                    }
                    $scope.pathsNotEmpty = true;
                    $scope.filter = paths;

                    if (loadRecentActivity) {
                        getRecentActivity($scope.userId, filterRAIds, $scope.pageScroll, $scope.limitLoad);
                    }
                });
            });
        }

        function getRecentActivity(id, paths, page, limit) {
            if(paths.length == 0) {
                paths = null;
            }
            $scope.activityIsLoading = true;
           
//console.log(RAService.getListRecentActivity(id, paths, page, limit));
            RAService.getListRecentActivity(id, paths, page, limit).then(function(response) {
                $scope.recentActivity = $scope.recentActivity.concat(response.feeds);
                $scope.isLoaPage = true;
                $scope.activityIsLoading = false;
                $scope.recentActivityLoaded = false;
            });
        }

        function openMessageForm() {
            $scope.messageFormOpened = true;
        }

        function closeMessageForm() {
            $scope.messageFormOpened = false;
        }

        function loadMorePage() {
            $scope.pageScroll += 1;
            getRecentActivity($scope.userId, filterRAIds, $scope.pageScroll, $scope.limitLoad);
        }

        // function called to open modal which allows selecting which paths to show activity from
        function openSettings() {
            loadPaths(true);
        }

        function handleCancel() {
            $scope.filterModalVariables.filteredPathIds = filterRAIds.slice();
            $scope.filterModalVariables.isOpened = false;
            $scope.filterModalVariables.title = $scope.filterTitle;
        }

        function handleOk() {
            $scope.filterModalVariables.isOpened = false;
            try {
                $scope.$apply()
            } catch(err) {}

            if (!$scope.filterModalVariables.filteredPathIds.length) {
                $scope.$emit('errorMessage', {statusText: 'You must select at least one path'});
                return;
            }
            // If we have same paths then don't do anything, only close popup filter.
            if (!$scope.filterModalVariables.filteredPathIds.diff(filterRAIds).length && !filterRAIds.diff($scope.filterModalVariables.filteredPathIds).length) {
                return;
            }

            filterRAIds = $scope.filterModalVariables.filteredPathIds.slice();
            if ($scope.isCurrentUserProfile) {
                localStorage.set('filterRAIds', filterRAIds);
            }

            $scope.filterTitle = $scope.filterModalVariables.title;
            $scope.pageScroll = 1;
            $scope.recentActivity = [];
            getRecentActivity($scope.userId, filterRAIds, $scope.pageScroll, $scope.limitLoad);
        }

        function openSource(type, src) {
            if($scope.isOpenComment) {
                $scope.isCommentSource = true;
            }
            switch (type) {
                case 'video':
                    $scope.load = {video: true};
                    $('.profile-sours').append(video.elem);
                    video.play(src);

                    break;
                case 'audio':
                    $scope.load = {audio: true};
                    $('.profile-sours').append(audio.elem);
                    audio.play(src);
                    break;
                case 'image':
                    $scope.load = {image: true, src: src};
                    break;
            }
            $scope.isOpenSource = true;
        }

        function handleOkSource() {
            if($scope.isCommentSource) {
                $scope.isCommentSource = false;
            }
            if($scope.load.video) {
                video.stop();
                video.elem.remove();
            }
            if($scope.load.audio) {
                audio.stop();
                $('.profile-sours audio').remove();
            }
            $scope.isOpenSource = false;
        }

        function handleCancelSource() {
            if($scope.isCommentSource) {
                $scope.isCommentSource = false;
            }
            $scope.isOpenSource = false;
        }

        function handleOkComment() {
            $scope.isCommentSource = false;
            $scope.isOpenComment = false;
        }

        function handleCancelComment() {
            $scope.isCommentSource = false;
            $scope.isOpenComment = false;
        }

        function showComment(e, index, type, id) {
            e.preventDefault();
            $scope.message ={};
            function responsePost(response) {
                $scope.comments = response.post;
                $scope.isOpenComment = true;
            }
            switch (type){
                case 'Reply':
                case 'Post':
                    $rootScope.isLoaded = true;

                    postService.getPostView(id)
                        .then(function(response) {
                            $rootScope.isLoaded = false;
                            $rootScope.handleResponse($scope, response, responsePost);
                        }, function(error) {
                            $rootScope.isLoaded = false;
                            $scope.$emit('errorMessage', error);
                        });
                    $scope.typeComments = 'post';
                    break;
                case 'ActionSubmit':
                case 'ActionSubmitComment':
                    $scope.comments = $scope.recentActivity[index].activity;
                    $scope.typeComments = 'submit';
                    $scope.isOpenComment = true;
                    break;
            }

            $scope.submitMessage = function() {
                var formData = new FormData();
                if($scope.message.message) {
                    formData.append('message', $scope.message.message);
                }
                if($scope.message.files && $scope.message.files.length) {
                    formData.append('file', $scope.message.files[$scope.message.files.length - 1], $scope.message.files[$scope.message.files.length - 1].name);
                    formData.append('attachmentType', $scope.attacheType);
                }
                $scope.replySending = true;
//				if($scope.formMessage.$valid) {
                submitService.setListCommentsFile(formData, id, type)
                        .then(function(response) {
                            if(response.statusCode == 201) {
                                if((type == 'Post')||(type == 'Reply')){
                                    $scope.comments.replies.push(response.reply)
                                }
                                if((type == 'ActionSubmitComment')||(type == 'ActionSubmit')){
                                    if($scope.comments.actionSubmit) {
                                        $scope.comments.actionSubmit.posts.push(response.post)
                                    }else {
                                        $scope.comments.posts.push(response.post)
                                    }
                                }
                                $scope.message.message = '';
                                $scope.message.files = [];
                                $scope.attacheType = '';
                                $scope.isAttach = false;
                            } else if(response.statusCode == 401){
                                $scope.$emit('userLogout', response);
                            } else if(response.statusCode == 403) {
                                //add message error;
                                $scope.$emit('errorMessage', {statusText: response.message});
                            }
                            $scope.replySending = false;
                        }, function(error) {
                            $scope.$emit('errorMessage', error);
                        });
//				}

            }
        }

        function addLike(e, userId, id, isLike, index, submitId) {
            if(userId == $rootScope.user.id) {
                $scope.$emit('errorMessage', {statusText: 'You cannot appreciate your own reply'});
                return;
            }
            if($scope.loadedLike == true) {
                return;
            }
            var elemLoding = $(e.currentTarget).children('.loadLike');
            elemLoding.show();
            $scope.loadedLike = true;
            if(!isLike) {
                US.addLike(id, $scope.typeComments, submitId)
                    .then(function(response) {
                        $scope.loadedLike = false;
                        $rootScope.handleResponse($scope, response, addLike);
                    }, function(error) {
                        $scope.loadedLike = false;
                        elemLoding.hide();
                        error.isLogin = true;
                        $scope.$emit('errorMessage', error);
                    });
            } else {
                US.deleteLike(id, $scope.typeComments, submitId)
                    .then(function(response) {
                        $scope.loadedLike = false;
                        $rootScope.handleResponse($scope, response, deleteLike);
                    }, function(error) {
                        $scope.loadedLike = false;
                        elemLoding.hide();
                        error.isLogin = true;
                        $scope.$emit('errorMessage', error);
                    })
            }
            function addLike(response) {
                elemLoding.hide();
                if(response.statusCode == 200) {
                    if($scope.typeComments == 'post'){
                        $scope.comments.replies[index] = response.reply;
                    }
                }else if(response.statusCode == 201) {
                    if($scope.typeComments == 'submit'){
                        $scope.comments.posts[index].appreciations = 1;
                        $scope.comments.posts[index].isAlreadyAppreciated = true;
                    }
                    if($scope.typeComments == 'post'){
                        $scope.comments.replies[index] = response.reply;
                    }
                }
            }
            function deleteLike (response) {
                elemLoding.hide();
                if(response.statusCode == 200) {
                    if($scope.typeComments == 'post'){
                        $scope.comments.replies[index] = response.reply;

                    }
                    if($scope.typeComments == 'submit'){
                        $scope.comments.posts[index].appreciations = 0;
                        $scope.comments.posts[index].isAlreadyAppreciated = false;
                    }
                }else if(response.statusCode == 201) {
                    if($scope.typeComments == 'submit'){
                        $scope.comments.posts[index].appreciations = 0;
                        $scope.comments.posts[index].isAlreadyAppreciated = false;
                    }
                    if($scope.typeComments == 'post'){
                        $scope.comments.replies[index] = response.reply;
                    }
                }
            }
        }


        function addFavorite(e) {
            e.preventDefault();
            $scope.favoriteLoading = true;
            if($scope.user.isFavoriteUser){
                userService.deleteFavorite($scope.user.id)
                    .then(function(response) {
                        if(response.statusCode == 200) {
                            $scope.user.isFavoriteUser = false;
                            $scope.favoriteLoading = false;
                        } else if(response.statusCode == 401){
                            $scope.$emit('userLogout', response);
                        } else if(response.statusCode == 403) {
                            //add message error;
                            $scope.$emit('errorMessage', {statusText: response.message});
                        }
                    }, function(error) {
                        $scope.$emit('errorMessage', error);
                    })
            } else {
                userService.setFavorite($scope.user.id)
                    .then(function(response) {
                        if(response.statusCode == 201) {
                            $scope.user.isFavoriteUser = true;
                            $scope.favoriteLoading = false;
                        } else if(response.statusCode == 401){
                            $scope.$emit('userLogout', response);
                        } else if(response.statusCode == 403) {
                            //add message error;
                            $scope.$emit('errorMessage', {statusText: response.message});
                        }
                    }, function(error) {
                        $scope.$emit('errorMessage', error);
                    })
            }
        }

        function $routeChangeStartHandler() {
            $scope.filterModalVariables.isOpened = false;
            $scope.isOpenSource = false;
            $scope.isOpenComment = false;
        }

        // added by Ben

        $scope.inEditMode = false;
        $scope.profile = $rootScope.user;
        $scope.profile.password = ""
        $scope.profile.formValid = true

        $scope.toggleEditMode = () => {
            $scope.inEditMode = !$scope.inEditMode;
        }

        // Hides/displays the "change password" section
        $scope.showPasswordChangeSection = false
        $scope.showPasswordChange = () => {
            $scope.showPasswordChangeSection = true
        }

        // Hides/displays the "unSubscribe" section
        $scope.showUnSubscribeSection = false
        $scope.showUnSubscribeChange = () => {
            $scope.showUnSubscribeSection = true
        }

        // Hides/displays the "push" section
        $scope.showPushSection = false
        $scope.showPushChange = () => {
            $scope.showPushSection = true
        }

        // starts true so that error doesn't show until they start typing
        // true is good
        $scope.passwordIsMinLengthOrEmpty = true 

        // called on change for password input
        $scope.checkPassword = () => {
            if (!$scope.profile || !$scope.profile.password) { 
                $scope.passwordIsMinLengthOrEmpty = true
                return
             }

            if ($scope.profile.password.length < 6 && $scope.profile.password.length > 0) {
                $scope.passwordIsMinLengthOrEmpty = false
            } else {
                $scope.passwordIsMinLengthOrEmpty = true
            }

            $scope.checkFormValid()
        }

        // checks to make sure everything in the form is valid
        $scope.checkFormValid = () => {
            // Start assuming it's valid, then check for invalid cases
            $scope.profile.formValid = true 

            // check password
            if ($scope.showPasswordChangeSection) {
                if (!$scope.passwordIsMinLengthOrEmpty) {
                    $scope.profile.formValid = false
                    return
                }
            }
        }

        // handles submitting the new info for the profile
        $scope.submitUpdateProfile = function () {
            // init form data
            $scope.formData = new FormData;
            
            // Append data 
            [
              "firstName",
              "lastName",
              "quote", // quote is now reused as the headline / short bio
              "profession",
              "company",
              "education",
              "email",
              "zip",
              "birthdate"
            ].forEach((data_id) => {
              $scope.formData.append(
                data_id,
                $scope.profile[data_id] ? $scope.profile[data_id] : ""
              );
            });

            // unfortunately twitter/linkedin have different keys when submitted in the form and when saved in the user object
            [
                {
                    formkey: "twitterUid",
                    userkey: "twitter_uid"
                },
                {
                    formkey: "gplusUid",
                    userkey: "gplus_uid"
                },
            ].forEach(data => {
                $scope.formData.append(
                    data.formkey,
                    $scope.profile[data.userkey] ? $scope.profile[data.userkey] : ""
                  );
            })

            // optionally append password
            if ($scope.showPasswordChangeSection) {
                if ($scope.profile.password && $scope.profile.password.length >= 6) {
                    $scope.formData.append(
                        "password",
                        $scope.profile.password
                      );
                }
            }

            if ($scope.showUnSubscribeSection) {
                if($scope.profile.unsubscribed){
                    var unsubscribed = true;
                } else {
                    var unsubscribed = false;
                }

                $scope.formData.append(
                    "unsubscribed",
                    unsubscribed
                );

            }

            // optionally append new profile photo
            if($scope.profile.files && $scope.profile.files.length > 0) {
				$scope.formData.append('file', $scope.profile.files[$scope.profile.files.length - 1] , $scope.profile.files[$scope.profile.files.length - 1].name);
            }
        

            $rootScope.isLoaded = true;
            userService.editProfile($scope.formData ,true)
                .then(function(response) {
                    $rootScope.handleResponse($scope, response, responseEdit);
                    $rootScope.isLoaded = false;
                }, function(response) {
                    $rootScope.isLoaded = false;
                });


        };
        
        function responseEdit(response) {
            $rootScope.user = response.user;
            $scope.inEditMode = false;
            $scope.$emit('userEdit', response.user);

            // refresh the page

                setTimeout(() => {
                    location.reload()
                }, 1000)            
        }

    }

})();
