(function() {
	'use strict';

	angular.module('unleeshApp').controller('SubmitsController', SubmitsController);
	SubmitsController.$inject = ['$scope','US','$routeParams', '$rootScope', 'video', 'audio',  'postService', 'submitService', 'RAService', 'getText'];

	function SubmitsController($scope, US, $routeParams, $rootScope, video, audio,  postService, submitService, RAService, getText) {
		$scope.$parent.headerTitle = 'Submits';
		$scope.$parent.titleClass = '';
		$rootScope.$emit('showBackLink');

		$scope.page = 1;
		$scope.limit = 10;
		$scope.isLoad = false;
		$scope.recentActivity = [];
		$scope.isLoadSubmit = false;
		$scope.stopLoadSubmit = false;
		// TODO make it by another way (prototypes or something else).
		new window.PostsScopeTrait($scope, US, postService, submitService, RAService, $rootScope, video, audio, $routeParams);

		$scope.isOpenComment = false;
		$scope.isOpenSource = false;
		$scope.loadMorePageSubmit = loadMorePageSubmit;

		getScrollContent($scope.page, $scope.limit);

		function loadMorePageSubmit() {
			if ($scope.isOpenComment || $scope.isOpenSource) {
				return;
			}
			$scope.page += 1;
			$scope.stopLoadSubmit = true;
			getScrollContent($scope.page, $scope.limit);
		}

		function getScrollContent(page, limit) {
			if(page == 1) {
				$rootScope.isLoaded = true;
			}
			submitService.getActionSubmits($routeParams.id, page, limit).then(function(response) {
				$rootScope.isLoaded = false;
				$scope.stopLoadSubmit = false;
				$rootScope.handleResponse($scope, response, responseGetRA);
			}, function(error) {
				$rootScope.isLoaded = false;
				$scope.$emit('errorMessage', error);
			});
		}

		function responseGetRA(response) {
			$scope.isLoadSubmit = true;
			$scope.recentActivity = $scope.recentActivity.concat(response.feeds);
			angular.forEach($scope.recentActivity, function(val) {
				var activityObj = null;
				switch (val.activityType) {
					case 'ActionSubmitComment':
						activityObj = val.activity.actionSubmit;
						break;
					case 'ActionSubmit':
						activityObj = val.activity;
						break;
				}
				if(!activityObj || activityObj.attachmentType != 'text' || 1) {
					return true;
				}
				getText.get(activityObj.attachment, function(text) {
					if(text != null) {
						activityObj.comment = text
					}
				});
			});
		}
	}

})();
